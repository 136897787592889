import React, { useEffect, useState, useCallback } from 'react';
import './AccessMatrix.css';
import EmptyRow from './EmptyRow';
import AccessHeader from './access_header/AccessHeader';
import AccessLock from './AccessLock';

function AccessMatrix({locks, user, onExit, apiService, editRule, refresh, onRefresh}) {
    const [accessList, setAccessList] = useState('');
    const [newAccessList, setNewAccessList] = useState([]);
    const [loading, setLoading] = useState(true);
    const [dataChanged, setDataChanged] = useState(false);
    const [retrievingData, setRetrievingData] = useState(false);
    const [pageNumber, setPageNumber] = useState(1);

    const closeOnBackdrop = (e) => {
        /* Checks if a click event specifically targets the backdrop */
        if(e.target.className === "access-matrix-wrapper"){
            onExit();
        }
    }

    const retrieveAccess = useCallback(async () => {
        /* Retrieves all access information for the selected user,
           stores this info in a temporary list and updates the state
           of the view when finished.
        */
        setRetrievingData(true);
        var access_rules;
        var displayList = [];
        console.log("Retrieving users's access information..");
        const response = await apiService.getUserAccess(user.id);
        if(response.success) access_rules = response.data;
        for(var full_lock of locks){
            /* Only keep the data we actually need (to save time by not comparing stuff that never changes) */
            const lock = {id: full_lock.id, name: full_lock.name, hardware: full_lock.hardware};
            var lock_rules = [];
            /* Check if user has an access rule for this lock*/
            for(var i = 0; i < access_rules.length; i++)
            {
                if(lock.id === access_rules[i].lock){
                    const user_rule = access_rules[i];
                    /* Check if the rule has full_access */
                    if(user_rule.permanent_access) {
                        lock_rules.push({
                            lock: lock,
                            access: [true, true, true, true, true, true, true,],
                            rule_id: user_rule.id, full_access: true, 
                            from_date: user_rule.from_date, to_date: user_rule.to_date,
                            from_time: user_rule.from_time, to_time: user_rule.to_time,
                            all_day: user_rule.all_day,
                        }); 
                        continue;
                    }
                    /* User has an access rule, but not full access, lets display the actual access rule. */
                    lock_rules.push({
                        lock: lock,
                        access:[
                            user_rule.monday,
                            user_rule.tuesday,
                            user_rule.wednesday,
                            user_rule.thursday,
                            user_rule.friday,
                            user_rule.saturday,
                            user_rule.sunday,
                        ], 
                        rule_id: user_rule.id, full_access: false,
                        from_date: user_rule.from_date, to_date: user_rule.to_date,
                        from_time: user_rule.from_time, to_time: user_rule.to_time,
                        all_day: user_rule.all_day,
                    });
                }
            }
            displayList.push({
                lock: lock,
                rules: lock_rules
            })
        };
        /* Set tempList as main accessList and display matrix */
        setAccessList(JSON.stringify(displayList));
        setNewAccessList([...displayList]);
        setLoading(false);
        setRetrievingData(false);
    }, [ locks, apiService, user]);

    useEffect(() => {
        onRefresh();
        retrieveAccess();
    },[refresh,]);

    if(!user) return null;
    if(!locks) return null;

    const handleDataChange = () => {
        if(accessList !== JSON.stringify(newAccessList)){
            if(!dataChanged){
                setDataChanged(true);
            }
        }else{
            if(dataChanged){
                setDataChanged(false);
            }
        }
    }

    /* Paginate lock array in slices of 4 */
    const paginate = (array, page_number) => {
        return array.slice((page_number - 1) * 4, page_number * 4);
    }
    
    /* If the view is not loaded yet show the loading screen */
    if(loading){
        /* Make sure to only request the access information once */
        if(!retrievingData) retrieveAccess();
        return(
            <div className="access-matrix-wrapper" onClick={closeOnBackdrop}>
                <div className="container">
                    <AccessHeader onExit={onExit} selectedUser={user}/>
                    <div className="access-rules-grid">
                        <AccessLock loading={true} key={"loading_lock_0"}/>
                        <AccessLock loading={true} key={"loading_lock_1"}/>
                        <AccessLock loading={true} key={"loading_lock_2"}/>
                        <AccessLock loading={true} key={"loading_lock_3"}/>
                    </div>
                </div>
            </div>
        );
    }

    const json_list = JSON.parse(accessList);
    const lock_list = json_list.map(data => <AccessLock data={data} onEdit={editRule} key={'lock_' + data.lock.id}/>);
    /* We always want 4 locks / rows on each page. */
    const rest = lock_list.length % 4;
    if(rest) 
    {
        const rows_needed = 4 - rest;
        for(var i = 0; i < rows_needed; i++)
        {
            lock_list.push(<EmptyRow key={"empty_row_" + i}/>);
        }
    }

    handleDataChange(accessList, newAccessList);
    console.log(`Current page number: ${pageNumber}`);
    return (
        <div className="access-matrix-wrapper" onClick={closeOnBackdrop}>
            <div className="container">
                <AccessHeader onExit={onExit} selectedUser={user} pageNumber={pageNumber} setPageNumber={setPageNumber} listLength={lock_list.length}/>
                <div className="access-rules-grid">
                    {paginate(lock_list, pageNumber)}
                </div>
            </div>
        </div>
    );
}

export default AccessMatrix;