import React, { useContext, useState } from 'react';
import { useEffect } from 'react';
import { Button, Divider, InputAdornment, Paper, Stack, TextField } from '@mui/material';
import './ListLockDetails.css';
import { Box } from '@mui/system';
import { useTranslation } from 'react-i18next';
import { ServiceContext } from '../../context/ServiceContext';

function ListLockDetails({lock, onUpdate, smallScreen}) {

    const { t } = useTranslation();
    const [hasDiff, setHasDiff] = useState(false);
    const [lockName, setLockName] = useState('');
    const [unlockTime, setUnlockTime] = useState(0);
    const [invalidTime, setInvalidTime] = useState(false);
    const context = useContext(ServiceContext);
    const orgId = context.selectedOrg && context.selectedOrg.id | 0;

    const onSave = () => {
        /* Called when edit / save button is clicked */
        onUpdate(lock, { name: lockName, unlock_time_default: unlockTime }).then((success) => {
            /* Update values on success */
            if (success) lock.name = lockName; lock.unlock_time_default = unlockTime;
        });
    };

    useEffect(() => {
        if (lock) {
            setLockName(lock.name);
            setUnlockTime(parseInt(lock.unlock_time_default));
        }
    }, [lock,]);

    useEffect(() => {
        if (lock) {
            if (
                lockName !== lock.name ||
                unlockTime !== parseInt(lock.unlock_time_default)
            ) {
                !hasDiff && setHasDiff(true);
            } else {
                hasDiff && setHasDiff(false);
            }
        }
    }, [lockName, unlockTime, lock, hasDiff]);

    if (!lock) return null;
    const export_url = `/api/organizations/${orgId}/locks/${lock.id}/access_export/`;

    const renderHardwarePhoto = (hardware, small) => {
        if(hardware){
            if(hardware.toLowerCase() === "edh"){
                return (<div className={small ? "hardware-image EDH small-image" : "hardware-image EDH"}></div>);
            }
            if(hardware.toLowerCase() === "edc"){
                return (<div className={small ? "hardware-image EDC small-image" : "hardware-image EDC"}></div>);
            }
            if(hardware.toLowerCase() === "wmr"){
                return (<div className={small ? "hardware-image WMR small-image" : "hardware-image WMR"}></div>);
            }
            if(hardware.toLowerCase() === "pcb"){
                return (<div className={small ? "hardware-image PCB small-image" : "hardware-image PCB"}></div>);
            }
        }
        /* Unknown hardware type */
        return(<div className="hardware-image"></div>);
    }

    const checkUnlockTime = (time) => {
        const value = parseInt(time);
        if (value >= 1 && value <= 15) {
            invalidTime && setInvalidTime(false);
            setUnlockTime(value);
            return;
        }
        !isNaN(value) && setUnlockTime(value);
        !invalidTime && setInvalidTime(true);

    }

    if (smallScreen) {
        return (
            <Stack direction="row" spacing={1} justifyContent='center' alignItems='center' sx={{width: '100%', height: 200}}>
                <Box sx={{p: 1}}>
                    <Stack spacing={2}>
                        <Paper elevation={1} >
                            <TextField id="outlined-name" label={t('labels.name')} variant="outlined" color={'text'}
                                sx={{ width: '100%' }} value={lockName} onChange={e => setLockName(e.target.value)} size='small'
                            />
                            </Paper>
                        <Paper elevation={1}>
                            <TextField disabled id="outlined-building" label={t('labels.building')} variant="outlined" color={'text'}
                                sx={{ width: '100%' }} value={lock.building_name} size='small'
                            />
                        </Paper>
                        <Paper elevation={1}>
                            <TextField disabled id="outlined-users" label={t('labels.users')} variant="outlined" color={'text'}
                                sx={{ width: '100%' }} value={lock.user_count} size='small'
                            />
                        </Paper>
                    </Stack>
                </Box>
                <Box sx={{p: 1, maxWidth: 400,}}>
                    <Stack spacing={2} >
                        <Paper elevation={1}>
                            <TextField disabled id="outlined-hw" label={t('labels.type')} variant="outlined" color={'text'}
                                sx={{ width: '100%' }} value={lock.hardware ? lock.hardware : t('labels.unknown')} size='small'
                            />
                        </Paper>
                        <Paper elevation={1}>
                            <Stack direction={'row'} spacing={1}>
                                <TextField disabled id="outlined-sw" label={t('labels.software_version')} variant="outlined"
                                    color={'text'} value={lock.software_version} size='small'
                                />
                                <TextField id="outlined-ut" label={t('labels.unlock_time')} variant="outlined" color={'text'}
                                    value={unlockTime} onChange={e => checkUnlockTime(e.target.value)} error={invalidTime} InputProps={{
                                        endAdornment: <InputAdornment position="end">s</InputAdornment>,
                                        inputMode: 'numeric', pattern: '[0-9]*', min: '1', max: '15'
                                    }} size='small'
                                />
                            </Stack>
                        </Paper>
                        <Button disabled={!hasDiff || invalidTime} sx={{
                            "&:hover": { backgroundColor: hasDiff ? 'info.hover' : 'transparent', },
                            borderRadius: 15, backgroundColor: hasDiff ? 'info.main' : 'transparent',
                            borderColor: 'drawer.button', borderWidth: 1, borderStyle: 'solid',
                            color: 'text.main', p:1
                        }} onClick={onSave} 
                        >{t('labels.save')}</Button>
                    </Stack>
                </Box>
            </Stack>);
    }

    return (
        <div style={{height: '100%'}}>
            <div style={{padding: 24, alignItems: 'center', justifyContent: 'center', width: '100%', display: 'flex'}}>
                <div style={{
                    alignItems: 'center',
                    backgroundColor: '#0a0a0a',
                    borderRadius: 150,
                    color: '#fff',
                    display: "flex",
                    fontSize: 13,
                    height: 150,
                    justifyContent: 'center',
                    width: 150,
                }}>
                    {renderHardwarePhoto(lock.hardware)}
                </div>
            </div>
            <Divider />
            <Box sx={{p: 2,}}>
                <Stack spacing={2}>
                    <Paper elevation={1} >
                        <TextField id="outlined-name" label={t('labels.name')} variant="outlined" color={'text'}
                            sx={{ width: '100%' }} value={lockName} onChange={e => setLockName(e.target.value)} size='small'
                        />
                    </Paper>
                    <Paper elevation={1}>
                        <TextField disabled id="outlined-building" label={t('labels.building')} variant="outlined" color={'text'}
                            sx={{ width: '100%' }} value={lock.building_name} size='small'
                        />
                    </Paper>
                    <Paper elevation={1}>
                        <Stack direction={'row'} spacing={1}>
                            <TextField disabled id="outlined-users" label={t('labels.users')} variant="outlined" color={'text'}
                                sx={{ width: '100%' }} value={lock.user_count} size='small'
                            />
                            <TextField disabled id="outlined-hw" label={t('labels.type')} variant="outlined" color={'text'}
                                sx={{ width: '100%' }} value={lock.hardware ? lock.hardware : t('labels.unknown')} size='small'
                            />
                        </Stack>
                    </Paper>
                    <Paper elevation={1}>
                        <TextField disabled id="outlined-sw" label={t('labels.software_version')} variant="outlined"
                            color={'text'} sx={{ width: '100%' }} value={lock.software_version} size='small'
                        />
                    </Paper>
                    <Paper elevation={1}>
                        <TextField id="outlined-ut" label={t('labels.unlock_time')} variant="outlined" color={'text'} sx={{ width: '100%' }}
                            value={unlockTime} onChange={e => checkUnlockTime(e.target.value)} error={invalidTime} InputProps={{
                                endAdornment: <InputAdornment position="end">{t('labels.seconds')}</InputAdornment>,
                                inputMode: 'numeric', pattern: '[0-9]*', min: '1', max: '15'
                            }} size='small'
                        />
                    </Paper>
                    <Button disabled={!hasDiff || invalidTime} sx={{
                        "&:hover": { backgroundColor: hasDiff ? 'info.hover' : 'transparent', },
                        borderRadius: 15, backgroundColor: hasDiff ? 'info.main' : 'transparent',
                        borderColor: 'drawer.button', borderWidth: 1, borderStyle: 'solid',
                        color: 'text.main',
                    }} onClick={onSave}
                    >{t('labels.save')}</Button>
                </Stack>
            </Box>
            <div className="user-actions">
                <div className="separator"/>
                <a href={export_url} target="_blank" rel="noreferrer">
                    <div className="info-row">
                        <div className="title">{t('actions.export_access')}</div>
                        <div className="download"></div>
                    </div>
                </a>
            </div>
        </div>
    );
}

export default ListLockDetails;