import {getUTCDayStart, getUTCDayEnd} from '../util/util';

class LogService {

    constructor(apiService) {

        this.apiService = apiService;
        this.logs = [];
        this.log_view_logs = [];
        
    }

    async retrieveLogs() {
        /* Passes a reference to the list of logs, tries to retrieve logs if list is empty */
        if(this.apiService){
            const response = await this.apiService.retrieveLogs();
            if(response.success)
            {
                this.logs = response.data.results;
                return this.logs;
            }
        }
        return this.logs;
    }

    async retrieveLatestLogs() {
        /* Retrieves only the 5 latest logs */
        const response = await this.apiService.retrieveLatestLogs();
        if(response.success){
            return {success: true, logs: response.data.results};
        }
        return {success: false, logs: []};
    }

    async retrieveLogsByDates(startDate, endDate, limit) {
        const dt_from = getUTCDayStart(startDate);
        const dt_to   = getUTCDayEnd(endDate)
        return await this.apiService.retrieveLogsByDates(dt_from, dt_to, limit);
    }

    async poll() {
        /* Retrieves logs from the API */
        const response = await this.apiService.retrieveLogs();
        if(response.success)
        {
            this.logs = response.data.results;
        }
    }
    
}
export default LogService;