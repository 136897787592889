import React, { Component } from 'react';
import '../../index.css';
import { ServiceContext } from '../../context/ServiceContext';
import { withTranslation } from 'react-i18next';
import { Box, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TableSortLabel } from '@mui/material';
import { withStyles } from '@mui/styles';
import { visuallyHidden } from '@mui/utils';
import { stableSort, getComparator, removeHypen } from '../../util/util';
import ListEntryCard from '../../components/list_entry_card/ListEntryCard';
    
    
const StyledTableCell = withStyles({
    root: {
        fontSize: 10,
        textTransform: 'uppercase',
        padding: '6px',
        paddingTop: 0,
    }
})(TableCell);

class Cards extends Component {
    

    static contextType = ServiceContext;

    constructor(props) {
        super(props)

        /* Initialize state */
        this.state = {
            order: 'asc',
            orderBy: 'serial',
            cards: null,
            users: null,
            filter: '',
        }

        /* Handles for services */
        this.orgService = null;
        this.userService = null;

        /* Set up function binds */
        this.filterCard = this.filterCard.bind(this);
    }

    componentDidMount() {
        /* Retrieve reference to organizationService */
        this.orgService = this.context.orgService;
        this.userService = this.context.userService;

        /* Retrieve cards when the component has been mounted. */
        // this.orgService.getCards().then( cards => {
        //     this.setState({cards: cards})
        // });
        this.userService.retrieveUsers().then( users => {
            this.setState({ users: users });
        });

        this.orgService.getCards().then( cards => {
            this.setState({cards: cards})
        });

        /* Get filter from URL parameter */
        const params = new URLSearchParams(this.props.location.search);
        const card = params.get('card');
        if(card) this.setState({filter: card})
    }

    filterCard(card) {
        /* 
            Filter on card based on serial
        */
        return card.serial.toLowerCase().includes(this.state.filter.toLowerCase());
    }

    onAccessClicked(user) {
        /* Shows / hiddes the matrix, changes scroll behaviour */
        document.body.style.overflowY = 'hidden';
        this.setState({selectedUser: user, showMatrix: !this.state.showMatrix});
    }

    render() {
        const { t } = this.props;

        const headCells = [
            {
                id: 'serial',
                label: t('labels.serial'),
                sortable: true,
            },
            {
                id: 'linked_to',
                label: t('labels.linked_to'),
            },
        ];

        const handleRequestSort = (event, property) => {
            const isAsc = this.state.orderBy === property && this.state.order === 'asc';
            this.setState({ order: isAsc ? 'desc' : 'asc', orderBy: property});
        };

        const EnhancedTableHead = (props) => {
            const { order, orderBy, onRequestSort } = props;
            const createSortHandler = (property) => (event) => {
                onRequestSort(event, property);
            };

            return (
                <TableHead>
                    <TableRow>
                        <TableCell padding="checkbox">
                        </TableCell>
                        {headCells.map((headCell) => (
                        <StyledTableCell
                            key={headCell.id}
                            sortDirection={orderBy === headCell.id ? order : false}
                            style={headCell.id === "serial_number" ? {width: 100} : {}}
                        >
                            {headCell.sortable ?
                                (<TableSortLabel
                                    style={{ fontSize: 10,}}
                                    active={orderBy === headCell.id}
                                    direction={orderBy === headCell.id ? order : 'asc'}
                                    onClick={createSortHandler(headCell.id)}
                                >
                                    {headCell.label}
                                    {orderBy === headCell.id ? (
                                        <Box component="span" sx={visuallyHidden}>
                                            {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                        </Box>
                                    ) : null}
                                </TableSortLabel>) : 
                                    headCell.label
                                }
                        </StyledTableCell>
                        ))}
                    </TableRow>
                </TableHead>
            );
        };

        var cards = this.state.cards;
        var cardList = null;

        /* Apply filter if necessary */
        if(cards && this.state.filter) {
            cards = cards.filter(this.filterCard);
        }

        /* Render users if any */
        if(cards) {
            cardList = stableSort(cards, getComparator(this.state.order, this.state.orderBy))
                .map((card) =>
                    <ListEntryCard card={card} key={"card_" + removeHypen(card.serial)} userService={this.userService}/>);
        }

        return (
            <div className="cards page">
                <div className="page-inner" style={{padding: 0}}>
                    <div className="left-side" style={{width: this.state.showDetails ? `calc(100% - 425px)`: `calc(100%)`,  padding: 0}}>
                        <div className="actions">
                            <div className="search-bar">
                                <div className="icon"></div>
                                <input type="text" placeholder={t("search-placeholders.card")}
                                    value={this.state.filter} 
                                    onChange={(event) => this.setState({filter: event.target.value})}
                                />
                            </div>
                        </div>
                        <TableContainer style={{ maxHeight: window.innerHeight - 202}}>
                            <Table stickyHeader>
                                <EnhancedTableHead
                                    order={this.state.order}
                                    orderBy={this.state.orderBy}
                                    onRequestSort={handleRequestSort}
                                />
                                <TableBody dense="true">
                                    {cardList}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </div>
                </div>
            </div> 
        )
    }
}

export default withTranslation()(Cards);
