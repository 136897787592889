import React, { useContext, useState, useEffect } from 'react';
import { ServiceContext } from '../../context/ServiceContext';
import { Button, Divider, Paper, Stack, TextField } from '@mui/material';
import './ListGroupDetails.css';
import { Box } from '@mui/system';
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';
import GroupIcon from '@mui/icons-material/Group';


function ListGroupDetails({ group, onAccess, onDelete, onMembers, refreshCallback, smallScreen }) {
    const { t } = useTranslation();
    const context = useContext(ServiceContext);
    const { enqueueSnackbar } = useSnackbar();
    const {apiService} = useContext(ServiceContext);
    const [groupName, setGroupName] = useState('');
    const [hasDiff, setHasDiff] = useState(false);
    const orgId = context.selectedOrg && context.selectedOrg.id | 0;

    useEffect(() => {
        if (group) {
            setGroupName(group.name);
        }
    }, [group,]);

    useEffect(() => {
        if (group) {
            if (groupName !== group.name) {
                !hasDiff && setHasDiff(true);
            } else {
                hasDiff && setHasDiff(false);
            }
        }
    }, [group, groupName, hasDiff]);

    if(!group) return null;
    const export_url = `/api/organizations/${orgId}/usergroups/${group.id}/members_export/`;

    const onSave = async () => {
        const response = await apiService.changeGroupName(group.id, groupName);
        if(response.success){
            setGroupName(() => response.data.name);
            await refreshCallback();
            enqueueSnackbar(
                t('notifcations.the_changes_has_been_saved_successfully'), { variant: 'success' }
            );
        } else {
            enqueueSnackbar(
                t('notifcations.could_not_save_changes'), { variant: 'error' }
            );
        }
    }

    if (smallScreen) {
        return (
            <Stack direction="row" spacing={1} justifyContent='center' alignItems='center' sx={{width: '100%', height: 200}}>
                <Stack spacing={2} direction="column" alignItems='center' justifyContent='center'>
                    <Paper elevation={1}>
                        <TextField id="outlined-name" label={t('labels.name')} variant="outlined" color={'text'}
                            sx={{ width: '100%' }} value={groupName} onChange={e => setGroupName(e.target.value)} size='small'
                        />
                    </Paper>
                    <Paper elevation={1}>
                        <TextField disabled id="outlined-members" label={t('labels.members')} variant="outlined" color={'text'}
                            sx={{ width: '100%' }} value={group.members.length} size='small'
                        />
                    </Paper>
                    <Button disabled={!hasDiff} sx={{
                        "&:hover": { backgroundColor: hasDiff ? 'info.hover' : 'transparent', },
                        borderRadius: 15, backgroundColor: hasDiff ? 'info.main' : 'transparent',
                        borderColor: 'drawer.button', borderWidth: 1, borderStyle: 'solid',
                        color: 'text.main', width: '100%'
                    }} onClick={onSave}
                    >{t('labels.save')}</Button>
                </Stack>
                <Box sx={{ p: 2, pl: 0 }}>
                    <div className="group-actions">
                        <div className="separator"></div>
                        <div className="info-row" onClick={() => onMembers(group)} style={{
                                borderLeft: '1px solid rgba(255, 255, 255, 0.12)',
                                borderRight: '1px solid rgba(255, 255, 255, 0.12)',
                        }}>
                            <div className="title" style={{marginRight: 6}}>Leden</div>
                            <div className="pencil"></div>
                        </div>
                        <div className="info-row" onClick={() => onAccess(group)} style={{
                                borderLeft: '1px solid rgba(255, 255, 255, 0.12)',
                                borderRight: '1px solid rgba(255, 255, 255, 0.12)',
                        }}>
                            <div className="title" style={{marginRight: 6}}>Toegang</div>
                            <div className="pencil"></div>
                        </div>
                            <div className="info-row" onClick={() => onDelete(group)} style={{
                                borderLeft: '1px solid rgba(255, 255, 255, 0.12)',
                                borderRight: '1px solid rgba(255, 255, 255, 0.12)',
                        }}>
                                <div className="title" style={{marginRight: 6}}>Verwijderen</div>
                                <div className="bin"></div>
                            </div>
                    </div>
                </Box>
            </Stack>
        );
    }

    return (
        <div style={{height: '100%'}}>
            <div style={{padding: 24, alignItems: 'center', justifyContent: 'center', width: '100%', display: 'flex'}}>
                <div style={{
                    alignItems: 'center',
                    backgroundColor: '#0a0a0a',
                    borderRadius: 150,
                    color: '#fff',
                    display: "flex",
                    fontSize: 13,
                    height: 150,
                    justifyContent: 'center',
                    width: 150,
                }}>
                    <GroupIcon sx={{height: 75, width: 75}}/>
                </div>
            </div>
            <Divider />
            <Box sx={{p: 2,}}>
                <Stack spacing={2}>
                    <Paper elevation={1}>
                        <TextField id="outlined-name" label={t('labels.name')} variant="outlined" color={'text'}
                            sx={{ width: '100%' }} value={groupName} onChange={e => setGroupName(e.target.value)} size='small'
                        />
                    </Paper>
                    <Paper elevation={1}>
                        <TextField disabled id="outlined-members" label={t('labels.members')} variant="outlined" color={'text'}
                            sx={{ width: '100%' }} value={group.members.length} size='small'
                        />
                    </Paper>
                    <Button disabled={!hasDiff} sx={{
                        "&:hover": { backgroundColor: hasDiff ? 'info.hover' : 'transparent', },
                        borderRadius: 15, backgroundColor: hasDiff ? 'info.main' : 'transparent',
                        borderColor: 'drawer.button', borderWidth: 1, borderStyle: 'solid',
                        color: 'text.main',
                    }} onClick={onSave}
                    >{t('labels.save')}</Button>
                </Stack>
            </Box>
            <div className="group-actions">
                <div className="separator"></div>
                <div className="info-row-static">
                    <div className="title">{t('labels.members')}</div>
                </div>
                <div className="info-row" onClick={() => onMembers(group)}>
                    <div className="title child">{t('labels.edit')}</div>
                    <div className="pencil"></div>
                </div>
                <a href={export_url} target="_blank" rel="noreferrer">
                    <div className="info-row indented">
                        <div className="title child">{t('labels.export')}</div>
                        <div className="download"></div>
                    </div>
                </a>
                <div className="info-row" onClick={() => onAccess(group)}>
                    <div className="title">{t('labels.access')}</div>
                    <div className="pencil"></div>
                </div>
                    <div className="info-row" onClick={() => onDelete(group)}>
                        <div className="title">{t('actions.delete')}</div>
                        <div className="bin"></div>
                    </div>
                </div>
        </div>
    );
}

export default ListGroupDetails;