import React, { useState } from 'react';
import '../../index.css';
import './sign_in.css';

const SignIn = ({ authService, loading }) => {

    const [email, setEmail]                = useState('');
    const [password, setPassword]          = useState('');
    const [errorMessage, setErrorMesssage] = useState(null);

    const login = async(event) => {
        /* Called when the login button is clicked */

        /* Prevent default HTML button action */
        event.preventDefault();

        try{
            /* Stop when we got no email or password. */
            if(!email || !password) {
                return;
            }
            /* API calls not implemented yet */
            const response = await authService.login(email, password);
            if(response.success) {
                console.log('login succesful');
            } else {
                /* Do not show an error if redirected to SSO */
                if(!response.redir) throw Error(response.error);
            }
        }catch(error)
        {
            /* Show error message and hide it after 2 seconds. */
            setErrorMesssage(error.message);
            setTimeout(() => setErrorMesssage(null), 3000);
        }
    }

    if (loading) return null;

    return (
        <div className="sign-in page">
            <div className="inner">
                <form id="login-form">
                    <div className="logo"></div>
                    <div className="container">
                        <div className="row">
                        <input
                            type="text"
                            placeholder="E-mailadres"
                            name="uname"
                            value={email}
                            onChange={(event) => {setEmail(event.target.value)}}
                            autoComplete="off"
                            required
                        />
                        </div>
                        <div className="row">
                        <input
                            type="password"
                            placeholder="Wachtwoord"
                            name="psw"
                            value={password}
                            onChange={(event) => {setPassword(event.target.value)}}
                            required
                        />
                        </div>
                        <p className="small" onClick={() => window.location.href = "https://api.nextxs.nl/account/password_reset/"}> Wachtwoord vergeten?</p>
                        <div className="row submit">
                            <div>
                                <button type="submit" className="login-button" id="login-button" onClick={login}>
                                Inloggen
                                </button>
                            </div>
                        </div>
                        <p id="login-error">
                            {errorMessage}
                        </p>
                    </div>
                </form>
            </div>
        </div>
    )
}

export default SignIn;
