class LockService {

    constructor(apiService) {

        this.apiService = apiService;
        this.locks = []; 
    }

    async retrieveLocks(filter_non_setup=false) {
        if(this.apiService){
            if(filter_non_setup){
                const response = await this.apiService.retrieveLocks(true);
                if(response.success)
                {
                    this.locks = response.data.results;
                    return this.locks;
                }
            }else{
                const response = await this.apiService.retrieveLocks();
                if(response.success)
                {
                    this.locks = response.data.results;
                    return this.locks;
                }
            }
        }
        return this.locks;
    }

    getLockName(pk) {
        for(var i = 0; i < this.locks.length; i++) {
            if ( this.locks[i].id === pk) {
                return this.locks[i].lock_name;
            }
        }
    }

    getLock(pk) {
        for(var i = 0; i < this.locks.length; i++) {
            if ( this.locks[i].id === pk) {
                return this.locks[i];
            }
        }
    }

    addUser(lock_id, email, full_access) {
        /* Add a user to a lock */
        var lock = this.locks.find((lock) => {return lock.id === lock_id} );
        lock.users.push({user: email, full_access: full_access})
    }

    removeUser(lock_id, email) {
        /* Removes a user from a lock */
        const lock = this.locks.find((lock) => {return lock.id === lock_id} );
        const user_index = lock.users.findIndex((user) => {return user.user === email} );
        if(user_index !== -1) lock.users.splice(user_index, 1);
    }

    updateAccess(lock_id, email, full_access) {
        /* Updates the users access */
        const lock = this.locks.find((lock) => {return lock.id === lock_id} );
        const user = lock.users.find((user) => {return user.user === email} );
        user.full_access = full_access;
    }

    async poll() {
        /* Retrieves locks from the API */
        this.locks = await this.apiService.retrieveLocks();
    }
    
}
export default LockService;