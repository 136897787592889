import React, { useContext, useEffect, useMemo, useState } from 'react';
import '../../index.css';
import { ServiceContext } from '../../context/ServiceContext';
import { removeHypen } from '../../util/util.js';
import { useTranslation } from 'react-i18next';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, } from '@mui/material';
import { withStyles } from '@mui/styles';
import useWindowDimensions from '../../hooks/UseWindowDimensions';
import ListEntryBuilding from '../../components/list_entry_building/ListEntryBuilding';
import ListEntryBuildingSkeleton from '../../components/list_entry_building/ListEntryBuildingSkeleton';


const StyledTableCell = withStyles({
    root: {
        fontSize: 10,
        textTransform: 'uppercase',
        padding: '6px',
        paddingTop: 0,
    }
})(TableCell);


export default function Presence({windowHeight, location}) {
    const { t } = useTranslation();
    const { width } = useWindowDimensions();
    const context = useContext(ServiceContext);

    const [filter, setFilter] = useState('');
    const [buildings, setBuildings] = useState(null);
    const [filteredBuildings, setFilteredBuildings] = useState(null);
    const [loading, setLoading] = useState(true);
    const is_small_screen = width < 850;

    let headCells = [
        {
            id: 'name',
            label: t('labels.building'),
        },
    ];

    const renderBuildings = useMemo(() => {
        if (loading){
            let skeletons = [];
            for (let i=0; i < 5; i++) {
                skeletons.push(<ListEntryBuildingSkeleton idx={i} key={"skell_" + i}/>);
            }
            return skeletons;
        }
        else if (filteredBuildings) {
            return filteredBuildings.map((building) => <ListEntryBuilding building={building} key={"build_" + building.id}/>);
       }
       return [];
    }, [filteredBuildings, loading]);

    useEffect(() => {
        /* Fetch data */
        context.orgService.getSelectedOrgBuildings().then(res => {
            setBuildings(res);
            setLoading(false);
        });
    }, [context.orgService]);

    useEffect(() => {
        /* Apply search bar filter */
        if (!buildings) return;
        setFilteredBuildings(() => buildings.filter(
            building => building.name.toLowerCase().includes(
                filter.toLowerCase()
            )
        ));
    }, [filter, buildings, ]);

    return (
        <div>
            <div className="page-inner" style={{padding: 0}}>
                <div className="left-side" style={{ width: 'calc(100%)', padding: 0}}>
                    <div className="actions">
                        <div className="search-bar" style={{ 
                            width: is_small_screen ? 'calc(90% - 15px)' : 'calc(50% - 15px)', marginLeft: 'auto', marginRight: "auto"
                        }}>
                            <div className="icon"></div>
                            <input type="text" placeholder={t("search-placeholders.building")}
                                value={filter}
                                onChange={(event) => setFilter(event.target.value)}
                            />
                        </div>
                    </div>
                    <TableContainer style={{
                        maxHeight: windowHeight - 207, width: is_small_screen ? '90%' : '50%', marginLeft: "auto", marginRight: "auto"
                    }}>
                        <Table stickyHeader>
                            <TableHead>
                                <TableRow>
                                    {headCells.map((headCell) => (<StyledTableCell key={removeHypen("head_" + headCell.id)}>{headCell.label}</StyledTableCell>))}
                                    <TableCell padding="checkbox"/>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {renderBuildings}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </div>
                
            </div>
        </div>);
}