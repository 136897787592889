import { createTheme } from "@mui/material/styles";

export const NextXSTheme = createTheme({
  palette: {
    mode: 'dark',
    primary: {
      main: '#0ADE0A',
    },
    background: {
      default: '#0a0a0a',
      navigation: '#0f0f0f',
    paper: '#111213',
    },
    info: {
      main: '#0867DE',
      hover: '#053e85'
    },
    warning: {
      main: '#FAA628',
    },
    error: {
      main: '#ED1C24',
    },
    success: {
      main: '#0ADE0A',
    },
    body: {
      default: '#0a0a0a',
    },
    text: {
      main: "#fff",
    },
    dimmed: {
      main: "rgba(255, 255, 255, 0.5)"
    },
    listentry: {
      main: '#272829'
    },
    drawer: {
      button: "rgba(255, 255, 255, 0.12)"
    }
  },
  typography: {
    fontFamily: [
      "Open Sans",
      "sans - serif",
      "-apple - system",
      "BlinkMacSystemFont",
      "Segoe UI",
      "Roboto",
      "Oxygen",
      "Ubuntu",
      "Cantarell",
      "Fira Sans",
      "Droid Sans",
      "Helvetica Neue",
      "sans - serif",
    ].join(","),
    body1: {
      fontFamily: "Open Sans",
      fontWeight: 600,
      fontSize: 14
    },
  },
  shape: {
    borderRadius: 4,
  },
  props: {
    MuiAppBar: {
      color: "transparent",
    },
  },
  zIndex: {
    snackbar: 1700
  }
});

