import React, { useContext } from 'react';
import Nav from '../../components/nav/Nav';
import { useTranslation } from 'react-i18next';
import { Box } from '@mui/system';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import Locks from '../locks/Locks';
import Bridges from '../bridges/Bridges';
import Cards from '../cards/Cards';
import { ServiceContext } from '../../context/ServiceContext';
import useWindowDimensions from '../../hooks/UseWindowDimensions';

export default function Hardware(props) {
    const { t } = useTranslation();
    const { height, width } = useWindowDimensions(); 
    const { subMenuLayout, setSubMenuLayout } = useContext(ServiceContext);

    const [value, setValue] = React.useState(subMenuLayout.hardware);

    const handleChange = (event, newValue) => {
        setValue(newValue);
        setSubMenuLayout(cur_layout => ({...cur_layout, hardware: newValue}));
    };

    const small_width = 850;

    return (
        <div className="hardware">
            <Nav />
            <TabContext value={value}>
                <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                    <TabList onChange={handleChange} style={{marginTop: 48}}>
                        <Tab label={t('navigation.locks')} value="1" sx={{fontSize: 12, textTransform:'initial'}}/>
                        <Tab label={t('navigation.bridges')} value="2" sx={{ fontSize: 12, textTransform: 'initial' }} />
                        <Tab label={t('navigation.cards')} value="3" sx={{fontSize: 12, textTransform:'initial'}} />
                    </TabList>
                </Box>
                <TabPanel value="1"><Locks {...props} smallScreen={width <= small_width} windowHeight={height}/></TabPanel>
                <TabPanel value="2"><Bridges {...props} smallScreen={width <= small_width} windowHeight={height}/></TabPanel>
                <TabPanel value="3"><Cards {...props} /></TabPanel>
            </TabContext>
        </div>
  )
}
