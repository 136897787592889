import React from 'react';
import { useTranslation } from 'react-i18next';
import styled, { keyframes } from "styled-components";
import { Avatar, Stack, TableCell, TableRow, Tooltip } from '@mui/material';
import BridgeIcon from '../../assets/svgs/wireless_white.svg';
import { withStyles } from '@mui/styles';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CloudOffIcon from '@mui/icons-material/CloudOff';
import Flash from "@bit/formidablelabs.react-animations.flash";
import Fade from '@mui/material/Fade';

const FlashAnimation = keyframes`${Flash}`;
const FlashDiv = styled.div`
  animation: infinite 2s ${FlashAnimation};
`;

const StyledTableRow = withStyles({
    root: {
        cursor: 'pointer',
        backgroundColor: 'rgba(39, 40, 41, 0.9)',
        "&:hover": {
            backgroundColor: 'rgba(39, 40, 41, 0.7)',
        }
    }
})(TableRow);

const StyledTableCell = withStyles({
    root: {
        borderBottom: '1px solid #181818',
        fontSize: 13,
        padding: '6px'
    }
})(TableCell);


function ListEntryBridge({ bridge, onSelect, onEdit, selected }) {
    const { t } = useTranslation();
    var is_selected = false;
    if (selected) is_selected = selected.pk === bridge.pk;

    const click_blacklist = ['MuiButtonBase-root', 'SVGAnimatedStringPrototype', 'MuiIconButton-root', 'count', 'edit'];

    return (
        <StyledTableRow style={is_selected ? {backgroundColor: 'rgba(39, 40, 41, 0.7)'} : {}} onClick={(event) => {
            /* Filter out events from the buttons */
            if (!click_blacklist.includes(event.target.className))
                console.log(event.target.className);
                onSelect(bridge);
        }}>
            <StyledTableCell>
                <Avatar sx={{
                    backgroundColor: '#181818',
                    color: '#fff',
                    fontSize: 13,
                    height: 34,
                    marginLeft: '6px',
                    width: 34,
                }}>
                    <img src={BridgeIcon} alt="logo" height={24} />
                </Avatar>
            </StyledTableCell>
            <StyledTableCell style={{fontWeight: 'bold'}}>
                {bridge.name}
            </StyledTableCell>
            <StyledTableCell>
                <Stack direction='row' alignItems='center'>
                    {bridge.locks.length}
                    {/* <IconButton onClick={() => onEdit(bridge)}>
                        <EditIcon sx={{ "&:hover": { color: '#0ADE0A' }, height: 20, width: 20}}/>
                    </IconButton> */}
                </Stack>
            </StyledTableCell>
            <StyledTableCell>
                {bridge.is_online ?
                    <Tooltip title={t('labels.online')}><CheckCircleIcon color='primary' sx={{height: 16, width: 16}} TransitionComponent={Fade} TransitionProps={{ timeout: 300 }}/></Tooltip>
                    :
                    <Tooltip title={t('labels.offline')}><FlashDiv><CloudOffIcon color='error' sx={{height: 16, width: 16}} TransitionComponent={Fade} TransitionProps={{ timeout: 300 }}/></FlashDiv></Tooltip>}
            </StyledTableCell>
        </StyledTableRow>
    );
}

export default ListEntryBridge;