import React from 'react';
import './nav.css';
import '../../index.css';
import { ServiceContext } from '../../context/ServiceContext';
import { Redirect } from 'react-router-dom';
import Logo from '../../assets/svgs/logo-light.svg';
import LanguageIcon from '@mui/icons-material/Language';
import LogoutIcon from '@mui/icons-material/Logout';
import OrganizationIcon from '@mui/icons-material/CorporateFare';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import PersonIcon from '@mui/icons-material/Person';
import {
  AppBar, Avatar, Box, Button, Divider, ListItem, ListItemAvatar, ListItemButton,
  ListItemText, Menu, MenuItem, Stack, Toolbar, Tooltip
} from '@mui/material';
import i18n from '../../i18n';
import { withTranslation } from 'react-i18next';
import { removeHypen } from '../../util/util';
import moment from 'moment';
import 'moment/locale/nl';
import Fade from '@mui/material/Fade';


class Nav extends React.Component {

  static contextType = ServiceContext;

  constructor() {
    super();

    /* Initialize state */
    this.state = {
      showDropdown: false,
      justLoggedOut: false,
      user_avatar: null,
      user_name: '',
      user_mail: '',
      user_orgs: [],
      userAnchor: null,
      refresh: false,
    }

    /* Handles for services */
    this.authService = null;
    this.orgService = null;
    this.logout = this.logout.bind(this);
    this.setUserAnchor = this.setUserAnchor.bind(this);
    this.clearUserAnchor = this.clearUserAnchor.bind(this);
    this.renderOrg = this.renderOrg.bind(this);
  }

  componentDidMount() {
    /* Retrieve reference to authService */
    if (!this.authService && this.context) {
      this.authService = this.context.authService;
      this.setState({
        user_avatar: this.authService.getUserAvatar(),
        user_name: this.authService.getUserName(),
        user_mail: this.authService.getUserEmail()
      });
    }
    /* Retrieve referece to orgService */
    if (!this.orgService && this.context) {
      this.orgService = this.context.orgService;
      this.orgService.retrieveOrgs().then(() => {
        console.log("Retrieved organizations.")
        this.context.setSelectedOrg(this.orgService.getSelectedOrg());
      }
      );
    }
  }

  logout() {
    /* Called when the user clicks on logout */
    this.authService.logout();
    this.setState({ refresh: true });
  }

  setUserAnchor(event) {
    this.setState({ userAnchor: event.currentTarget });
  }

  clearUserAnchor() {
    this.setState({ userAnchor: null });
  }

  getCurrentPage() {
    if(window.location.href.includes("#/people")) {
      return "people";
    }
    if(window.location.href.includes("#/hardware")) {
      return "hardware";
    }
    return "logs";
  }


  renderOrg(org) {
    return (
      <MenuItem key={removeHypen("org_" + org.id + org.name)} onClick={() => this.changeSelectedOrg(org)}>
        <ListItemAvatar>
          <Avatar src={org.logo} sx={{ height: 30, width: 30 }} >
            <OrganizationIcon sx={{ height: 20, width: 20 }} />
          </Avatar>
        </ListItemAvatar>
        <ListItemText>
          {org.name}
        </ListItemText>
      </MenuItem>
    );
  }

  changeSelectedOrg(org) {
    this.authService.setSelOrg(org.id);
    this.context.setSelectedOrg(org);
    this.context.setSelectedUser('');
    this.context.setSelectedLock('');
    this.setState({ refresh: true, userAnchor: null });
  }

  renderPage(page, selected){
    return (
      <ListItemButton onClick={() => window.location.href = page.link}
        sx={{ color: selected ? 'primary.main' : 'text.main', fontSize: 12 }}
        key={removeHypen("page_" + page.text)}
      >
        {page.text}
      </ListItemButton>
    );
  }

  render() {
    const { t } = this.props;
    const cur_lang = i18n.language ? i18n.language.substring(0, 2) : "";
    moment.locale(cur_lang);
    let user_orgs = [];
    if (this.authService && this.orgService) {
      user_orgs = this.orgService.getOrgs();
      user_orgs = user_orgs.map((org, index) => this.renderOrg(org, index));
    }
    const cur_page = this.getCurrentPage();

    const pages = [
      { key: 'logs', text: t('navigation.logs'), link: "#/"},
      { key: 'people', text: t('navigation.people'), link: "#people"},
      { key: 'hardware', text: t('navigation.hardware'), link: "#hardware" },
    ];

    return (
      <AppBar position="fixed" sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}>
        <Toolbar variant='dense' style={{backgroundColor: '#181818', height: 40}}>
          <img src={Logo} alt="logo" height={30} style={{ marginRight: 24 }} />
          <Box sx={{ flexGrow: 1, display: 'flex' }}>
          <Stack direction="row">
              {
                pages.map(page => this.renderPage(page, page.key === cur_page))
              }
          </Stack>
          </Box>
          <Box sx={{ flexGrow: 0 }}>
            <div className="account" style={{ display: 'flex' }}>
              <Tooltip title={t("user-menu.menu-tooltip")} TransitionComponent={Fade} TransitionProps={{ timeout: 300 }}>
                <Button sx={{ p: 0 }} onClick={this.setUserAnchor} color='text'>
                  <Avatar src={this.context.selectedOrg ? this.context.selectedOrg.logo : null}
                    sx={{height: 30, width: 30}}
                  >
                    <OrganizationIcon size="small" />
                  </Avatar>
                  <KeyboardArrowDownIcon color='text' fontSize='small'/>
                </Button>
              </Tooltip>
              <Menu
                sx={{ mt: '45px', fontSize: '12px'}}
                id="menu-appbar"
                anchorEl={this.state.userAnchor}
                anchorOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                keepMounted
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                open={Boolean(this.state.userAnchor)}
                onClose={this.clearUserAnchor}
              >
                <MenuItem>
                  <ListItemAvatar>
                    <Avatar src={this.state.user_avatar} sx={{height: 30, width: 30}}>
                      <PersonIcon/>
                    </Avatar>
                  </ListItemAvatar>
                  <div className="user-info" >
                    <div className="nav-name">{this.state.user_name}</div>
                    <div className="nav-mail">{this.state.user_mail}</div>
                  </div>
                </MenuItem>
                <MenuItem key={"logout-user"} onClick={this.logout}>
                  <ListItemAvatar>
                    <Avatar sx={{
                      alignItems: 'center', backgroundColor: 'transparent', height: 30,
                      justifyContent: 'center', width: 30
                    }}>
                      <LogoutIcon sx={{ color: '#fff', height: 20, width: 20 }} />
                    </Avatar>
                  </ListItemAvatar>
                  <ListItemText>
                    {t("user-menu.logout")}
                  </ListItemText>
                </MenuItem>
                <Divider />
                {user_orgs}
                <Divider />
                <ListItem>
                  <ListItemAvatar>
                    <Avatar sx={{
                      alignItems: 'center', backgroundColor: 'transparent', height: 30,
                      justifyContent: 'center', width: 30
                    }}>
                      <LanguageIcon sx={{ color: '#fff', height: 20, width: 20 }} />
                    </Avatar>
                  </ListItemAvatar>
                  <Stack direction="row">
                    <Button color={cur_lang === 'nl' ? 'primary' : 'text'} onClick={() => {
                      moment.locale('nl');
                      i18n.changeLanguage('nl');
                    }}>
                      NL
                    </Button>
                    <Button color={cur_lang === 'en' ? 'primary' : 'text'} onClick={() => {
                      moment.locale('en');
                      i18n.changeLanguage('en');
                    }
                    }>
                      EN
                    </Button>
                    <Button disabled>
                      DE
                    </Button>
                  </Stack>
                </ListItem>
                <ListItem style={{ paddingTop: 0, paddingBottom: 0, margin: 0 }}>
                  <div className="nav-version">
                    {"XS Control v" + this.context.controlVersion}
                  </div>
                </ListItem>
              </Menu>
            </div>
          </Box>
        </Toolbar>
        {this.state.refresh ? <Redirect to={{
          pathname: "/refresh",
          state: {origin: cur_page}
        }}/> : null}
      </AppBar>
    );
  }
}

export default withTranslation()(Nav);
