import { dtToApiDate, readCookie } from '../util/util';

/*
    The API Service is responsible for handing all interactions
    with the API.
*/

export default class ApiService {
  constructor() {
    /* Use production API if env var does not exist */
    this.api_url = "https://api.nextxs.nl/";
    const env_api_url = process.env.REACT_APP_API_URL;
    /* If it does exist, use the URL specified in the env var instead */
    if (env_api_url) this.api_url = env_api_url;
    this.organization_id = -1;
    this.csrf_token = this.getCSRFToken();
    this.debug = false;
    console.log('Created APIService.');
  }

  setOrg(id) {
    /* Sets the organization_id property to the given id. And writes the index to the org_id to local storage. */
    console.log(`Setting organization: ${id}`);
    this.organization_id = id;
    localStorage.setItem('org_id', String(id));
  }

  async get(endpoint, paginated) {
    /* 
      Performs a GET request to the given endpoint.
      Returns a promise that always resolves to a JSON
      object: 
      {success: true/false, reason: response_code/error, data: data/null}
    */
    const full_url = paginated ? endpoint : this.api_url + endpoint;
    if (this.debug) console.log('HTTP GET to : ' + full_url);
    try {
      var response = await fetch(full_url, {
        method: 'GET',
        credentials: 'include',
        headers: {
          'Content-Type': 'application/json',
        },
      });
      if (response.status === 200) {
        try {
          response = await response.json();
          return { success: true, reason: response.status, data: response };
        }catch {
          return { success: true, reason: response.status, data: null };
        }
      } else {
        return { success: false, reason: response.status, data: null };
      }
    } catch (error) {
      console.log(error);
      return { success: false, reason: error, data: null };
    }
  }

  async delete(endpoint) {
    /* 
      Performs a DELETE request to the given endpoint.
      Returns a promise that always resolves to a JSON
      object: 
      {success: true/false, reason: response_code/error, data: data/null}
    */
    this.csrf_token = this.getCSRFToken();
    if (this.debug) console.log('HTTP DELETE to : ' + this.api_url + endpoint);
    try {
      var response = null;
      if (this.csrf_token) {
        response = await fetch(this.api_url + endpoint, {
          method: 'DELETE',
          credentials: 'include',
          headers: { 'Content-Type': 'application/json', 'X-CSRFToken': this.csrf_token },
        });
      } else {
        response = await fetch(this.api_url + endpoint, {
          method: 'DELETE',
          credentials: 'include',
          headers: { 'Content-Type': 'application/json' },
        });
      }
      if (response.status === 200 || response.status === 204) {
        return { success: true, reason: response.status, data: "" };
      } else {
        return { success: false, reason: response.status, data: null };
      }
    } catch (error) {
      console.log(error);
      return { success: false, reason: error, data: null };
    }
  }

  async post(endpoint, body) {
    /* 
      Performs a POST request to the given endpoint.
      Returns a promise that always resolves to a JSON
      object: 
      {success: true/false, reason: response_code/error, data: data/null}
    */
    this.csrf_token = this.getCSRFToken();
    if (this.debug) console.log('HTTP POST to : ' + this.api_url + endpoint);
    try {
      var response = null;
      if (this.csrf_token) {
        response = await fetch(this.api_url + endpoint, {
          method: 'POST',
          credentials: 'include',
          headers: { 'Content-Type': 'application/json', 'X-CSRFToken': this.csrf_token },
          body: body,
        });
      } else {
        response = await fetch(this.api_url + endpoint, {
          method: 'POST',
          credentials: 'include',
          headers: { 'Content-Type': 'application/json' },
          body: body,
        });
      }
      if (response.status === 200 || response.status === 201) {
        response = await response.json();
        return { success: true, reason: response.status, data: response };
      } else {
        return { success: false, reason: response.status, data: response };
      }
    } catch (error) {
      console.log(error);
      return { success: false, reason: error, data: null };
    }
  }

  async postFormData(endpoint, body) {
    /* 
      Performs a POST request to the given endpoint.
      Returns a promise that always resolves to a JSON
      object: 
      {success: true/false, reason: response_code/error, data: data/null}
    */
    this.csrf_token = this.getCSRFToken();
    if (this.debug) console.log('HTTP POST to : ' + this.api_url + endpoint);
    try {
      var response = null;
      if (this.csrf_token) {
        response = await fetch(this.api_url + endpoint, {
          method: 'POST',
          credentials: 'include',
          headers: { 'X-CSRFToken': this.csrf_token },
          body: body,
        });
      } else {
        response = await fetch(this.api_url + endpoint, {
          method: 'POST',
          credentials: 'include',
          body: body,
        });
      }
      if (response.status === 200 || response.status === 201) {
        response = await response.json();
        return { success: true, reason: response.status, data: response };
      } else {
        return { success: false, reason: response.status, data: response };
      }
    } catch (error) {
      console.log(error);
      return { success: false, reason: error, data: null };
    }
  }

  async patch(endpoint, body) {
    /* 
      Performs a PATCH request to the given endpoint.
      Returns a promise that always resolves to a JSON
      object: 
      {success: true/false, reason: response_code/error, data: data/null}
    */
    this.csrf_token = this.getCSRFToken();
    if (this.debug) console.log('HTTP PATCH to : ' + this.api_url + endpoint);
    try {
      var response = null;
      if (this.csrf_token) {
        response = await fetch(this.api_url + endpoint, {
          method: 'PATCH',
          credentials: 'include',
          headers: { 'Content-Type': 'application/json', 'X-CSRFToken': this.csrf_token },
          body: body,
        });
      } else {
        response = await fetch(this.api_url + endpoint, {
          method: 'PATCH',
          credentials: 'include',
          headers: { 'Content-Type': 'application/json' },
          body: body,
        });
      }
      if (response.status === 200) {
        response = await response.json();
        return { success: true, reason: response.status, data: response };
      } else {
        return { success: false, reason: response.status, data: null };
      }
    } catch (error) {
      console.log(error);
      return { success: false, reason: error, data: null };
    }
  }

  async paginated_get(endpoint, limit) {
    /* Does a GET request and handles pagination */
    var response_list = []
    const response =await this.get(endpoint.includes("?") ? `${endpoint}&limit=${limit}` : `${endpoint}?limit=${limit}`);
    /* If the first request fails just return the failed HTTP req */
    if (!response.success) return response;
    /* If we got the entire list, just return the list */
    if (!response.data.next) return { success: true, data: response.data.results };
    /* If we reach this point we need to get more data */
    var next_url = response.data.next;
    response_list = response.data.results;
    while (next_url) {
      const additional_response = await this.get(next_url, true);
      if (additional_response.data.results) response_list = response_list.concat(additional_response.data.results);
      additional_response.data.next ? next_url = additional_response.data.next : next_url = null;
    }
    return { success: true, data: response_list };
  }

  async paginated_get_cb(endpoint, limit, callback, initial_call, depth){
    /* Does a GET request and handles pagination */
    const response = await this.get(endpoint, !initial_call);
    /* Stop on error */
    if (!response.success || !response.data.count) {
      callback({ data: [], done: true });
      return;
    }
    callback({ data: response.data.results, done: !response.data.next});
    if (response.data.next) {
      await this.paginated_get_cb(response.data.next, limit, callback, false, depth+1);
    }
  }

  async isLoggedIn() {
    /* Check if the user is still logged in (allowed to retrieve its profile) 
       Returns: {success: true/false, reason: null/string, data: null/data}
    */
    if (this.debug) console.log('Checking if we are still authenticated');
    var response = await this.get(`users/me`);
    if (this.debug) response.success ? console.log('still authenticated!') : console.log('not authenticated');
    return response;
  }

  async getCookie(username, password) {
    /*
      Tries to post a username and password to the API to retrieve
      a session cookie. Returns a promise that resolves to the object
      created by the post() function. 
    */
    if (this.debug) console.log('Trying to login.');
    var response = await this.post(
      'auth/cookie/',
      JSON.stringify({ username, password }),
    );
    /* Check for SSO redirect */
    if (response.data && response.data.hasOwnProperty("redirect")) {
      window.location.href = response.data.redirect;
      return { "success": false, "redirect": true };
    };
    return response;
  }
  
  async retrieveSingleUser(user_id) {
    if (this.organization_id !== -1) {
      if (this.debug) console.log(`Retrieving (updated) user ${user_id}`);
      return await this.get(`organizations/${this.organization_id}/users/${user_id}/`);
    }
    return { success: false };
  }

  async getUserAccess(user_id) {
    /* Retrieve user access*/
    if (this.organization_id !== -1) {
      if (this.debug) console.log(`Retrieving access of users ${user_id}`);
      return await this.paginated_get(`organizations/${this.organization_id}/users/${user_id}/access`, 20);
    }
    return { success: false };
  }

  async createUserAccessRule(user_id, rule) {
    /* Create an access rule for a specific user */
    if (this.organization_id !== -1) {
      if (this.debug) console.log(`Creating access rule for user ${user_id}.`);
      const data = {
        from_date: rule.from_date ? rule.from_date : dtToApiDate(new Date()), to_date: rule.to_date ? rule.to_date : null, monday: rule.monday, 
        tuesday: rule.tuesday, wednesday: rule.wednesday, thursday: rule.thursday, friday: rule.friday, saturday: rule.saturday, sunday: rule.sunday, 
        from_time: rule.from_time ? rule.from_time : "00:00:00", to_time: rule.to_time ? rule.to_time : "23:59:59", 
        permanent_access: rule.full_access, lock: rule.lock, all_day: rule.all_day
      };
      return await this.post(`organizations/${this.organization_id}/users/${user_id}/access`, JSON.stringify(data));
    }
    return { success: false };
  }

  async patchUserAccessRule(user_id, rule) {
    if (this.organization_id !== -1) {
      if (this.debug) console.log(`Patching rule ${rule.id} of user ${user_id}`);
      const data = {
        from_date: rule.from_date ? rule.from_date : dtToApiDate(new Date()), to_date: rule.to_date ? rule.to_date : null,
        monday: rule.monday, tuesday: rule.tuesday, wednesday: rule.wednesday, thursday: rule.thursday,
        friday: rule.friday, saturday: rule.saturday, sunday: rule.sunday, from_time: rule.from_time ? rule.from_time : "00:00:00",
        to_time: rule.to_time ? rule.to_time : "23:59:59", permanent_access: rule.full_access,
        lock: rule.lock, all_day: rule.all_day
      };
      return await this.patch(`organizations/${this.organization_id}/users/${user_id}/access/${rule.id}`, JSON.stringify(data));
    }
    return { success: false };
  }

  async setUserAccessRuleFullAccess(user_id, access_rule_id, new_full_access, all_day) {
    if (this.organization_id !== -1) {
      if (this.debug) console.log(`Changing full access of rule${access_rule_id} to ${new_full_access} for user ${user_id}`);
      const data = { permanent_access: new_full_access, all_day: all_day };
      return await this.patch(`organizations/${this.organization_id}/users/${user_id}/access/${access_rule_id}`, JSON.stringify(data));
    }
    return { success: false };
  }

  async removeUserAccessRule(user_id, rule_id) {
    if (this.organization_id !== -1) {
      if (this.debug) console.log(`Removing access rule ${rule_id} from user ${user_id}`);
      return await this.delete(`organizations/${this.organization_id}/users/${user_id}/access/${rule_id}`);
    }
    return { success: false };
  }

  async retrieveBridges(callback){
    /* Retrieve the list of bridges */
    if(this.organization_id !== -1){
      if (this.debug) console.log(`Retrieving list of bridges for organization ${this.organization_id}`);
      return await this.paginated_get_cb(`organizations/${this.organization_id}/bridges?limit=20`, 20, callback, true, 1);
    }
  }

  async assingLocksToBridge(bridge_id, locks) {
    /* Patch a lock with upated properties */
    if(this.organization_id !== -1) {
      if (this.debug) console.log(`Assigning locks ${locks} to bridge ${bridge_id}`);
      const data = {"locks": locks};
      return await this.post(`organizations/${this.organization_id}/bridges/${bridge_id}/add_locks`, JSON.stringify(data));
    }
  }

  async removeLocksFromBridge(bridge_id, locks) {
    /* Patch a lock with upated properties */
    if(this.organization_id !== -1) {
      if (this.debug) console.log(`Removing locks ${locks} from bridge ${bridge_id}`);
      const data = {"locks": locks};
      return await this.post(`organizations/${this.organization_id}/bridges/${bridge_id}/remove_locks`, JSON.stringify(data));
    }
  }

  async retrieveLocks(filter_non_setup=false){
    /* Retrieve the list of locks */
    if(this.organization_id !== -1){
        if (this.debug) console.log(`Retrieving list of locks for organization ${this.organization_id}`);
        return await this.get(filter_non_setup ? `organizations/${this.organization_id}/locks?is_setup=true&limit=200` : `organizations/${this.organization_id}/locks?limit=200`);
    }
    return { success: false };
  }

  async updateLock(lock_id, updated_properties) {
    /* Patch a lock with upated properties */
    if (this.organization_id !== -1) {
      if (this.debug) console.log(`Updating lock ${lock_id}`);
      return await this.patch(`locks/${lock_id}`, JSON.stringify(updated_properties));
    }
  }

  async retrieveUsers() {
    /* Retrieve the list of users */
    if (this.organization_id !== -1) {
      if (this.debug) console.log(`Retrieving list of users for organization ${this.organization_id}`);
      return await this.paginated_get(`organizations/${this.organization_id}/users`, 200);
    }
    return { success: false };
  }

  async retrieveUsersLazy(callback){
    /* Retrieve the list of users */
    if(this.organization_id !== -1){
      if (this.debug) console.log(`Retrieving list of users for organization ${this.organization_id}`);
      return await this.paginated_get_cb(`organizations/${this.organization_id}/users?limit=75`, 75, callback, true, 1);
    }
  }

  async addUser(user_email) {
    /* Adds a user to the organization */
    if (this.organization_id !== -1) {
      if (this.debug) console.log(`Adding user ${user_email} to organization ${this.organization_id}`);
      const data = { email: user_email };
      return await this.post(`organizations/${this.organization_id}/users`, JSON.stringify(data));
    }
    return { success: false };
  }

  async addGroup(group_name) {
    /* Adds a group to the organization */
    if (this.organization_id !== -1) {
      if (this.debug) console.log(`Adding group ${group_name} to organization ${this.organization_id}`);
      const data = { name: group_name };
      return await this.post(`organizations/${this.organization_id}/usergroups`, JSON.stringify(data));
    }
    return { success: false };
  }

  async inviteUser(user_email) {
    /* Invite a user to join NextXS and the current organization */
    if (this.organization_id !== -1) {
      if (this.debug) console.log(`Inviting user ${user_email} to organization ${this.organization_id}`);
      const data = { email: user_email };
      return await this.post(`organizations/${this.organization_id}/invites`, JSON.stringify(data));
    }
    return { success: false };
  }

  async removeUser(user_id) {
    /* Remove a user from the current organization */
    if (this.organization_id !== -1) {
      if (this.debug) console.log(`Removing user ${user_id} from organization ${this.organization_id}`);
      return await this.delete(`organizations/${this.organization_id}/users/${user_id}`);
    }
    return { success: false };
  }

  async removeGroup(group_id) {
    /* Remove a group from the current organization */
    if (this.organization_id !== -1) {
      if (this.debug) console.log(`Removing group ${group_id} from organization ${this.organization_id}`);
      return await this.delete(`organizations/${this.organization_id}/usergroups/${group_id}`);
    }
    return { success: false };
  }

  async retrieveLogs() {
    /* Retrieve as many logs as the API can send in one GET. */
    if (this.organization_id !== -1) {
      if (this.debug) console.log(`Retrieving list of logs for organization ${this.organization_id}`);
      return await this.get(`organizations/${this.organization_id}/logs`);
    }
    return { success: false };
  }

  async retrieveLatestLogs() {
    /* Retrieve the 5 latest logs from the API */
    if (this.organization_id !== -1) {
      if (this.debug) console.log(`Retrieving list of logs for organization ${this.organization_id}`);
      return await this.get(`organizations/${this.organization_id}/logs?limit=5`);
    }
    return { success: false };
  }

  async retrieveLogsByDates(date, date_to, limit) {
    /* Retrieve logs from the API by date */
    if (this.organization_id !== -1) {
      if (this.debug) console.log(`Retrieving list of logs for organization ${this.organization_id}`);
      return await this.paginated_get(`organizations/${this.organization_id}/logs?from_date=${date}&to_date=${date_to}`, limit);
    }
    return { success: false };
  }


  async getOrgs() {
    /* Retrieve organizations*/
    if (this.debug) console.log(`Retrieving list of accesible organizations`);
    return await this.paginated_get(`organizations`,  25);
  }

  async getUserGroups() {
    /* Retrieve usergroups*/
    if (this.organization_id !== -1) {
      if (this.debug) console.log(`Retrieving list of usergroups for organization ${this.organization_id}`);
      return await this.paginated_get(`organizations/${this.organization_id}/usergroups`, 25);
    }
    return { success: false };
  }

  async assignCard(card_id, user_id) {
    if (this.organization_id !== -1) {
      if (this.debug) console.log(`Assigning card ${card_id} to user ${user_id}`);
      const data = { user: user_id };
      return await this.patch(`organizations/${this.organization_id}/cards/${card_id}/`, JSON.stringify(data));
    }
  }

  async unassignCard(card_id) {
    if (this.organization_id !== -1) {
      if (this.debug) console.log(`unassigning card ${card_id}`);
      const data = { user: null };
      return await this.patch(`organizations/${this.organization_id}/cards/${card_id}/`, JSON.stringify(data));
    }
  }

  async getOrgCards() {
    /* Retrieve organization cards*/
    if (this.organization_id !== -1) {
      if (this.debug) console.log(`Retrieving list of cards for organization ${this.organization_id}`);
      return await this.paginated_get(`organizations/${this.organization_id}/cards`, 100);
    }
    return { success: false };
  }


  getCSRFToken() {
    /* Gets the current CSRF token or an empty string if not set */
    return readCookie("csrftoken");
  }

  getOrgId() {
    /* Gets the current org_id , or set's it to 0 if not set 
       This is the ID of the organization
    */
    const value = localStorage.getItem("org_id");
    if (value) {
      return parseInt(value);
    }
    localStorage.setItem("org_id", "0");
    return 0;
  }

  async getGroupAccess(group_id) {
    /* Retrieve usergroups*/
    if (this.organization_id !== -1) {
      if (this.debug) console.log(`Retrieving access of usergroups ${group_id}`);
      return await this.paginated_get(`organizations/${this.organization_id}/usergroups/${group_id}/access`, 20);
    }
    return { success: false };
  }

  async createGroupAccessRule(group_id, rule) {
    /* Create an access rule for a specific usergroup */
    if (this.organization_id !== -1) {
      if (this.debug) console.log(`Creating access rule for group ${group_id}.`);
      const data = {
        from_date: rule.from_date ? rule.from_date : dtToApiDate(new Date()), to_date: rule.to_date ? rule.to_date : null, monday: rule.monday, 
        tuesday: rule.tuesday, wednesday: rule.wednesday, thursday: rule.thursday, friday: rule.friday, saturday: rule.saturday, sunday: rule.sunday, 
        from_time: rule.from_time ? rule.from_time : "00:00:00", to_time: rule.to_time ? rule.to_time : "23:59:59", 
        permanent_access: rule.full_access, lock: rule.lock, all_day: rule.all_day
      };
      return await this.post(`organizations/${this.organization_id}/usergroups/${group_id}/access`, JSON.stringify(data));
    }
    return { success: false };
  }

  async patchGroupAccessRule(group_id, rule) {
    if (this.organization_id !== -1) {
      if (this.debug) console.log(`Patching rule ${rule.id} of group ${group_id}`);
      const data = {
        from_date: rule.from_date ? rule.from_date : dtToApiDate(new Date()), to_date: rule.to_date ? rule.to_date : null,
        monday: rule.monday, tuesday: rule.tuesday, wednesday: rule.wednesday, thursday: rule.thursday,
        friday: rule.friday, saturday: rule.saturday, sunday: rule.sunday, from_time: rule.from_time ? rule.from_time : "00:00:00",
        to_time: rule.to_time ? rule.to_time : "23:59:59", permanent_access: rule.full_access,
        lock: rule.lock, all_day: rule.all_day
      };
      return await this.patch(`organizations/${this.organization_id}/usergroups/${group_id}/access/${rule.id}`, JSON.stringify(data));
    }
    return { success: false };
  }

  async setGroupAccessRuleFullAccess(group_id, access_rule_id, new_full_access, all_day) {
    if (this.organization_id !== -1) {
      if (this.debug) console.log(`Changing full access of rule${access_rule_id} to ${new_full_access} for group ${group_id}`);
      const data = { permanent_access: new_full_access, all_day: all_day};
      return await this.patch(`organizations/${this.organization_id}/usergroups/${group_id}/access/${access_rule_id}`, JSON.stringify(data));
    }
    return { success: false };
  }

  async removeGroupAccessRule(group_id, rule_id) {
    if (this.organization_id !== -1) {
      if (this.debug) console.log(`Removing access rule ${rule_id} from group ${group_id}`);
      return await this.delete(`organizations/${this.organization_id}/usergroups/${group_id}/access/${rule_id}`);
    }
    return { success: false };
  }

  async changeGroupName(group_id, group_name) {
    if (this.organization_id !== -1) {
      if (this.debug) console.log(`Changing the name of group ${group_id} to ${group_name}`);
      return await this.patch(`organizations/${this.organization_id}/usergroups/${group_id}`, JSON.stringify({ name: group_name }));
    }
    return { success: false };
  }

  async addUserToGroup(group_id, user_id) {
    /* Patch a lock with upated properties */
    if (this.organization_id !== -1) {
      if (this.debug) console.log(`Adding user ${user_id} to group ${group_id}`);
      const formData = new FormData();
      formData.append('user', user_id);
      return await this.postFormData(`organizations/${this.organization_id}/usergroups/${group_id}/add`, formData);
    }
  }

  async remUserFromGroup(group_id, user_id) {
    /* Patch a lock with upated properties */
    if (this.organization_id !== -1) {
      if (this.debug) console.log(`Adding user ${user_id} to group ${group_id}`);
      const formData = new FormData();
      formData.append('user', user_id);
      return await this.postFormData(`organizations/${this.organization_id}/usergroups/${group_id}/remove`, formData);
    }
  }

  async logOut() {
    /* Tell the API to remove the session cookie.
       Returns {success: true/false, reason: null/string, data: null/data}
    */
    if (this.debug) console.log('Telling API to remove session cookie.');
    var response = await this.get(`auth/logout/`);
    if (this.debug) response.success ? console.log('Logged out!') : console.log('Couldn\'t log out!');
    return response;
  }

}
