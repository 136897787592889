import React, { useState } from 'react';
import '../../index.css';
import './create_user.css';
import { useTranslation } from 'react-i18next';


const CreateUser = ({onAdd, onCancel, onInvite}) => {
    const { t } = useTranslation();
    const [email, setEmail]               = useState('');
    const [showInvalid, setShowInvalid]   = useState(false);
    const [needRegister, setNeedRegister] = useState(false);

    const validateEmail = (email) => {
        /* Checks if the input is a valid email */
        // eslint-disable-next-line
        const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(String(email).toLowerCase());
    }

    const onSubmit = async () => {
        /* 
            Executed when users clicks "add" 
            Checks if user entered a valid email.
            Flashes input field red on invalid input.
        */
        if(validateEmail(email)) {
            const response = await onAdd(email);
            if(!response.success && response.reason === "not-registered"){
                setNeedRegister(true);
            }
        }else{
            setShowInvalid(true);
            setTimeout(() => {setShowInvalid(false);}, 300);
        }
    }

    const onInviteClicked = async () => {
        await onInvite(email);
    }

    const onPopUpClicked = (e) => {
        /* Called when the user clicks the popup. Closes the popup when
           the user clicked on the outer part.
        */
        if(e.target.className  === "pop-up create-user")
        {
            /* User clicked on the background */
            onCancel();
        }
    }

    if(needRegister){
        return (
            <div>
                <div className="pop-up create-user" onClick={onPopUpClicked}>
                    <div className="inner">
                        <div className="top">
                            <div className="close" onClick={onCancel}></div>
                        </div>
                        <div className="middle">
                            <h2>{t("user-management.invite_user")}</h2>
                            <p>{t("user-management.invite_user_detail", {email: email})}</p>
                        </div>
                        <div className="bottom">
                            <div className="buttons">
                                <button className="button cancel" onClick={onCancel}>{t('labels.cancel')}</button>
                                <button className="button add" onClick={onInviteClicked}>{t('labels.invite')}</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
    return (
        <div>
            <div className="pop-up create-user" onClick={onPopUpClicked}>
                <div className="inner">
                    <div className="top">
                        <div className="close" onClick={onCancel}></div>
                    </div>
                    <div className="middle">
                        <h2>{t('user-management.add_user_to_org')}</h2>
                        <div className="form-part">
                            <input type="text" placeholder={t('labels.email-address')} value={email}
                                   onChange={(event) => setEmail(event.target.value)}
                                   style={{borderColor : showInvalid ? "red" : null }}
                                   />
                        </div>
                    </div>
                    <div className="bottom">
                        <div className="buttons">
                            <button className="button cancel" onClick={onCancel}>{t('labels.cancel')}</button>
                            <button className="button add" onClick={onSubmit}>{t('labels.add')}</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default CreateUser;
