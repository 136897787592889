import React from 'react';
import './AccessMatrix.css';

function EmptyRow(props) {
    return (        
    <div className="col" id="slide-1">
    <div className="cell access-lock">
        <div className={"lock-name"}></div>
    </div>
    <div className="cell">
        <div className="left">
            <div className="access-dates"/>
            <div className="access-days">
                <div className={"access-day"}></div>
                <div className={"access-day"}></div>
                <div className={"access-day"}></div>
                <div className={"access-day"}></div>
                <div className={"access-day"}></div>
                <div className={"access-day"}></div>
                <div className={"access-day"}></div>
            </div>
            
        </div>
            <div className="right">{""}</div>
    </div>
    <div className="cell inactive"></div>
    <div className="cell inactive"></div>
    <div className="cell inactive"></div>
    <div className="cell inactive"></div>
    <div className="cell inactive"></div>
    <div className="cell inactive"></div>
</div>
    );
}

export default EmptyRow;