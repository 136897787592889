import React, { useContext } from 'react'
import Nav from '../../components/nav/Nav'
import Unlocks from '../unlocks/Unlocks'
import { useTranslation } from 'react-i18next';
import { Box } from '@mui/system';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import { ServiceContext } from '../../context/ServiceContext';
import useWindowDimensions from '../../hooks/UseWindowDimensions';
import Presence from '../presence/Presence';

export default function Logs(props) {
    const { t } = useTranslation();
    const { height } = useWindowDimensions(); 
    const { subMenuLayout, setSubMenuLayout } = useContext(ServiceContext);

    const [value, setValue] = React.useState(subMenuLayout.logs);

    const handleChange = (event, newValue) => {
        setValue(newValue);
        setSubMenuLayout(cur_layout => ({...cur_layout, logs: newValue}));
    };

    return (
        <div className="unlocks page">
            <Nav />
            <TabContext value={value}>
                <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                    <TabList onChange={handleChange} style={{marginTop: 48}}>
                        <Tab label={t('navigation.unlocks')} value="1" sx={{fontSize: 12, textTransform:'initial'}}/>
                        <Tab label={t('navigation.presence')} value="2" sx={{fontSize: 12, textTransform:'initial'}}/>
                    </TabList>
                </Box>
                <TabPanel value="1"><Unlocks {...props} windowHeight={height}/></TabPanel>
                <TabPanel value="2"><Presence {...props} windowHeight={height}/></TabPanel>
            </TabContext>
        </div>
  )
}
