import React, { useState } from 'react';
import LockService from '../services/LockService';
import LogService from '../services/LogService';
import OrganizationService from '../services/OrganizationService';
import UserService from '../services/UserService';
import BridgeService from '../services/BridgeService';

const ServiceContext = React.createContext({});

const ServiceContextProvider = (props) => {

  const apiService    = props.apiService;
  const authService   = props.authService;
  const [lockService] = useState(new LockService(apiService));
  const [userService] = useState(new UserService(apiService));
  const [logService]  = useState(new LogService(apiService));
  const [orgService]  = useState(new OrganizationService(apiService));
  const [bridgeService] = useState(new BridgeService(apiService));
  const [selectedGroup, setSelectedGroup] = useState(null);
  const [selectedUser, setSelectedUser] = useState(null);
  const [selectedLock, setSelectedLock] = useState(null);
  const [selectedOrg, setSelectedOrg] = useState(null);
  const [subMenuLayout, setSubMenuLayout] = useState({logs: "1", people: "1", hardware: "1"});
  const controlVersion = "2.1.6";
  
  const context = {
    apiService,
    lockService,
    userService,
    logService,
    authService,
    orgService,
    bridgeService,
    selectedGroup,
    setSelectedGroup,
    selectedUser,
    setSelectedUser,
    selectedLock,
    setSelectedLock,
    selectedOrg,
    setSelectedOrg,
    subMenuLayout,
    setSubMenuLayout,
    controlVersion
  };

  return (
    <ServiceContext.Provider value={context}>
      {props.children}
    </ServiceContext.Provider>
  );
}

export { ServiceContext, ServiceContextProvider };