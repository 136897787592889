import React, { useState } from 'react';
import '../../index.css';


const CreateGroup = ({onAdd, onCancel}) => {
    
    const [groupName, setGroupName] = useState('');
    
    const onPopUpClicked = (e) => {
        /* Called when the user clicks the popup. Closes the popup when
           the user clicked on the outer part.
        */
        if(e.target.className  === "pop-up create-user")
        {
            /* User clicked on the background */
            onCancel();
        }
    }

    return (
        <div>
            <div className="pop-up create-user" onClick={onPopUpClicked}>
                <div className="inner">
                    <div className="top">
                        <div className="close" onClick={onCancel}></div>
                    </div>
                    <div className="middle">
                        <h2>Groep toevoegen</h2>
                        <div className="form-part">
                            <input type="text" placeholder="Groepnaam" value={groupName} 
                                onChange={(event) => setGroupName(event.target.value)}
                            />
                        </div>
                    </div>
                    <div className="bottom">
                        <div className="buttons">
                            <button className="button cancel" onClick={onCancel}>annuleren</button>
                            <button className="button add" onClick={() => onAdd(groupName)}>Toevoegen</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default CreateGroup;
