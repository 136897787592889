import React from 'react';
import { Stack, TableCell, TableRow } from '@mui/material';
import { withStyles } from '@mui/styles';
import Skeleton from '@mui/material/Skeleton';

const StyledTableRow = withStyles({
    root: {
        backgroundColor: 'rgba(39, 40, 41, 0.9)',
        "&:hover": {
            backgroundColor: 'rgba(39, 40, 41, 0.7)',
        }
    }
})(TableRow);

const StyledTableCell = withStyles({
    root: {
        borderBottom: '1px solid #181818',
        fontSize: 13,
        padding: '6px'
    }
})(TableCell);

function ListEntryBuildingSkeleton({idx}) {
    const item_key = `build_${idx}_skel`;
    return (
        <StyledTableRow key={item_key}>
            <StyledTableCell>
                <Stack direction="row" spacing={1} alignItems="center">
                    <Skeleton variant="circular" width={34} height={34} animation="wave"/>
                    <Skeleton variant="text" width={220} animation="wave"/>
                </Stack>
            </StyledTableCell>
            <StyledTableCell>
                <Skeleton variant="square" width={42} height={34} animation="wave"/>
            </StyledTableCell>
        </StyledTableRow>
    );
}

export default ListEntryBuildingSkeleton;
