import React from 'react';
import PendingIcon from '@mui/icons-material/Pending';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';
import { Tooltip } from '@mui/material';
import { useTranslation } from 'react-i18next';
import Fade from '@mui/material/Fade';


const XSLogState = ({ state }) => {

    const { t } = useTranslation();

    const renderIcon = () => {
        switch (state) {
            case 'Nack':
                return (
                    <Tooltip title={t('labels.failed')} TransitionComponent={Fade} TransitionProps={{ timeout: 300 }}>
                        <CancelIcon color='error' sx={{
                            height: 20,
                            width: 20,
                            }} />
                    </Tooltip>
                );
            case 'Unconfirmed':
                return (
                    <Tooltip title={t('labels.unconfirmed')} TransitionComponent={Fade} TransitionProps={{ timeout: 300 }}>
                        <PendingIcon color='warning' sx={{
                            height: 20,
                            width: 20,
                        }} />
                    </Tooltip>
                );
            default:
                return (
                    <Tooltip title={t('labels.confirmed')} TransitionComponent={Fade} TransitionProps={{ timeout: 300 }}>
                        <CheckCircleIcon color='primary' sx={{
                            height: 20,
                            width: 20,
                        }} />
                     </Tooltip>
                );
        }
    }
    return (<div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
        {renderIcon()}
    </div>)
}

export default XSLogState;
