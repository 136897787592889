import moment from 'moment';
import React from 'react';

function AccessRule({ loading, data, onEdit }) {
    
    const renderTime = (data) => {
        if (data.all_day) {
            return <div className="right">00:00 - 23:59</div>; 
        }
        let from_time = new moment(data.from_time, "HH:mm").format("HH:mm");
        let to_time = new moment(data.to_time, "HH:mm").format("HH:mm");
        return <div className="right">{`${from_time} - ${to_time}`}</div>;
    }

    return (
            <div className="cell" onClick={loading ? () => {} : () => onEdit(data)}>
                <div className="left">
                    {loading ? 
                        <div className="access-dates">
                            <div className={"access-date-shape shimmer"}/>
                        </div>
                    :
                        <div className="access-dates">
                            <div className={"date"}>{data.from_date ? data.from_date : "Geen toegang"}</div>
                            {data.from_date ? <div className={"arrow"}></div> : null}
                            {data.from_date ? <div className={"date"}>{data.to_date ? data.to_date : "∞"} &nbsp;</div> : null}
                        </div>
                    }
                    { loading ? 
                        <div className="access-days">
                            <div className={"access-day-shape shimmer"}/>
                        </div>
                    : 
                        <div className="access-days">
                            <div className={data.access[0] && data.rule_id !== -1 ? "access-day active" : "access-day"}>Ma</div>
                            <div className={data.access[1] && data.rule_id !== -1 ? "access-day active" : "access-day"}>Di</div>
                            <div className={data.access[2] && data.rule_id !== -1 ? "access-day active" : "access-day"}>Wo</div>
                            <div className={data.access[3] && data.rule_id !== -1 ? "access-day active" : "access-day"}>Do</div>
                            <div className={data.access[4] && data.rule_id !== -1 ? "access-day active" : "access-day"}>Vr</div>
                            <div className={data.access[5] && data.rule_id !== -1 ? "access-day active" : "access-day"}>Za</div>
                            <div className={data.access[6] && data.rule_id !== -1 ? "access-day active" : "access-day"}>Zo</div>
                        </div>
                    }
                </div>
                { loading ?
                    <div className="right">
                        <div className="time-shape shimmer"/>
                    </div>
                :
                    renderTime(data)
                }
            </div>);
}

export default AccessRule;