import React, { useState, useEffect, useContext } from 'react';
import { Redirect } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import './ListUserDetails.css';
import { Avatar, Divider, Paper, Stack, TextField, Autocomplete} from '@mui/material';
import { Box } from '@mui/system';
import { LoadingButton } from '@mui/lab';
import { ServiceContext } from '../../context/ServiceContext';

function ListUserDetails({ user, userService, orgService, onAccess, onDelete, smallScreen, unAssignedCards, updateUser, updateCardStatus}) {
    const { t } = useTranslation();
    const context = useContext(ServiceContext); 
    const [logRedirect, setLogRedirect] = useState(false);
    const [assignedCard, setAssignedCard] = useState(null);
    const [hasDiff, setHasDiff] = useState(false);
    const [loading, setLoading] = useState(false);
    const cardOptions = [{ "serial": t('labels.no_card') }, ...unAssignedCards];
    const orgId = context.selectedOrg && context.selectedOrg.id | 0;

    useEffect(() => {
        if (user && user.cards) {
            if (user.cards.length) {
                setAssignedCard(user.cards[0]);
            } else {
                setAssignedCard({ "serial": t('labels.no_card') });
            }
        }
    }, [user, t]);

    useEffect(() => {
        if (user) {
            if (
                (assignedCard && user.cards.length && assignedCard.serial !== user.cards[0].serial) ||
                (assignedCard && !user.cards.length && assignedCard.serial !== t('labels.no_card'))
            ) {
                !hasDiff && setHasDiff(true);
            } else {
                hasDiff && setHasDiff(false);
            }
        }
    }, [assignedCard, user, t, hasDiff]);

    const onCardAssigned = async() => {
        if (!loading) {
            setLoading(true);
            if (assignedCard.serial !== t('labels.no_card')) {
                if (user.cards.length) {
                    /* User had a different card assigned, unassign it first..*/
                    await orgService.unassignCard(user.cards[0].id);
                    updateCardStatus(user.cards[0].id, null);
                }
                await orgService.assignCard(assignedCard.id, user.id);
                updateCardStatus(assignedCard.id, user.id);
            } else {
                await orgService.unassignCard(user.cards[0].id);
                updateCardStatus(user.cards[0].id, null);
            }
            setLoading(false);
            updateUser(user.id).then().catch();
        }        
    }

    if (!userService || !user) return null;
    const export_url = `/api/organizations/${orgId}/users/${user.id}/access_export/`;

    if (smallScreen) {
        return (
            <Stack direction="row" spacing={1} justifyContent='center' alignItems='center' sx={{width: '100%', height: 200}}>
                <Stack spacing={2} direction="column" alignItems='center' justifyContent='center'>
                    <div style={{padding: 24, alignItems: 'center', justifyContent: 'center', width: '100%', display: 'flex', paddingRight: 0}}>
                        <Avatar sx={{
                            backgroundColor: '#0a0a0a',
                            color: '#fff',
                            fontSize: 24,
                            height: 75,
                            marginLeft: '6px',
                            width: 75,
                        }} src={user.avatar}>
                            {userService.getInitials(user)}
                        </Avatar>
                    </div>
                </Stack>
                <Stack spacing={2} direction="column" alignItems='center' justifyContent='center'>
                    <Box sx={{ p: 2, }}>
                        <Stack spacing={2}>
                            <Paper elevation={1}>
                                <TextField disabled id="outlined-name" label={t('labels.name')} variant="outlined" color={'text'} sx={{ width: '100%' }} value={userService.getFullName(user)} size="small"/>
                            </Paper>
                            <Paper elevation={1}>
                                <TextField disabled id="outlined-email" label={t('labels.email')} variant="outlined" color={'text'} sx={{ width: '100%' }} value={user.email} size="small"/>
                            </Paper>
                            <Paper elevation={1}>
                                <TextField disabled id="outlined-role" label={t('labels.role')} variant="outlined" color={'text'} sx={{ width: '100%' }} value={user.is_admin ? t('labels.admin') : t('labels.user')} size="small"/>
                            </Paper>
                        </Stack>
                    </Box>
                </Stack>
                <Stack spacing={2} direction="column" alignItems='center' justifyContent='center'>
                <Box sx={{ p: 2, pl:0}}>
                    <div className="user-actions">
                        <div className="separator"></div>
                        <div className="info-row" onClick={() => onAccess(user)}
                            style={{
                                borderLeft: '1px solid rgba(255, 255, 255, 0.12)',
                                borderRight: '1px solid rgba(255, 255, 255, 0.12)',
                        }}>
                            <div className="title" style={{marginRight: 6}}>{t('actions.change_access')}</div>
                            <div className="pencil"></div>
                        </div>
                        <div className="info-row" onClick={() => setLogRedirect(true)}
                            style={{
                                borderLeft: '1px solid rgba(255, 255, 255, 0.12)',
                                borderRight: '1px solid rgba(255, 255, 255, 0.12)',
                        }}>
                            <div className="title" style={{marginRight: 6}}>{t('labels.unlocks')}</div>
                            <div className="arrow"></div>
                        </div>
                        { logRedirect ? <Redirect to={`/?user=${user.first_name+"%20"+user.last_name}`}/>: null}
                        {user.is_admin ? null :
                            <div className="info-row" onClick={() => onDelete(user)}
                                style={{
                                    borderLeft: '1px solid rgba(255, 255, 255, 0.12)',
                                    borderRight: '1px solid rgba(255, 255, 255, 0.12)',
                            }}>
                                <div className="title" style={{marginRight: 6}}>{t('actions.delete')}</div>
                                <div className="bin"></div>
                            </div>
                        }
                    </div>
                </Box>
                </Stack>
            </Stack>
        );
    }

    return (
        <div style={{height: '100%'}}>
            <div style={{padding: 24, alignItems: 'center', justifyContent: 'center', width: '100%', display: 'flex'}}>
                <Avatar sx={{
                    backgroundColor: '#0a0a0a',
                    color: '#fff',
                    fontSize: 34,
                    height: 150,
                    marginLeft: '6px',
                    width: 150,
                }} src={user.avatar}>
                     {userService.getInitials(user)}
                </Avatar>
            </div>
                <Divider />
                <Box sx={{ p: 2, }}>
                    <Stack spacing={2}>
                        <Paper elevation={1}>
                            <TextField disabled id="outlined-name" label={t('labels.name')} variant="outlined" color={'text'} sx={{ width: '100%' }} value={userService.getFullName(user)} size="small"/>
                        </Paper>
                        <Paper elevation={1}>
                            <TextField disabled id="outlined-email" label={t('labels.email')} variant="outlined" color={'text'} sx={{ width: '100%' }} value={user.email} size="small"/>
                        </Paper>
                        <Paper elevation={1}>
                            <TextField disabled id="outlined-role" label={t('labels.role')} variant="outlined" color={'text'} sx={{ width: '100%' }} value={user.is_admin ? t('labels.admin') : t('labels.user')} size="small"/>
                        </Paper>
                        <Paper elevation={1}>
                            <Autocomplete
                            id="combo-box-cards"
                            options={cardOptions}
                            getOptionLabel={(card) => card.serial}
                            renderInput={(params) => <TextField {...params} label="Card" variant="outlined" />}
                            color={'text'} sx={{ width: '100%', borderColor: 'text' }} disableClearable blurOnSelect
                            size={'small'} value={assignedCard} onChange={(e, value) => setAssignedCard(value)}
                            />
                    </Paper>
                    <LoadingButton disabled={!hasDiff} sx={{
                            "&:hover": { backgroundColor: hasDiff ? 'info.hover' : 'transparent', },
                            borderRadius: 15, backgroundColor: hasDiff ? 'info.main' : 'transparent',
                            borderColor: 'drawer.button', borderWidth: 1, borderStyle: 'solid',
                            color: 'text.main', p: 1
                        }} 
                        onClick={onCardAssigned} loading={loading}
                        >{t('labels.save')}
                        </LoadingButton>
                    </Stack>
                </Box>
            <div className="user-actions">
                <div className="separator"></div>
                <div className="info-row-static">
                    <div className="title">{t('labels.access')}</div>
                </div>
                <div className="info-row" onClick={() => onAccess(user)}>
                    <div className="title child">{t('labels.edit')}</div>
                    <div className="pencil"></div>
                </div>
                <a href={export_url} target="_blank" rel="noreferrer">
                    <div className="info-row indented">
                        <div className="title child">{t('labels.export')}</div>
                        <div className="download"></div>
                    </div>
                </a>
                <div className="info-row" onClick={() => setLogRedirect(true)}>
                    <div className="title">{t('labels.unlocks')}</div>
                    <div className="arrow"></div>
                </div>
                { logRedirect ? <Redirect to={`/?user=${user.first_name+"%20"+user.last_name}`}/>: null}
                {user.is_admin ? null :
                    <div className="info-row" onClick={() => onDelete(user)}>
                        <div className="title">{t('actions.delete')}</div>
                        <div className="bin"></div>
                    </div>
                }
                </div>
        </div>
    );
}

export default ListUserDetails;