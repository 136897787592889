import React from 'react';
import { ListItemText, Stack, TableCell, TableRow } from '@mui/material';
import { withStyles } from '@mui/styles';
import Skeleton from '@mui/material/Skeleton';

const StyledTableRow = withStyles({
    root: {
        backgroundColor: 'rgba(39, 40, 41, 0.9)',
        "&:hover": {
            backgroundColor: 'rgba(39, 40, 41, 0.7)',
        }
    }
})(TableRow);

const StyledTableCell = withStyles({
    root: {
        borderBottom: '1px solid #181818',
        fontSize: 13,
        padding: '6px'
    }
})(TableCell);

function ListEntryLogSkeleton({is_small_screen}) {
    return (
        <StyledTableRow>
            <StyledTableCell>
                <Skeleton variant="circular" width={20} height={20} animation="wave"/>
            </StyledTableCell>
            <StyledTableCell>
                <Skeleton variant="text" width={120} animation="wave"/>
            </StyledTableCell>
            <StyledTableCell>
                <Skeleton variant="text" width={120} animation="wave"/>
            </StyledTableCell>
            <StyledTableCell>
                <Stack direction='row' spacing={1} sx={{alignItems: 'center'}}>
                    <Skeleton variant="circular" width={34} height={34} animation="wave"/>
                    <ListItemText>
                        <Skeleton variant="text" width={360} animation="wave"/>
                    </ListItemText>
                </Stack>
            </StyledTableCell>
            <StyledTableCell>
                <Skeleton variant="text" width={120} animation="wave"/>
            </StyledTableCell>
            {is_small_screen ?
                null
                :
                <StyledTableCell>
                    <Skeleton variant="text" width={240} animation="wave"/>
                </StyledTableCell>
            }
        </StyledTableRow>
    );
}

export default ListEntryLogSkeleton;
