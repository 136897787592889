import React from 'react';
import { Avatar, ListItemText, Stack, TableCell, TableRow } from '@mui/material';
import { withStyles } from '@mui/styles';
import CreditCardIcon from '@mui/icons-material/CreditCard';
import PersonOutlineIcon from '@mui/icons-material/PersonOutline';


const StyledTableRow = withStyles({
    root: {
        backgroundColor: 'rgba(39, 40, 41, 0.9)',
        "&:hover": {
            backgroundColor: 'rgba(39, 40, 41, 0.7)',
        }
    }
})(TableRow);

const StyledTableCell = withStyles({
    root: {
        borderBottom: '1px solid #181818',
        fontSize: 13,
        padding: '6px'
    }
})(TableCell);


function ListEntryCard({ card, userService}) {
    let user = null;
    
    if (card.user) {
        user = userService.getUserById(card.user);
    }

    return(
        <StyledTableRow>
           <StyledTableCell>
                <Avatar sx={{
                    backgroundColor: '#181818',
                    color: '#fff',
                    fontSize: 13,
                    height: 34,
                    marginLeft: '6px',
                    width: 34,
                }}>
                    <CreditCardIcon style={{height: 20, width: 20}}/>
                </Avatar>
            </StyledTableCell>
            <StyledTableCell style={{ fontWeight: 'bold' }}>
                <ListItemText>
                    {card.serial}
                </ListItemText>
            </StyledTableCell>
            <StyledTableCell>
                {user ? (
                    <Stack direction="row" spacing={1} sx={{alignItems: 'center'}}>
                        <Avatar src={user ? user.avatar : null} sx={{
                            backgroundColor: '#181818',
                            color: '#fff',
                            fontSize: 13,
                            height: 34,
                            width: 34,
                        }}>
                            {user ? userService.getInitials(user) : <PersonOutlineIcon/>}
                        </Avatar>
                        <ListItemText>
                            {user ? userService.getFullName(user) : card.visitor}
                        </ListItemText>
                    </Stack>
                ) : (
                    <Stack direction="row" spacing={1} sx={{alignItems: 'center'}}>
                        <ListItemText>

                        </ListItemText>
                    </Stack>
                    )}
            </StyledTableCell>
        </StyledTableRow>
    )
}

export default ListEntryCard;
