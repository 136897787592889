import React, { useContext, useState } from 'react';
import './MemberManager.css';
import AccessHeader from '../access_matrix/access_header/AccessHeader';
import Avatar from '@mui/material/Avatar';
import { ServiceContext } from '../../context/ServiceContext';
import useWindowDimensions from '../../hooks/UseWindowDimensions';
import GroupSVG from '../../assets/svgs/groups.svg';

function MemberManager({initialGroup, users, onExit, refreshCallback}) {
    const { orgService, apiService, userService } = useContext(ServiceContext);
    const { height } = useWindowDimensions();
    
    const [group, setGroup] = useState(initialGroup);
    const [moving, setMoving] = useState(false);
    const [selectedOrgUsers, setSelectedOrgUsers] = useState([]);

    const org = orgService.getOrg(apiService.organization_id);

    const closeOnBackdrop = (e) => {
        /* Checks if a click event specifically targets the backdrop */
        if (e.target.className === "member-manager-wrapper") onExit();
    }

    const selectOrgUser = (user) => {
        const user_idx = selectedOrgUsers.findIndex((item) => item === user.id);
        /* If user is not selected yet add it to the list */
        if(selectedOrgUsers.findIndex((item) => item === user.id) === -1) {
            selectedOrgUsers.push(user.id);
            setSelectedOrgUsers([...selectedOrgUsers]);
        }else{
            /* Else remove it from the list */
            selectedOrgUsers.splice(user_idx, 1);
            setSelectedOrgUsers([...selectedOrgUsers]);
        }
    }
    
    const moveUsers = async () => {
        setMoving(true);
        const orgUsers = selectedOrgUsers;
        setSelectedOrgUsers(() => []);
        for (const user_id of orgUsers) {
            const response = await apiService.addUserToGroup(group.id, user_id);
            if(response.success){
                setGroup(response.data);
            }
        }
        await refreshCallback();
        setMoving(false);
    }

    const removeUser = async (user) => {
        const response = await apiService.remUserFromGroup(group.id, user.id);
        if(response.success){
            setGroup(response.data);
        }
        await refreshCallback();
    }

    const renderOrgUser = (user) => {

        const selected = (selectedOrgUsers.findIndex((item) => item === user.id) !== -1);

        return (
            <div className={selected ? "cell left-box active" : "cell left-box"} onClick={() => selectOrgUser(user)} key={"left_" + user.id}>
                <Avatar src={user.avatar} sx={{
                    backgroundColor: '#0a0a0a',
                    fontSize: 15}}>
                    {userService.getInitials(user)}
                </Avatar>
                <div className="group-name">{user.first_name + ' ' + user.last_name}</div>
            </div>
        );
    }

    const renderGroupUser = (user) => {

        const selected = (selectedOrgUsers.findIndex((item) => item === user.id) !== -1);

        return (
            <div className={selected ? "cell right-box active" : "cell right-box"} key={"right_" + user.id}>
                <Avatar src={user.avatar} sx={{
                    backgroundColor: '#0a0a0a',
                    fontSize: 15}}>
                    {userService.getInitials(user)}
                </Avatar>
                <div className="group-name">{user.first_name + ' ' + user.last_name}</div>
                <div className="actions">
                    <div className="remove">
                        <div className="img" onClick={() => removeUser(user)}/>
                    </div>
                </div>
            </div>
        );
    }

    const renderAllUsers = () => {

        var filteredUsers = [];
        var userList = [];

        if(users){
            // users = users.sort((a,b) => (a.first_name. > b.first_name) ? 1 : ((b.first_name > a.first_name) ? -1 : 0));
            filteredUsers = users.filter((user) => !group.members.includes(user.id));
            userList = filteredUsers.map((user) => renderOrgUser(user));
        }

        return (
            <div className="col">
                <div className="cell header left-box">
                    <Avatar src={org.logo} sx={{
                        backgroundColor: '#0a0a0a',
                        fontSize: 15}}>
                    </Avatar>
                    <div className="group-name">{"Alle gebruikers"}</div>
                </div>
                <div className="scroll-container" style={{height: height * 0.7}}>
                    {userList}
                </div>
            </div>
        );
    };

    const renderEmptyRow = (selectedOrgUsers) => {

        const amountOfUsers = selectedOrgUsers.length;

        return(
            <div className="col-center">
                <div className="wrapper">
                    <div className="actions">
                        {moving ? <div className="arrow moving"/> : <div className={amountOfUsers ? "arrow active" : "arrow"} onClick={amountOfUsers ? moveUsers : null}/>}
                        <div className="count unselectable">
                            {amountOfUsers > 1 ? `${amountOfUsers} gebruikers` : null }
                            {amountOfUsers === 1 ? `${amountOfUsers} gebruiker` : null }
                            &nbsp;
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    const renderGroupUsers = () => {

        var filteredUsers = [];
        var userList = [];

        if(users){
            // users = users.sort((a,b) => (a.first_name > b.first_name) ? 1 : ((b.first_name > a.first_name) ? -1 : 0));
            filteredUsers = users.filter((user) => group.members.includes(user.id));
            userList = filteredUsers.map((user) => renderGroupUser(user));
        }

        return(
            <div className="col">
                <div className="cell header right-box">
                    <Avatar src={GroupSVG} sx={{
                        backgroundColor: '#0a0a0a',
                        fontSize: 15}}>
                    </Avatar>
                    <div className="group-name">{group.name}</div>
                </div>
                <div className="scroll-container" style={{height: height * 0.7}}>
                    {userList}
                </div>
            </div>
        );
    };

    return (
        <div className="member-manager-wrapper" onClick={closeOnBackdrop}>
            <div className="container">
                <AccessHeader onExit={onExit} selectedGroup={group} pageNumber={0} setPageNumber={() => {}} listLength={0} memberManager/>
                <div className="user-grid">
                    {renderAllUsers()}
                    {renderEmptyRow(selectedOrgUsers)}
                    {renderGroupUsers()}
                </div>
            </div>
        </div>
    );
}

export default MemberManager;