import React, { useContext } from 'react';
import { ServiceContext } from '../../../context/ServiceContext';
import XSAvatar from '../../xs_mui/XSAvatar';

function AccessHeader({onExit, selectedUser, selectedGroup, pageNumber, setPageNumber, listLength, memberManager, accessControl}) {
    const services = useContext(ServiceContext);

    const renderHeader = () => {
        if(selectedGroup){
            var headerText = '';
            if(memberManager){
                headerText = `Leden "${selectedGroup.name}"`;
            }else if(accessControl){
                headerText = `Toegang "${selectedGroup.name}"`;
            }
            /* Render the group icon and name */
            return(
                <div className="header">
                <div className="group-header">
                    <div className="group-icon"></div> 
                    <div className="group-name">{headerText}</div>
                </div>
                <div className="close" onClick={onExit}></div>
            </div>
            );
        }
        return(
                <div className="header">
                <div className="group-header">
                    <XSAvatar src={selectedUser.avatar}>
                        {services.userService.getInitials(selectedUser)}
                    </XSAvatar>
                    <div className="group-name">{services.userService.getFullName(selectedUser)}</div>
                </div>
                <div className="close" onClick={onExit}></div>
            </div>
        );
    }

    const renderBackArrow = () => {
        if(listLength > 4){
            if(pageNumber > 1){
                return (
                    <div className="prev" onClick={() => setPageNumber(pageNumber - 1 )}>
                        <div className="arrow">&#10094;</div>
                    </div>
                );
            }
        }
    }

    const renderNextArrow = () => {
        if(listLength > 4 && pageNumber < (listLength / 4)){
            return(
                <div className="next" onClick={() => setPageNumber(pageNumber + 1 )}>
                    <div className="arrow">&#10095;</div>
                </div>
            );
        }
    }

    return (
        <div>
            {renderHeader()}
            {renderBackArrow()}
            {renderNextArrow()}
        </div>);
}

export default AccessHeader;