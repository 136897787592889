import React from 'react';
import './verify_user_delete.css';
import '../../index.css';

const VerifyUserDelete = ({onDelete, onCancel, user}) => {
    
    const onPopUpClicked = (e) => {
        /* Called when the user clicks the popup. Closes the popup when
           the user clicked on the outer part.
        */
        if(e.target.className  === "pop-up verify")
        {
            /* User clicked on the background */
            onCancel();
        }
    }

    return (
        <div className="pop-up verify" onClick={onPopUpClicked}>
            <div className="inner">
                <div className="top">
                    <div className="close" onClick={onCancel}>&nbsp;</div>
                </div>
                <div className="middle">
                <h2>{user.email} verwijderen</h2>
                    <p>Met deze actie wordt {user.email} uit de organisatie verwijdert en daarmee de toegang tot alle toegewezen sloten ontnomen.</p>
                </div>
                <div className="bottom">
                    <div className="buttons">
                        <button className="button cancel" onClick={onCancel}>Annuleren</button>
                        <button className="button delete" onClick={() => onDelete(user)}>Verwijderen</button>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default VerifyUserDelete;