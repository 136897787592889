import React, { useContext, useState } from 'react';
import { ServiceContext } from '../../../context/ServiceContext';
import './EditAccessRule.css';
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';
import moment from 'moment';

const calcApiTime = (localtime) => {
    return new moment(localtime, "HH:mm:ss").format("HH:mm:ss");
}

const calcDisplayFromTime = (all_day, from_time) => {
    if (all_day || !from_time || from_time === "00:00:00") return "00:00:00";
    return new moment(from_time, "HH:mm:ss").format("HH:mm:ss");
}

const calcDisplayToTime = (all_day, to_time) => {
    if (all_day || !to_time || to_time === "23:59:59") return "23:59:59";
    return new moment(to_time, "HH:mm:ss").format("HH:mm:ss");
}

const today = () => new moment().format("YYYY-MM-DD");

function EditAccessRule({ user, group, lock, accessList, selectedRule, onClose, onSave, onDelete, createSnackbar}) {
    if (!selectedRule){
        selectedRule = {};
    }
    const { t } = useTranslation();
    const { apiService, userService } = useContext(ServiceContext);
    const [initialRule] = useState(accessList);
    const [newRule, setNewRule] = useState(accessList);
    const [saving, setSaving] = useState(false);
    const [fromDate, setFromDate] = useState(selectedRule.from_date ? selectedRule.from_date : today());
    const [toDate, setToDate] = useState(selectedRule.to_date ? selectedRule.to_date : "");
    const [fromTime, setFromTime] = useState(calcDisplayFromTime(selectedRule.all_day, selectedRule.from_time));
    const [toTime, setToTime] = useState(calcDisplayToTime(selectedRule.all_day, selectedRule.to_time));
    const [unlimitedPeriod, setUnlimitedPeriod] = useState(selectedRule.to_date ? false : true);
    const [unlimitedTimeframe, setUnlimitedTimeframe] = useState(selectedRule.all_day === undefined ? true : selectedRule.all_day);
    const { enqueueSnackbar } = useSnackbar();

    const closeOnBackdrop = (e) => {
        /* Checks if a click event specifically targets the backdrop */
        if (e.target.className === "access-rule-wrapper") {
            onClose();
        }
    }

    const toggleDay = (idx) => {
        /* Toggles a single day from an access list */
        var ruleCopy = [...newRule];
        ruleCopy[idx] = !newRule[idx];
        setNewRule(ruleCopy);
    }

    const handleEditedGroupRule = async () => {
        /* Pushes changed rules to the API */
        const access_days = accessListToAccessRule(newRule);
        if (newRule.every(Boolean) && unlimitedPeriod && unlimitedTimeframe) {
            /* If user has been granted full access just toggle the full_access property */
            if (selectedRule.rule_id === -1) {
                /* No access rule yet, lets create one */
                const response = await apiService.createGroupAccessRule(
                    group.id, { ...access_days, full_access: true, lock: lock.id, all_day: unlimitedTimeframe},
                );
                return response.success;
            }
            /* User has a rule but full_access is not set, set full access. */
            const response = await apiService.setGroupAccessRuleFullAccess(
                group.id, selectedRule.rule_id, true, unlimitedTimeframe
            );
            return response.success;
        }
        if (selectedRule.rule_id === -1) {
            /* No rule yet, create rule without full access */
            const response = await apiService.createGroupAccessRule(
                group.id, 
                { ...access_days, full_access: false, lock: lock.id, from_date: fromDate, 
                    to_date: toDate, from_time: calcApiTime(fromTime), to_time: calcApiTime(toTime),
                    all_day: unlimitedTimeframe
                }
            );
            return response.success;
        }
        /* Update existing rule */
        const response = await apiService.patchGroupAccessRule(
            group.id, 
            { ...access_days, full_access: false, lock: lock.id, id: selectedRule.rule_id,
                from_date: fromDate, to_date: toDate, from_time: calcApiTime(fromTime), to_time: calcApiTime(toTime),
                all_day: unlimitedTimeframe
            });
        return response.success;

    }

    const handleEditedUserRule = async () => {
                /* Pushes changed rules to the API */
        const access_days = accessListToAccessRule(newRule);
        if (newRule.every(Boolean) && unlimitedPeriod && unlimitedTimeframe) {
            /* If user has been granted full access just toggle the full_access property */
            if (selectedRule.rule_id === -1) {
                /* No access rule yet, lets create one */
                const response = await apiService.createUserAccessRule(
                    user.id, { ...access_days, full_access: true, lock: lock.id, all_day: unlimitedTimeframe},
                );
                return response.success;
            }
            /* User has a rule but full_access is not set, set full access. */
            const response = await apiService.setUserAccessRuleFullAccess(
                user.id, selectedRule.rule_id, true, unlimitedTimeframe
            );
            return response.success;
        }
        if (selectedRule.rule_id === -1) {
            /* No rule yet, create rule without full access */
            const response = await apiService.createUserAccessRule(
                user.id, 
                { ...access_days, full_access: false, lock: lock.id, from_date: fromDate, 
                    to_date: toDate, from_time: calcApiTime(fromTime), to_time: calcApiTime(toTime),
                    all_day: unlimitedTimeframe
                }
            );
            return response.success;
        }
        /* Update existing rule */
        const response = await apiService.patchUserAccessRule(
            user.id, 
            { ...access_days, full_access: false, lock: lock.id, id: selectedRule.rule_id,
                from_date: fromDate, to_date: toDate, from_time: calcApiTime(fromTime), to_time: calcApiTime(toTime),
                all_day: unlimitedTimeframe
            });
        return response.success;
    }

    const accessListToAccessRule = (list) => {
        /* Converts an access list to an access rule for the API. */
        return ({
            monday: list[0],
            tuesday: list[1],
            wednesday: list[2],
            thursday: list[3],
            friday: list[4],
            saturday: list[5],
            sunday: list[6],
        });
    }

    const saveAccess = async () => {
        /* Called when the user clicks "save". Checks which
           access rules were edited and need to be changed
           in the API and handles the API calls.
        */
        setSaving(true);
        let success;
        if (group) {
            success = await handleEditedGroupRule();
        } else {
            success = await handleEditedUserRule();
        }
        if (success) {
            enqueueSnackbar(t('notifications.the_changes_has_been_saved_successfully'), {variant: 'success'});
        } else {
            enqueueSnackbar(t('notifications.could_not_save_changes'), {variant: 'error'});
        }
        setSaving(false);
        await onSave();
        await onClose();
    }

    const renderLockIcon = (hardware) => {
        if (hardware) {
            if (hardware.toLowerCase() === "edh") {
                return (<div className="icon EDH"></div>);
            }
            if (hardware.toLowerCase() === "edc") {
                return (<div className="icon EDC"></div>);
            }
            if (hardware.toLowerCase() === "wmr") {
                return (<div className="icon WMR"></div>);
            }
            if (hardware.toLowerCase() === "pcb") {
                return (<div className="icon PCB"></div>);
            }
        }
        return (<div className="icon"></div>);

    };

    const sameMembers = (array_1, array_2) => {
        if (array_1.length !== array_2.length) return false;
        for (var i = 0; i < array_1.length; i++) {
            if (array_1[i] !== array_2[i]) return false;
        }
        return true;
    }
    
    const handleSwipe = (event, index) => {
        if (event.buttons && (event.buttons === 1 || event.buttons === 3)) {
            toggleDay(index);
        }
    }

    const renderIcon = () => {
        if (group){
            return(
                <div className="col flex">
                    <div className="icon"></div>
                    <div className="name">{name}</div>
                </div>
            ); 
        }
        const user_has_avatar = (user && user.avatar ? true : false);
        return(
            <div className="col flex">
                {user_has_avatar ?
                    <div className="icon" style={{ backgroundImage: "url('" + user.avatar + "')", }} />
                    :
                    <div className="initials">{userService.getInitials(user)}</div>
                }
                <div className="name">{user.first_name + ' ' + user.last_name}</div>
            </div>
        );
    }

    const renderDeleteButton = () => {
        if (selectedRule.rule_id === -1) return null;
        return(
            <div className={saving ? "button button-shimmer": "button delete-alt"} onClick={
                () => {
                    setSaving(true); 
                    onDelete(selectedRule).then(
                        () => {
                            setSaving(false);
                            onClose();
                        }
                    ).catch(
                        setSaving(false)
                    );
                }
            }>Regel verwijderen</div>);
    }
    
    if (!user && !group) return null;
    const name = group ? group.name : user.full_name;
    const hasDiff = (selectedRule.rule_id && selectedRule.rule_id === -1) || !sameMembers(newRule, initialRule) || fromDate !== selectedRule.from_date || toDate !== selectedRule.to_date || fromTime !== selectedRule.from_time || toTime !== selectedRule.to_time;
    return (
        <div className="access-rule-wrapper" onClick={closeOnBackdrop}>
            <div className="inner">
                <div className="header">
                    <div className="group-header">
                        {selectedRule.rule_id === -1 ?
                            <div className="group-name unselectable">Toegangsregel aanmaken</div>
                        :
                            <div className="group-name unselectable">Toegangsregel wijzigen</div>
                        }
                    </div>
                <div className="close" onClick={onClose}></div>
            </div>
            <div className="row special">
                {renderIcon()}
                <div className="arrow"></div>
                <div className="col flex">
                    {renderLockIcon(lock.hardware)}
                    <div className="name">{lock.name}</div>
                </div>
            </div>
            <div className="row">
                <div className="col">
                    <div className="text unselectable">Weekdagen</div>
                    <div className="small-text unselectable">Groen is toegang</div>
                </div>
                <div className="arrow" style={{ opacity: 0 }}></div>
                <div className="col flex">
                    <div className={newRule[0] ? "day active unselectable" : "day unselectable"} onMouseDown={() => toggleDay(0)} onMouseOver={(e) => handleSwipe(e, 0)}>Ma</div>
                    <div className={newRule[1] ? "day active unselectable" : "day unselectable"} onMouseDown={() => toggleDay(1)} onMouseOver={(e) => handleSwipe(e, 1)}>Di</div>
                    <div className={newRule[2] ? "day active unselectable" : "day unselectable"} onMouseDown={() => toggleDay(2)} onMouseOver={(e) => handleSwipe(e, 2)}>Wo</div>
                    <div className={newRule[3] ? "day active unselectable" : "day unselectable"} onMouseDown={() => toggleDay(3)} onMouseOver={(e) => handleSwipe(e, 3)}>Do</div>
                    <div className={newRule[4] ? "day active unselectable" : "day unselectable"} onMouseDown={() => toggleDay(4)} onMouseOver={(e) => handleSwipe(e, 4)}>Vr</div>
                    <div className={newRule[5] ? "day active unselectable" : "day unselectable"} onMouseDown={() => toggleDay(5)} onMouseOver={(e) => handleSwipe(e, 5)}>Za</div>
                    <div className={newRule[6] ? "day active unselectable" : "day unselectable"} onMouseDown={() => toggleDay(6)} onMouseOver={(e) => handleSwipe(e, 6)}>Zo</div>
                </div>
            </div>
            <div className="form">
                <div className="row" style={{ display: 'block' }}>
                    <div style={{ display: 'flex' }}>
                        <div className="col">
                            <div className="text unselectable">Onbepaalde periode</div>
                        </div>
                        <label className="checkbox-container">
                            <input type="checkbox" checked={unlimitedPeriod} onChange={() => {
                                if (!unlimitedPeriod) {
                                    setFromDate(today());
                                    setToDate("");
                                }
                                setUnlimitedPeriod(!unlimitedPeriod);
                            }} />
                            <span className="checkmark"></span>
                        </label>
                    </div>
                    <div className="open-data">
                        <div className="col flex">
                            <input type="date" disabled={unlimitedPeriod} onChange={(e) => setFromDate(e.target.value)} value={fromDate}/>
                        </div>
                        <div className="arrow"></div>
                        <div className="col">
                            <input type="date" disabled={unlimitedPeriod} onChange={(e) => setToDate(e.target.value)} value={toDate}/>
                        </div>
                    </div>
                </div>
                <div className="row" style={{ display: 'block' }}>
                    <div style={{ display: 'flex' }}>
                        <div className="col">
                            <div className="text unselectable">24 uur toegang</div>
                        </div>
                        <label className="checkbox-container">
                            <input type="checkbox" checked={unlimitedTimeframe} onChange={() => setUnlimitedTimeframe(!unlimitedTimeframe)} />
                            <span className="checkmark"></span>
                        </label>
                    </div>
                    <div className="open-data">
                        <div className="col flex">
                            <input type="time" disabled={unlimitedTimeframe} onChange={(e) => setFromTime(e.target.value)} value={fromTime}/>
                        </div>
                        <div className="arrow"></div>
                        <div className="col">
                            <input type="time" disabled={unlimitedTimeframe} onChange={(e) => setToTime(e.target.value)} value={toTime}/>
                        </div>
                    </div>
                </div>
                <div className="buttons">
                    {renderDeleteButton()}
                    <div className="button cancel" onClick={saving ? () => {} : onClose}>Annuleren</div>
                    <div className={saving ? "button button-shimmer" : "button"}
                        style={{
                            backgroundColor: hasDiff ? "#0ade0a" : "transparent",
                            borderColor: hasDiff ? "#0ade0a" : "rgba(255, 255, 255, 0.1)",
                        }}
                        onClick={hasDiff ? saveAccess : onClose}
                    >Regel opslaan</div>
                </div>
            </div>
        </div>
    </div>);

}

export default EditAccessRule;
