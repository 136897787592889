import React, { useContext, useState } from 'react';
import './BridgeManager.css';
import AccessHeader from '../access_matrix/access_header/AccessHeader';
import { ServiceContext } from '../../context/ServiceContext';
import useWindowDimensions from '../../hooks/UseWindowDimensions';
import { useTranslation } from 'react-i18next';

function BridgeManager({ initialBridge, locks, onExit, updateCallback }) {
    const { t } = useTranslation();
    const { orgService, apiService } = useContext(ServiceContext);
    const { height } = useWindowDimensions();

    const [bridge, setBridge] = useState(initialBridge);
    const [moving, setMoving] = useState(false);
    const [selectedOrgLocks, setSelectedOrgLocks] = useState([]);

    const org = orgService.getOrg(apiService.organization_id);

    const closeOnBackdrop = (e) => {
        /* Checks if a click event specifically targets the backdrop */
        if (e.target.className === "bridge-manager-wrapper") onExit();
    }

    const selectOrgLock = (lock) => {
        const user_idx = selectedOrgLocks.findIndex((item) => item === lock.id);
        /* If lock is not selected yet add it to the list */
        if (selectedOrgLocks.findIndex((item) => item === lock.id) === -1) {
            selectedOrgLocks.push(lock.id);
            setSelectedOrgLocks([...selectedOrgLocks]);
        } else {
            /* Else remove it from the list */
            selectedOrgLocks.splice(user_idx, 1);
            setSelectedOrgLocks([...selectedOrgLocks]);
        }
    }

    const moveLocks = async () => {
        setMoving(true);
        setSelectedOrgLocks(() => []);
        const response = await apiService.assingLocksToBridge(bridge.pk, selectedOrgLocks);
        if (response.success) {

            setBridge(response.data.filter(new_bridge => new_bridge.pk === bridge.pk)[0]);
            await updateCallback(response.data);
        }
        setMoving(false);
    }

    const removeLock = async (lock) => {
        const response = await apiService.removeLocksFromBridge(bridge.pk, [lock.id]);
        if (response.success) {
            setBridge(response.data.filter(new_bridge => new_bridge.pk === bridge.pk)[0]);
        }
        await updateCallback(response.data);
    }

    const renderOrgLock = (lock) => {

        const selected = (selectedOrgLocks.findIndex((item) => item === lock.id) !== -1);
        let icon_css = "lock-icon"
        if (lock.hardware) icon_css = `lock-icon ${lock.hardware}`;
        if (lock.assignable){
            return (
                <div className={selected ? "cell left-box active" : "cell left-box"} onClick={() => selectOrgLock(lock)}>
                    <div className={icon_css} />
                    <div className="group-name">{lock.name}</div>
                </div>
            );
        }
        return (
            <div className={"cell left-box unassignable"}>
                <div className={icon_css} />
                <div className="group-name">{lock.name}</div>
            </div>
        );
    }

    const renderBridgeLock = (lock) => {

        const selected = (selectedOrgLocks.findIndex((item) => item === lock.id) !== -1);
        let icon_css = "lock-icon"
        if (lock.hardware) icon_css = `lock-icon ${lock.hardware}`;

        return (
            <div className={selected ? "cell right-box active" : "cell right-box"} >
                <div className={icon_css} />
                <div className="group-name">{lock.name}</div>
                <div className="actions">
                    <div className={lock.connected ? "status connected" : "status disconnected"}/>
                    <div className="remove">
                        <div className="img" onClick={() => removeLock(lock)} />
                    </div>
                </div>
            </div>
        );
    }

    const renderAllLocks = () => {

        var filteredLocks = [];
        var lockList = [];

        if (locks) {
            locks = locks.sort((a, b) => (a.id > b.id) ? 1 : ((b.id > a.id) ? -1 : 0));
            filteredLocks = locks.filter((lock) => !bridge.locks.includes(lock.id));
            lockList = filteredLocks.map((lock) => renderOrgLock(lock));
        }

        return (
            <div className="col">
                <div className="cell header left-box">
                    <div className="group-icon" style={{
                        backgroundImage: "url('" + org.logo + "')",
                        backgroundSize: 'cover'
                    }} />
                    <div className="group-name">{t("labels.all-locks")}</div>
                </div>
                <div className="scroll-container" style={{ height: height * 0.7 }}>
                    {lockList}
                </div>
            </div>
        );
    };

    const renderEmptyRow = (selectedOrgLocks) => {

        const amountOfLocks = selectedOrgLocks.length;

        return (
            <div className="col-center">
                <div className="wrapper">
                    <div className="actions">
                        {moving ? <div className="arrow moving" /> : <div className={amountOfLocks ? "arrow active" : "arrow"} onClick={amountOfLocks ? moveLocks : null} />}
                        <div className="count unselectable">
                            {amountOfLocks > 1 ? `${amountOfLocks} ${t('labels.locks')}` : null}
                            {amountOfLocks === 1 ? `${amountOfLocks} ${t('labels.lock')}` : null}
                            &nbsp;
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    const renderBridgeLocks = () => {

        var filteredLocks = [];
        var lockList = [];

        if (locks) {
            locks = locks.sort((a, b) => (a.id > b.id) ? 1 : ((b.id > a.id) ? -1 : 0));
            filteredLocks = locks.filter((lock) => bridge.locks.includes(lock.id));
            lockList = filteredLocks.map((lock) => renderBridgeLock(lock));
        }

        return (
            <div className="col">
                <div className="cell header right-box">
                    <div className="bridge-icon"></div>
                    <div className="group-name">{bridge.name}</div>
                </div>
                <div className="scroll-container" style={{ height: height * 0.7 }}>
                    {lockList}
                </div>
            </div>
        );
    };

    return (
        <div className="bridge-manager-wrapper" onClick={closeOnBackdrop}>
            <div className="container">
                <AccessHeader onExit={onExit} selectedGroup={bridge} pageNumber={0} setPageNumber={() => { }} listLength={0} BridgeManager />
                <div className="user-grid">
                    {renderAllLocks()}
                    {renderEmptyRow(selectedOrgLocks)}
                    {renderBridgeLocks()}
                </div>
            </div>
        </div>
    );
}

export default BridgeManager;