import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import CssBaseline from '@mui/material/CssBaseline';
import { NextXSTheme } from './assets/themes/NextXSTheme';
import { ThemeProvider } from '@mui/material/styles';
import DateAdapter from '@mui/lab/AdapterMoment';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import { StyledEngineProvider } from '@mui/material/styles';
import 'whatwg-fetch'; 
import './i18n';

ReactDOM.render(
  <React.StrictMode>
    <ThemeProvider theme={NextXSTheme}>
      <StyledEngineProvider>
        <LocalizationProvider dateAdapter={DateAdapter}>
            <CssBaseline />
            <App />
        </LocalizationProvider>
      </StyledEngineProvider>
    </ThemeProvider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
