import React, { Component } from 'react';
import { pixelsToMinutes, pixelsToTime } from '../../util/util';
import { LogRangeSelector } from './LogRangeSelector';

const CursorOffset = 10;
const MinCursorDistance = 60;

export default class LogRangeBar extends Component{
    constructor(props){
        super(props);
        this.state = {
            barWidth: document.body.clientWidth - 20,
            leftX: 0,
            rightX: document.body.clientWidth - 20,
            isDragging: false,
            isDraggingRightCursor: false,
            isDraggingLeftCursor: false,
        }

        this.barRef = React.createRef();

        this.startTrackingMouse = this.startTrackingMouse.bind(this);
        this.stopTrackingMouse = this.stopTrackingMouse.bind(this);
        this.onUpdateLocation = this.onUpdateLocation.bind(this);
        this.onStartDraggingCursor = this.onStartDraggingCursor.bind(this);
        this.onStopDraggingCursor = this.onStopDraggingCursor.bind(this);
        this.onUpdateLeftCursorLocation = this.onUpdateLeftCursorLocation.bind(this);
        this.onUpdateRightCursorLocation = this.onUpdateRightCursorLocation.bind(this);
        this.updateDimensions = this.updateDimensions.bind(this);
        this.updateBarWidth = this.updateBarWidth.bind(this);
        this.getStartTime = this.getStartTime.bind(this);
        this.getCursorDrawX = this.getCursorDrawX.bind(this);
        this.isFullSize = this.isFullSize.bind(this);
    }

    componentDidMount() {
        this.updateDimensions();
        window.addEventListener('resize', this.updateDimensions);
    }

    componentDidUpdate() {
        this.updateDimensions();
        /* Reset cursors when view new logs are loaded */
        if (this.props.loading && (this.state.leftX !== 0 || this.state.rightX !== this.state.barWidth)) {
            this.setState({leftX: 0, rightX: this.state.barWidth});
        }
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.updateDimensions);
        document.removeEventListener("mousemove", this.onUpdateLocation);
        document.removeEventListener("mouseup", this.stopTrackingMouse);
        document.removeEventListener('mousemove', this.onUpdateLeftCursorLocation);
        document.removeEventListener('mousemove', this.onUpdateRightCursorLocation);
        document.removeEventListener('mouseup', this.onStopDraggingCursor);
    }

    onUpdateLocation(e) {
        const bar_width = this.state.rightX - this.state.leftX;
        /* Calculate new X position of start cursor */
        var new_start_x = (e.clientX - 25) - (0.5 * bar_width);
        if(new_start_x < 0){
            /* Don't move past left border */
            this.setState({leftX: 0});
            return;
        };
        /* Calculate new X position of end cursor */
        var new_end_x = (e.clientX - 25) + (0.5 * bar_width); 
        if(new_end_x > this.state.barWidth){
            /* Don't move past right border */
            this.setState({rightX: this.state.barWidth});
            return;
        }
        /* Regular move */
        this.setState({ leftX: new_start_x, rightX: new_end_x });
    }

    onStartDraggingCursor(e, left) {
        document.addEventListener('mousemove', left ? this.onUpdateLeftCursorLocation : this.onUpdateRightCursorLocation);
        document.addEventListener('mouseup', this.onStopDraggingCursor);
        if (left) this.setState({ isDraggingLeftCursor: true});
        else this.setState({ isDraggingRightCursor: true});
    }

    onUpdateLeftCursorLocation(e) {
        var new_x = e.clientX - 25;
        if(new_x < 0) new_x = 0;
        else if(new_x > this.state.rightX - MinCursorDistance) new_x = this.state.rightX - MinCursorDistance;
        this.setState({leftX: new_x});
    }

    onUpdateRightCursorLocation(e) {
        var new_x = e.clientX - 25;
        if(new_x < this.state.leftX + MinCursorDistance) new_x = this.state.leftX + MinCursorDistance;
        if(new_x > this.state.barWidth) new_x = this.state.barWidth;
        this.setState({rightX: new_x});
    }

    onStopDraggingCursor() {
        document.removeEventListener('mousemove', this.onUpdateLeftCursorLocation);
        document.removeEventListener('mousemove', this.onUpdateRightCursorLocation);
        document.removeEventListener('mouseup', this.onStopDraggingCursor);
        this.setState({ isDraggingRightCursor: false, isDraggingLeftCursor: false });
        this.props.setTimeFilter(
            {
                from: pixelsToMinutes(this.state.leftX, this.state.barWidth, this.props.minutesDisplayed),
                till: pixelsToMinutes(this.state.rightX, this.state.barWidth, this.props.minutesDisplayed)
            }
        );
    }

    startTrackingMouse(e) {
        e.preventDefault();
        if (!this.isFullSize()) {
            document.addEventListener("mousemove", this.onUpdateLocation);
            document.addEventListener("mouseup", this.stopTrackingMouse);
            document.getElementById("grab-bar").style.cursor = "grabbing";
            this.setState({ isDragging: true });
        };
    }

    stopTrackingMouse(e) {
        e.preventDefault();
        document.removeEventListener("mousemove", this.onUpdateLocation);
        document.removeEventListener("mouseup", this.stopTrackingMouse);
        document.getElementById("grab-bar").style.cursor = "grab"; 
        this.setState({isDragging: false});
        this.props.setTimeFilter(
            {
                from: pixelsToMinutes(this.state.leftX, this.state.barWidth, this.props.minutesDisplayed),
                till: pixelsToMinutes(this.state.rightX, this.state.barWidth, this.props.minutesDisplayed)
            }
        );
    }

    updateDimensions() {
        const new_width = this.barRef.current.getBoundingClientRect().width - 20;
        if (new_width !== this.state.barWidth) {
            if (parseInt(this.state.rightX) === parseInt(this.state.barWidth)) {
                this.setState({ barWidth: new_width, rightX: new_width });
            } else {
                this.setState({ barWidth: new_width });
            }
            !this.props.loading && this.props.setTimeFilter(
                {
                    from: pixelsToMinutes(this.state.leftX, new_width, this.props.minutesDisplayed),
                    till: pixelsToMinutes(this.state.rightX, new_width, this.props.minutesDisplayed)
                }
            );
        }
    };

    updateBarWidth() {
        if(this.state.barWidth <= this.state.rightX - this.state.leftX){
            const hourWidth = (this.props.minutesDisplayed / this.state.barWidth) * 60;
            let newWidth = hourWidth;
            while(newWidth < MinCursorDistance) newWidth = newWidth + hourWidth;
            this.setState({ rightX: this.state.leftX + newWidth });
            this.props.setTimeFilter(
                {
                    from: pixelsToMinutes(this.state.leftX, this.state.barWidth, this.props.minutesDisplayed),
                    till: pixelsToMinutes(this.state.leftX + newWidth, this.state.barWidth, this.props.minutesDisplayed)
                }
            );
        }else{
            this.setState({ leftX: 0, rightX: this.state.barWidth });
            this.props.setTimeFilter(
                {
                    from: pixelsToMinutes(0, this.state.barWidth, this.props.minutesDisplayed),
                    till: pixelsToMinutes(this.state.barWidth, this.state.barWidth, this.props.minutesDisplayed)
                }
            );
        }
    }

    getStartTime() {
        if (this.state.leftX === 0) return 0;
        return pixelsToTime(this.state.leftX, this.state.barWidth, this.props.minutesDisplayed);
    }

    getCursorDrawX(cursor_pos) {
        if (cursor_pos === "left") return this.state.leftX + CursorOffset - 4;
        return this.state.rightX + CursorOffset - 4;
    }

    isFullSize() {
        return this.state.barWidth <= this.state.rightX - this.state.leftX;
    }

    render() {
        return (
            <div ref={this.barRef}>
                <div className="lograngeselector-left">
                    <LogRangeSelector x={this.getCursorDrawX("left")} onDrag={this.onStartDraggingCursor} isDragging={this.state.isDragging || this.state.isDraggingLeftCursor} left={true} time={pixelsToTime(this.state.leftX, this.state.barWidth, this.props.minutesDisplayed)} />
                </div>
                <div className="lograngeselector-right">
                    <LogRangeSelector x={this.getCursorDrawX("right")} onDrag={this.onStartDraggingCursor} isDragging={this.state.isDragging || this.state.isDraggingRightCursor} left={false} time={pixelsToTime(this.state.rightX, this.state.barWidth, this.props.minutesDisplayed)} />
                </div>
                <div className="bar-overlay" style={{ left: this.state.leftX + 10 , width: this.state.rightX + 1 - this.state.leftX, opacity: this.state.isDragging ? 0.1 : 0.05, cursor: this.isFullSize() ? 'pointer' : 'grab', zIndex: 5}} id="grab-bar"
                    onMouseDown={this.startTrackingMouse} onDoubleClick={this.updateBarWidth}
                />
            </div>
        );
    }
}
