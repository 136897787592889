import React, { useContext } from 'react';
import { removeHypen } from '../../util/util.js';
import { Button, ListItemText, Stack, TableCell, TableRow, Tooltip } from '@mui/material';
import { withStyles } from '@mui/styles';
import HomeWorkIcon from '@mui/icons-material/HomeWork';
import { ServiceContext } from '../../context/ServiceContext.js';
import { useTranslation } from 'react-i18next';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import Fade from '@mui/material/Fade';


const StyledTableRow = withStyles({
    root: {
        backgroundColor: 'rgba(39, 40, 41, 0.9)',
        "&:hover": {
            backgroundColor: 'rgba(39, 40, 41, 0.7)',
        }
    }
})(TableRow);

const StyledTableCell = withStyles({
    root: {
        borderBottom: '1px solid #181818',
        fontSize: 13,
        padding: '6px'
    }
})(TableCell);

function ListEntryBuilding({building}) {
    const { t } = useTranslation();
    const context = useContext(ServiceContext);
    const orgId = context.selectedOrg && context.selectedOrg.id | 0;

    if(!building) return null
    const export_url = `/api/organizations/${orgId}/buildings/${building.id}/export/`;
    const item_key = removeHypen(`build_${building.id}_${building.name}`);

    return (
        <StyledTableRow key={item_key}>
            <StyledTableCell>
                <Stack direction="row" spacing={1} alignItems="center">
                    <div style={{
                        backgroundColor: '#0f0f0f', display: "flex", height: 34, width: 34, 
                        alignItems: "center", justifyContent: "center", borderRadius: 20
                    }}>
                        <HomeWorkIcon color="text" style={{height: 18, width: 18}}/>
                    </div>
                    <ListItemText>
                        {building.name}
                    </ListItemText>
                </Stack>

            </StyledTableCell>
            <StyledTableCell>
                <a href={export_url} target="_blank" rel="noreferrer">
                    <Tooltip title={t('actions.export_csv')} TransitionComponent={Fade} TransitionProps={{ timeout: 300 }}>
                        <Button color={'info'} size="small" variant="contained" sx={{height: 34}} style={{
                           maxWidth: '42px', maxHeight: '34px', minWidth: '42px', minHeight: '34px'
                        }}>
                            <FileDownloadIcon color={'listentry'} />
                        </Button>
                    </Tooltip>
                </a>
            </StyledTableCell>
        </StyledTableRow>
    );
}

export default ListEntryBuilding;
