import { Divider, Paper, Stack, TextField } from '@mui/material';
import React from 'react';
import BridgeIcon from '../../assets/svgs/wireless_white.svg';
import { Box } from '@mui/system';
import { useTranslation } from 'react-i18next';


function ListBridgeDetails({ onEdit, bridge, smallScreen }) {

    const { t } = useTranslation();
    if (!bridge) return null;
    if (smallScreen) {
        return (
            <Stack direction="row" spacing={1} justifyContent='center' alignItems='center' sx={{width: '100%', height: 200}}>
                <Stack spacing={2}direction="column" alignItems='center' justifyContent='center'>
                    <Paper elevation={1} >
                        <TextField disabled id="outlined-name" label={t('labels.name')} variant="outlined" color={'text'} sx={{ width: '100%' }} value={bridge.name} size="small"/>
                    </Paper>
                    <Paper elevation={1}>
                        <TextField disabled id="outlined-locks" label={t('labels.locks')} variant="outlined" color={'text'} sx={{ width: '100%' }}
                            inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }} value={bridge.locks.length} size="small"
                        />
                    </Paper>
                    <Paper elevation={1}>
                        <TextField disabled id="outlined-status" label={t('labels.status')} variant="outlined" color={'text'} sx={{ width: '100%' }}
                            inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }} value={bridge.is_online ? t('labels.online') : t('labels.offline')}
                            size="small"
                        />
                    </Paper>
                </Stack>  
                <Box sx={{p: 2, minWidth: 175, maxWidth: 400,}}>
                    <Stack spacing={2} justifyContent='center' sx={{height: 123}}>
                        <div className="user-actions" style={{
                            borderLeft: '1px solid rgba(255, 255, 255, 0.12)',
                            borderRight: '1px solid rgba(255, 255, 255, 0.12)',
                        }}>
                            <div className="separator"></div>
                            <div className="info-row" onClick={() =>onEdit(bridge)}>
                                <div className="title" style={{marginRight: 6}}>{t('actions.change_locks')}</div>
                                <div className="pencil"></div>
                            </div>
                        </div>
                    </Stack>
                </Box>
            </Stack>);
    }

    return (
        <div style={{height: '100%'}}>
            <div style={{padding: 24, alignItems: 'center', justifyContent: 'center', width: '100%', display: 'flex'}}>
                <div style={{
                    alignItems: 'center',
                    backgroundColor: '#0a0a0a',
                    borderRadius: 150,
                    color: '#fff',
                    display: "flex",
                    fontSize: 13,
                    height: 150,
                    justifyContent: 'center',
                    width: 150,
                }}>
                    <img src={BridgeIcon} alt="logo" height={75} />
                </div>
            </div>
            <Divider />
            <Box sx={{p: 2,}}>
                <Stack spacing={2}>
                    <Paper elevation={1}>
                        <TextField disabled id="outlined-name" label={t('labels.name')} variant="outlined" color={'text'} sx={{ width: '100%' }} value={bridge.name} size="small"/>
                    </Paper>
                    <Paper elevation={1}>
                        <TextField disabled id="outlined-locks" label={t('labels.locks')} variant="outlined" color={'text'} sx={{ width: '100%' }} inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }} value={bridge.locks.length} size="small"/>
                    </Paper>
                    <Paper elevation={1}>
                        <TextField disabled id="outlined-status" label={t('labels.status')} variant="outlined" color={'text'} sx={{ width: '100%' }} value={bridge.is_online ? t('labels.online') : t('labels.offline')} size="small"/>
                    </Paper>
                    {/* <Divider />
                    <Button sx={{
                            "&:hover": { backgroundColor:'info.hover' },
                            borderRadius: 15, backgroundColor: 'info.main',
                            borderColor: 'drawer.button', borderWidth: 1, borderStyle: 'solid',
                            color: 'text.main', width: '100%'
                        }} onClick={() => onEdit(bridge)} endIcon={<EditIcon />}
                    >{t('actions.change_locks')}</Button> */}
                    </Stack>
                    
            </Box>
            <div className="user-actions">
                <div className="separator"></div>
                <div className="info-row" onClick={() =>onEdit(bridge)}>
                    <div className="title">{t('actions.change_locks')}</div>
                    <div className="pencil"></div>
                </div>
            </div>
        </div>
    );
}

export default ListBridgeDetails;