import React, { Component } from 'react';
import '../../index.css';
import './users.css';
import CreateUser from '../../components/create_user/CreateUser';
import VerifyUserDelete from '../../components/verify_user_delete/VerifyUserDelete';
import { ServiceContext } from '../../context/ServiceContext';
import ListEntryUser from '../../components/list_entry_user/ListEntryUser';
import ListUserDetails from '../../components/list_user_details/ListUserDetails';
import AccessMatrix from '../../components/access_matrix/AccessMatrix';
import EditAccessRule from '../../components/access_matrix/edit_access_rule/EditAccessRule';
import { withTranslation } from 'react-i18next';
import { withSnackbar } from 'notistack';
import { Box, Drawer, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TableSortLabel } from '@mui/material';
import { withStyles } from '@mui/styles';
import { visuallyHidden } from '@mui/utils';
import { stableSort, getComparator } from '../../util/util';
    
const StyledTableCell = withStyles({
    root: {
        fontSize: 10,
        textTransform: 'uppercase',
        padding: '6px',
        paddingTop: 0,
    }
})(TableCell);

class Users extends Component {
    

    static contextType = ServiceContext;

    constructor(props) {
        super(props)
        /* Initialize state */
        this.state = {
            cards: [],
            filter: '',
            locks: null,
            order: 'asc',
            orderBy: 'first_name',
            refresh: false,
            refreshMatrix: false,
            selectedUser: null,
            selectedLock: null,
            selectedRule: null,
            showCreateUser: false,
            showVerify: false,
            showMatrix: false,
            users: [],
            usergroups: null,
        }

        /* Handles for services */
        this.userService = null;
        this.lockService = null;
        this.apiService = null;
        this.orgService = null;
        
        /* Set up function binds */
        this.filterUser = this.filterUser.bind(this);
        this.addUserClicked = this.addUserClicked.bind(this);
        this.delUserClicked = this.delUserClicked.bind(this);
        this.delUserTapped  = this.delUserTapped.bind(this);
        this.onAccessClicked = this.onAccessClicked.bind(this);
        this.inviteUserClicked = this.inviteUserClicked.bind(this);
        this.onExitMatrix    = this.onExitMatrix.bind(this);
        this.changeUserSelection = this.changeUserSelection.bind(this);
        this.editRule        = this.editRule.bind(this);
        this.deleteRule      = this.deleteRule.bind(this);
        this.refreshUsers    = this.refreshUsers.bind(this);
        this.addUsers        = this.addUsers.bind(this);
        this.updateUser      = this.updateUser.bind(this);
        this.updateCardStatus = this.updateCardStatus.bind(this);
    }

    componentDidMount() {
        /* Retrieve reference to lockService */
        if(!this.lockService){
            this.lockService = this.context.lockService;
        }

        /* Retrieve reference to userService */
        if(!this.userService){
        this.userService = this.context.userService;
        }

        /* Retrieve reference to apiService */
        if(!this.apiService){
            this.apiService = this.context.apiService;
        }

        /* Retrieve reference to organizationService */
        if(!this.orgService){
            this.orgService = this.context.orgService;
        }

        /* Retrieve cards when the component has been mounted. */
        this.orgService.getCards().then( cards => {
            this.setState({ cards: cards });
        });

        /* Retrieve usergroups when the component has been mounted. */
        this.orgService.retrieveUserGroups().then( usergroups => {
            this.setState({usergroups: usergroups})
        });

        /* Retrieve users when the component has been mounted. */
        this.userService.retrieveUsersLazy(
            (users) => this.setState({users: [...this.state.users, ...users.data]})
        ).then();
        /* Retrieve locks when the component has been mounted */
        this.lockService.retrieveLocks(true).then( locks => {
          this.setState({locks: locks})
        })

        /* Get filter from URL parameter */
        const params = new URLSearchParams(this.props.location.search);
        const name = params.get('name');
        if(name) this.setState({filter: name})

        /* Get global selected user and apply if not null */
        if(this.context.selectedUser) this.setState({selectedUser: JSON.parse(this.context.selectedUser), showDetails: true});
    }

    addUsers(new_users) {
        this.setState(cur_state => ({ ...cur_state, users: [...cur_state.users, ...new_users.data] }));
    }

    async addUserClicked(email) {
        /* Called when a user is being added to the organization. */
        const exists = await this.userService.userExists(email);
        /* Decide what to do based on if the user exists (within organization) */
        if(!exists) {
            const response = await this.userService.addUser(email);
            if(response.success){
                this.setState({ showCreateUser: false, refresh: true });
                this.props.enqueueSnackbar(
                    this.props.t("notifications.x_was_added_to_this_organization", { email: email }),
                    { variant: 'success' }
                );
                await this.refreshUsers();
                return {success: true};
            }else{
                /* User is not a NextXS member, ask to send an invite */
                if(response.reason === "[\"User does not exist\"]"){
                    return {success: false, reason: "not-registered"}
                }
            }
        }else {
            /* User is already part of the organization */
            this.setState({ showCreateUser: false });
            this.props.enqueueSnackbar(
                this.props.t("notifications.this_user_is_already_a_member_of_this_organization"), { variant: 'info' }
            );
            return {success: false, reason: "in-org"};
        }
    }

    async inviteUserClicked(email) {
        /* Sends an actual invite to the user */
        const response = await this.userService.inviteUser(email);
        if(response){
            this.setState({showCreateUser: false });
            this.props.enqueueSnackbar(
                this.props.t("notifications.invitation_sent_to_x", {user_email: email}), { variant: 'info' }
            );
            return {success: true};
        }
        this.setState({ showCreateUser: false});
        this.props.enqueueSnackbar(
            this.props.t("notifications.could_not_send_invitation_to_x", {email: email}), { variant: 'error' }
        );
        return {success: false};
    }

    delUserTapped(user) {
        /* Called when the delete button on a user entry was clicked */
        this.setState({
            selectedUser: user,
            showVerify: true
        });
    }

    async delUserClicked() {
        /* Called after the verification popup when a user is being removed 
           from the organization. */
        const success = await this.userService.removeUser(this.state.selectedUser.email);
        if(success) {
            const email = this.state.selectedUser.email;
            this.context.setSelectedUser(null);
            this.setState({ showDetails: false, showVerify: false, selectedUser: null, refresh: true, });
            this.props.enqueueSnackbar(
                this.props.t('notifications.user_x_has_been_removed_from_this_organization',
                    { user_email: email }),
                { variant: 'success' }
            );
        }else {
            this.props.enqueueSnackbar(
                this.props.t('notifications.could_not_remove_x_from_this_organization',
                    { user_email: this.state.selectedUser.email }),
                {variant: 'error'}
            );
            this.toggleVerify(false);
        }
    }

    toggleCreateUser(bool){
        /* Toggles visibilty of the CreateUser Component */
        if(bool) {
            this.setState({
                showCreateUser: bool,
            });
        } else {
            this.setState({
                showCreateUser: bool,
            });
        }
    }

    toggleVerify(bool) {
        /* Toggles visibilty of the Verify Component */
        this.setState({
            showVerify: bool,
        })
    }

    changeUserSelection(user) {
        /* Called when a user within the list is clicked */
        if (user === this.state.selectedUser) {
            this.setState({ selectedUser: null });
            this.context.setSelectedUser(null);
            return;
        }
        this.setState({ selectedUser: user });
        this.context.setSelectedUser(JSON.stringify(user));
    }

    filterUser(user) {
        /* 
           Filters a user.
           Looks for the applied filter in full name of the user and their email.
        */
        const in_mail = user.email.toLowerCase().includes(this.state.filter.toLowerCase());
        const in_full_name = this.userService.getFullName(user).toLowerCase().includes(this.state.filter.toLowerCase());
        return (in_mail || in_full_name);
    }

    onAccessClicked(user) {
        /* Shows / hiddes the matrix, changes scroll behaviour */
        document.body.style.overflowY = 'hidden';
        this.setState({selectedUser: user, showMatrix: !this.state.showMatrix});
    }

    onExitMatrix() {
        document.body.style.overflowY = 'scroll';
        if(!this.state.showDetails) {
            this.setState({showMatrix: false, selectedUser: null});
            return
        }
        this.setState({showMatrix: false});
    }

    editRule(data){
        this.setState({selectedRule: data});
    }

    async deleteRule(data){
        let success = false;
        if (this.apiService){
            if(this.state.selectedUser && this.state.selectedUser.id)
            {
                success = (await this.apiService.removeUserAccessRule(this.state.selectedUser.id, data.rule_id)).success;
            }
        }
        /* Indicate to the user if the removal was succesful */
        if(success) {
            this.setState({ refreshMatrix: true });
            this.props.enqueueSnackbar(
                this.props.t('notifications.the_access_rule_was_successfully_deleted'),
                { variant: 'success' }
            );
        } else {
            this.props.enqueueSnackbar(
                this.props.t('notifications.could_not_delete_access_rule'),
                { variant: 'error' }
            );
        }
    }

    async refreshUsers() {
        const cur_user_id = this.state.selectedUser ? this.state.selectedUser.id : -1;
        const users = await this.userService.retrieveUsers();
        this.setState({users: users});
        if(cur_user_id !== -1){
            const selection = this.state.users.find((user) => user.id === cur_user_id);
            if(selection === undefined){
                this.setState({selectedUser: null});
            }else{
                this.context.setSelectedUser(JSON.stringify(selection));
                this.setState({selectedUser: selection});
            }
        }
    }

    async updateUser(user_id) {
        /* Gets a single user from the API to update the state of the page */
        const updated_user = await this.userService.retrieveSingleUser(user_id);
        if (updated_user) {
            const idx = this.state.users.findIndex(user => user.id === user_id);
            if (idx !== -1) {
                let updated_list = [...this.state.users];
                updated_list[idx] = updated_user;
                this.setState({ users: updated_list });
                this.changeUserSelection(updated_user);
            }
        }
    }

    updateCardStatus(card_id, assigned_user) {
        let affected_card = this.state.cards.find(c => c.id === card_id);
        if (affected_card) {
            affected_card.user = assigned_user;
            this.setState({
                cards: [...this.state.cards.filter(c => c.id !== card_id), affected_card]
            });
        }
        return;
    }

    render() {
        const { t } = this.props;

        const headCells = [
            {
                id: 'first_name',
                label: this.props.t('labels.first_name'),
                sortable: true,
            },
            {
                id: 'last_name',
                label: this.props.t('labels.last_name'),
                sortable: true,
            },
            {
                id: 'groups',
                label: this.props.t('labels.groups'),
            },
        ];

        const handleRequestSort = (event, property) => {
            const isAsc = this.state.orderBy === property && this.state.order === 'asc';
            this.setState({ order: isAsc ? 'desc' : 'asc', orderBy: property});
        };

        const EnhancedTableHead = (props) => {
            const { order, orderBy, onRequestSort } = props;
            const createSortHandler = (property) => (event) => {
                onRequestSort(event, property);
            };

            return (
                <TableHead>
                    <TableRow>
                        <TableCell padding="checkbox">
                        </TableCell>
                        {headCells.map((headCell) => (
                        <StyledTableCell
                            key={headCell.id}
                            sortDirection={orderBy === headCell.id ? order : false}
                            // style={headCell.id === "first_name" ? {width: '100px', whiteSpace: 'nowrap'} : {}}
                        >
                            {headCell.sortable ?
                                (<TableSortLabel
                                    style={{ fontSize: 10,}}
                                    active={orderBy === headCell.id}
                                    direction={orderBy === headCell.id ? order : 'asc'}
                                    onClick={createSortHandler(headCell.id)}
                                >
                                    {headCell.label}
                                    {orderBy === headCell.id ? (
                                        <Box component="span" sx={visuallyHidden}>
                                            {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                        </Box>
                                    ) : null}
                                </TableSortLabel>) : 
                                    headCell.label
                                }
                        </StyledTableCell>
                        ))}
                    </TableRow>
                </TableHead>
            );
        };

        var users = this.state.users;
        var userList = null;
        const unAssignedCardList = this.state.cards.filter(card => !card.user);

        const is_small_screen = this.props.smallScreen;
        const drawerOpen = this.state.selectedUser !== null;
        const drawerWidth = drawerOpen ? 285 : 0;

        /* Apply filter if necessary */
        if(users && this.state.filter) {
            users = users.filter(this.filterUser);
        }

        /* Render users if any */
        if(users) {
            userList = stableSort(users, getComparator(this.state.order, this.state.orderBy))
                .map((user) =>
                    <ListEntryUser user={user} onSelect={this.changeUserSelection} onAccess={this.onAccessClicked} selected={this.state.selectedUser} groups={this.state.usergroups} key={String(user.id) + user.email} />);
        }

        return (
            <div className="users page" style={{width: drawerOpen && !is_small_screen ? 'calc(100% - 285px)' : '100%'}}>
                <div className="page-inner" style={{padding: 0}}>
                    <div className="left-side" style={{width: '100%', flexShrink: 0, padding: 0}}>
                        <div className="actions">
                            <div className="search-bar">
                                <div className="icon"></div>
                                <input type="text" placeholder={t("search-placeholders.user")}
                                    value={this.state.filter} 
                                    onChange={(event) => this.setState({filter: event.target.value})}
                                />
                            </div>
                            <button className="button" onClick={() => this.toggleCreateUser(true)}>
                                {`+ ${this.props.t('labels.user')}`}
                            </button>
                        </div>
                        <TableContainer style={{ maxHeight: this.props.windowHeight - 202}}>
                            <Table stickyHeader>
                                <EnhancedTableHead
                                    order={this.state.order}
                                    orderBy={this.state.orderBy}
                                    onRequestSort={handleRequestSort}
                                />
                                <TableBody dense="true">
                                    {userList}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </div>
                    {this.state.showMatrix ? 
                        <AccessMatrix 
                            locks={this.state.locks}
                            user={this.state.selectedUser}
                            onExit={this.onExitMatrix}
                            apiService={this.apiService}
                            lockService={this.lockService}
                            editRule={this.editRule}
                            refresh={this.state.refreshMatrix}
                            onRefresh={() => this.setState({ refreshMatrix: false })}
                            />
                        : null
                    }
                    <Drawer anchor={is_small_screen ? 'bottom' : 'right'} open={drawerOpen} variant="permanent" sx={drawerOpen ? {
                        width: is_small_screen ? '100%' : drawerWidth,
                        height: is_small_screen ? 200 : '100%',
                        flexShrink: 0,
                        [`& .MuiDrawer-paper`]: {
                            width: is_small_screen ? '100%' : drawerWidth, boxSizing: 'border-box',
                            height: is_small_screen ? 200 : '100%', backgroundColor: 'rgb(24, 24, 24)'
                        },
                    } : {}}
                    >
                        {is_small_screen ? null : <div style={{ height: 48 }} />}
                        <ListUserDetails
                            user={this.state.selectedUser}
                            userService={this.userService}
                            orgService={this.orgService}
                            onAccess={this.onAccessClicked}
                            onDelete={this.delUserTapped}
                            smallScreen={is_small_screen}
                            unAssignedCards={unAssignedCardList}
                            updateUser={this.updateUser}
                            updateCardStatus={this.updateCardStatus}
                        />
                    </Drawer>
                </div>
                { this.state.showCreateUser ? 
                    <CreateUser 
                        onAdd={this.addUserClicked}
                        onCancel={() => this.toggleCreateUser(false)}
                        onInvite={this.inviteUserClicked}/> 
                : null  }
                { this.state.showVerify ? 
                    <VerifyUserDelete
                        onDelete={this.delUserClicked}
                        onCancel={() => this.toggleVerify(false)}
                        user={this.state.selectedUser}/> 
                : null }
                { this.state.selectedRule ?
                    <EditAccessRule user={this.state.selectedUser} lock={this.state.selectedRule.lock} accessList={this.state.selectedRule.access}
                        selectedRule={this.state.selectedRule} onClose={() => this.setState({ selectedRule: null })}
                        onDelete={this.deleteRule} onSave={() => this.setState({ refreshMatrix: true })}
                    />
                    :null
                }
            </div> 
        )
    }
}

export default withTranslation()(withSnackbar(Users));
