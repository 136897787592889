import React, { useState } from 'react';
import WebFont from 'webfontloader';
import './App.css';
import { HashRouter as Router } from 'react-router-dom';
import Routes from './routes';
import { ServiceContextProvider } from './context/ServiceContext';
import ApiService from './services/ApiService';
import AuthService from './services/AuthService';
import SignIn from './pages/sign_in/SignIn';
import { SnackbarProvider } from 'notistack';
import { Slide } from '@mui/material';
import SnackbarCloseButton from './components/snackbar_close_button/SnackbarCloseButton';

WebFont.load({
  google: {
    families: ['Open Sans:300,400,700', 'sans-serif']
  }
});

const apiService = new ApiService();

function App() {

  const [authenticated, setAuthenticated] = useState(false);
  const [checkingAuth, setCheckingAuth] = useState(false);
  const [checkedAuth, setCheckedAuth] = useState(false);

  const [authService] = useState(
    new AuthService(apiService, setAuthenticated, () => { setAuthenticated(false); setCheckedAuth(false) })
  );

  if (!authenticated && !checkingAuth && !checkedAuth) {
    /* Check if the user is authenticated */
    setCheckingAuth(true);
    authService.isAuthenticated().then((auth) => {
      if (auth.success) {
        setAuthenticated(true);
      }
      setCheckingAuth(false);
      setCheckedAuth(true);
    });
  }

  if (authenticated) {
    /* Return JS Application */
    return (
      <SnackbarProvider maxSnack={5}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        TransitionComponent={Slide}
        action={snackbarKey => <SnackbarCloseButton snackbarKey={snackbarKey}/>}
      >
        <div className="App">
          <ServiceContextProvider apiService={apiService} authService={authService}>
            <Router>
              <Routes />
            </Router>
          </ServiceContextProvider>
        </div>
      </SnackbarProvider>
    );
  }
  /* Return login screen */
  return (<div className="App">
    <SignIn authService={authService} loading={checkingAuth} />
  </div>);
}

export default App;
