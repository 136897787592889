import React from 'react';
import { Avatar, TableCell, TableRow } from '@mui/material';
import { withStyles } from '@mui/styles';
import PowerIcon from '../power_icon/PowerIcon';
import './listentrylock.css';

const StyledTableRow = withStyles({
    root: {
        cursor: 'pointer',
        backgroundColor: 'rgba(39, 40, 41, 0.9)',
        "&:hover": {
            backgroundColor: 'rgba(39, 40, 41, 0.7)',
        }
    }
})(TableRow);

const StyledTableCell = withStyles({
    root: {
        borderBottom: '1px solid #181818',
        fontSize: 13,
        padding: '6px'
    }
})(TableCell);

function ListEntryLock({lock, selected, onSelect}) {
    var is_selected = false;
    if(selected) is_selected = selected.uuid === lock.uuid;

    const renderHardwarePhoto = (hardware) => {
        if(hardware){
            if(hardware.toLowerCase() === "edh"){
            return(<div className="hardware-image EDH"></div>);
            }
            if(hardware.toLowerCase() === "edc"){
                return(<div className="hardware-image EDC"></div>);
            }
            if(hardware.toLowerCase() === "wmr"){
                return(<div className="hardware-image WMR"></div>);
            }
            if(hardware.toLowerCase() === "pcb"){
                return(<div className="hardware-image PCB"></div>);
            }
        }
        /* Unknown hardware type */
        return(<div className="hardware-image"></div>);
    }

    return (
        <StyledTableRow style={is_selected ? { backgroundColor: 'rgba(39, 40, 41, 0.7)' } : {}} onClick={(event) => onSelect(lock)}>
            <StyledTableCell>
                <Avatar sx={{
                        backgroundColor: '#181818',
                        color: '#fff',
                        fontSize: 13,
                        height: 34,
                        marginLeft: '6px',
                        width: 34,
                }}>
                    {renderHardwarePhoto(lock.hardware)}
                </Avatar>
            </StyledTableCell>
            <StyledTableCell style={{fontWeight: 'bold'}}>
                {lock.lock_name}
            </StyledTableCell>
            <StyledTableCell style={{fontWeight: 'bold'}}>
                {lock.building_name}
            </StyledTableCell>
            <StyledTableCell style={{fontWeight: 'bold'}}>
                {lock.user_count}
            </StyledTableCell>
            <StyledTableCell>
                 <PowerIcon hw_type={lock.hardware} state={lock.battery_state}/>
            </StyledTableCell>
        </StyledTableRow>
    );
}

export default ListEntryLock;