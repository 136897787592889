import React from 'react';
import AccessRule from './AccessRule';

function AccessLock({loading, data, onEdit, idx}) {

    const newRule = {
        lock: data ? data.lock : -1, 
        access: [false, false, false, false, false, false, false], 
        rule_id: -1

    };

    const renderLockIcon = (hardware) => {
        if(hardware){
            if(hardware.toLowerCase() === "edh"){
            return(<div className="lock-icon EDH"></div>);
            }
            if(hardware.toLowerCase() === "edc"){
                return(<div className="lock-icon EDC"></div>);
            }
            if(hardware.toLowerCase() === "wmr"){
                return(<div className="lock-icon WMR"></div>);
            }
            if(hardware.toLowerCase() === "pcb"){
                return(<div className="lock-icon PCB"></div>);
            }
        }
        return(<div className="icon"></div>);

    };

    let cellList = [];
    if (data) {
        cellList = data.rules.map(rule => <AccessRule loading={loading} data={rule} onEdit={onEdit} key={rule.rule_id}/>);
        if (cellList.length < 7) cellList.push(
            <div className="cell" key={data.lock.id + "_cell_add"}>
                <div className="add-rule unselectable" onClick={() => onEdit(newRule)}>+</div>
            </div>);
        if (cellList.length < 7){
            for (let i = cellList.length; i < 7; i++){
                cellList.push(<div className="cell inactive" key={data.lock.id + "_" + i}></div>);
            }
        }
    } else {
        for (let i = 0; i < 7; i++){
            cellList.push(<div className="cell inactive" key={idx + "_loading_" + i}></div>);
        }
    }

    return (
        <div className="col" id="slide-1">
            <div className="cell access-lock">
                {loading ? <div className="lock-icon-shape shimmer"/> : renderLockIcon(data.lock.hardware)} 
                <div className={loading ? "lock-name-shape shimmer" : "lock-name"}>{loading ? "" : data.lock.name}</div>
            </div>
            {cellList}
        </div>
    );
}

export default AccessLock;