import React from 'react';
import { removeHypen, dtMinuteDelta } from '../../util/util.js';
import { Avatar, ListItemText, Stack, TableCell, TableRow, Tooltip } from '@mui/material';
import Fade from '@mui/material/Fade';
import { withStyles } from '@mui/styles';
import XSLogState from '../xs_mui/XSLogState.js';
import moment from 'moment';

const StyledTableRow = withStyles({
    root: {
        backgroundColor: 'rgba(39, 40, 41, 0.9)',
        "&:hover": {
            backgroundColor: 'rgba(39, 40, 41, 0.7)',
        }
    }
})(TableRow);

const StyledTableCell = withStyles({
    root: {
        borderBottom: '1px solid #181818',
        fontSize: 13,
        padding: '6px'
    }
})(TableCell);

function getInitials(first, last) {
    /* Constructs the user initials */
    var last_name = last.replace(/\s/g, "");
    /* Find first capital letter in last name */
    for(var i=0; i < last_name.length; i++) {
        if(last_name[i] === last_name[i].toUpperCase()) {
            return (`${first[0]}${last_name[i]}`);
        }
    }
    /* If there is no capital letter in the last name, just use the first letter */
    return (`${first[0]}${last_name[0].toUpperCase()}`);
}

function ListEntryLog({log, lockService, time_from, time_till, is_small_screen, startDate}) {
    const log_minutes = dtMinuteDelta(log.datetime, startDate.startOf('day'));
    if(!(log_minutes >= time_from && log_minutes <= time_till)) return null;

    if(!lockService) return null

    const lock = lockService.getLock(log.lock);
    const datetime = moment(log.datetime);

    return (
        <StyledTableRow key={removeHypen(log.user.email + log.datetime)}>
            <StyledTableCell>
                <XSLogState state={log.state} />
            </StyledTableCell>
            <StyledTableCell>
                <Tooltip title={datetime.format('HH:mm:ss')} placement="bottom-start" TransitionComponent={Fade} TransitionProps={{ timeout: 300 }}>
                    <div>{datetime.format('HH:mm')}</div>
                </Tooltip>
            </StyledTableCell>
            <StyledTableCell>
                {datetime.format('DD MMM YYYY')}
            </StyledTableCell>
            <StyledTableCell>
                <Stack direction='row' spacing={1} sx={{alignItems: 'center'}}>
                    <Avatar src={log.user.avatar} sx={{
                        backgroundColor: '#181818',
                        color: '#fff',
                        fontSize: 13,
                        height: 34,
                        width: 34,
                    }}>
                        {getInitials(log.user.first_name, log.user.last_name)}
                    </Avatar>
                    <ListItemText>
                        {log.user.first_name + " " + log.user.last_name}
                    </ListItemText>
                </Stack>
            </StyledTableCell>
            <StyledTableCell>
                    {lock ? lock.lock_name : ""}
            </StyledTableCell>
            {is_small_screen ?
                null
                :
                <StyledTableCell>
                    {lock ? lock.building_name : ""}
                </StyledTableCell>
            }
        </StyledTableRow>
    );
}

export default ListEntryLog;
