import React, { Component } from 'react';

export class LogRangeSelector extends Component{

    constructor(props) {
        super(props)

        this.startTrackingMouse = this.startTrackingMouse.bind(this);
    }

    startTrackingMouse(e) {
        e.preventDefault();
        this.props.onDrag(e, this.props.left);
    }
  
    render() {
        if(!this.props.isDragging){
            return(
                <div className="range-selector" onMouseDown={(e) => this.startTrackingMouse(e)} style={{left: this.props.x}}>
                    <div className="time unselectable" style={{opacity: 0.5}}>{this.props.time}</div>
                    <div className="arrow"/>
                    <div className="pointer"/>
                </div>
            );
        }
        return(
            <div className="range-selector" style={{left: this.props.x}}>
                <div className="time unselectable">{this.props.time}</div>
                <div className="arrow" style={{opacity: 0.7}}/>
                <div className="pointer" style={{opacity: 0.7}}/>
            </div>
        );
    }
  }