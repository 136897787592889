import React from 'react';
import styled, { keyframes } from "styled-components";
import './PowerIcon.css';
import Flash from "@bit/formidablelabs.react-animations.flash";
import batt_0 from "../../assets/svgs/batt_0.svg";
import batt_1 from "../../assets/svgs/batt_1.svg";
import batt_2 from "../../assets/svgs/batt_2.svg";
import batt_3 from "../../assets/svgs/batt_3.svg";
import plug from "../../assets/svgs/plug.svg";

const FlashAnimation = keyframes`${Flash}`;
const FlashDiv = styled.div`
  animation: infinite 2s ${FlashAnimation};
`;

function PowerIcon({hw_type, state}) {
    /* WMR should show a plug instead of a battery */
    if(hw_type === "WMR" || hw_type === "PCB"){
        return (<img src={plug} style={{height: 16, width: 16}} alt=""/>);
    }
    /* Determine battery icon on battery state */
    var back_icon = null;
    var blink_icon = false;
    switch(state){
        case 0:
            back_icon = batt_0;
            break;
        case 1:
            back_icon = batt_1;
            break;
        case 2:
            back_icon = batt_2;
            break;
        case 3:
            blink_icon = true;
            back_icon = batt_3;
            break;
        default:
            /* In case all else fails */
            return null;
    }
    if(back_icon == null) return  null;
    
    /* Blink the icon if the battery state is very low */
    if(blink_icon) {
        return (<FlashDiv><img src={back_icon} style={{height: 16, width: 16}} alt=""/></FlashDiv>);
    }
    return (<img src={back_icon} style={{height: 16, width: 16}} alt=""/>);
}

export default PowerIcon;