class BridgeService {

    constructor(apiService) {

        this.apiService = apiService;
        this.bridges = [];
    }

    async 

    async retrieveBridges(callback) {
        if(this.apiService){
            await this.apiService.retrieveBridges(callback);
        }
    }
    
}
export default BridgeService;