class OrganizationService {

    constructor(apiService) {

        this.apiService = apiService;
        this.orgs = [];
        this.userGroups = [];
        this.cards = [];
    }

    async retrieveOrgs() {
        if(this.apiService){
            const response = await this.apiService.getOrgs();
            if(response.success)
            {
                this.orgs = response.data;
                return this.orgs;
            }
        }
        return this.orgs;
    }

    async retrieveUserGroups() {
        if(this.apiService){
            const response = await this.apiService.getUserGroups();
            if(response.success)
            {
                this.userGroups = response.data;
                return this.userGroups;
            }
        }
        return this.userGroups;
    }

    getOrgLogo(pk) {
        const org = this.getOrg(pk);
        if(org) return org.logo;
        return null;
    }

    getSelOrgIdx() {
        try{
            if(this.apiService && this.orgs){
                const org_id =  this.apiService.getOrgId();
                for(var i = 0; i < this.orgs.length; i++){
                    if(org_id === this.orgs[i].id){
                       return i;
                    }
                }
            }
        }catch{
            console.log("Failure getting selected org index");
        }
        return 0;
    }

    getOrg(pk) {
        for(var i = 0; i < this.orgs.length; i++) {
            if ( this.orgs[i].id === pk) {
                return this.orgs[i];
            }
        }
        return null;
    }

    async getCards() {
        if(this.apiService){
            const response = await this.apiService.getOrgCards();
            if(response.success)
            {
                this.cards = response.data;
                return this.cards;
            }
        }
        return this.cards;
    }

    async assignCard(card_id, user_id) {
        if(this.apiService){
            return await this.apiService.assignCard(card_id, user_id).success;
        }
        return false;
    }
    
    async unassignCard(card_id) {
        if(this.apiService){
            return await this.apiService.unassignCard(card_id).success;
        }
        return false;
    }

    getSelectedOrg(){
        if(this.apiService && this.orgs){
            const org_id =  this.apiService.getOrgId();
            for(var i = 0; i < this.orgs.length; i++){
                if(org_id === this.orgs[i].id){
                   return this.orgs[i];
                }
            }
        }
        return null;
    }

    async getSelectedOrgBuildings() {
        const org = this.getSelectedOrg();
        if(org && org.buildings) return org.buildings;
        return [];
    }

    getOrgs(){
        return this.orgs;
    }

    async removeGroup(group_id){
        /* Removes a user from the org */
        const response = await this.apiService.removeGroup(group_id);
        if(response.success){
            const i = this.userGroups.findIndex((group) => group.id === group_id);
            this.userGroups.splice(i, 1);
            return true;
        }
        return false;
    }

    async addGroup(group_name) {
        /* Sends a request to the API for adding a group */
        const response = await this.apiService.addGroup(group_name);
        if(!response.success){
            return {success: response.success, reason: await response.data.text()};
        }else{
            return {success: response.success, reason: response.data};
        }
    }
}
export default OrganizationService;