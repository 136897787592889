import React, { useContext } from 'react'
import Nav from '../../components/nav/Nav'
import { useTranslation } from 'react-i18next';
import { Box } from '@mui/system';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import Users from '../users/Users';
import Groups from '../groups/Groups';
import { ServiceContext } from '../../context/ServiceContext';
import useWindowDimensions from '../../hooks/UseWindowDimensions';

export default function People(props) {
    const { t } = useTranslation();
    const { height, width } = useWindowDimensions();
    const { subMenuLayout, setSubMenuLayout } = useContext(ServiceContext);

    const [value, setValue] = React.useState(subMenuLayout.people);

    const handleChange = (event, newValue) => {
        setValue(newValue);
        setSubMenuLayout(cur_layout => ({...cur_layout, people: newValue}));
    };

    const small_width = 850;

    return (
        <div className="page">
            <Nav />
            <TabContext value={value}>
                <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                    <TabList onChange={handleChange} style={{marginTop: 48}}>
                        <Tab label={t('navigation.users')} value="1" sx={{ fontSize: 12, textTransform:'initial' }}/>
                        <Tab label={t('navigation.groups')} value="2" sx={{ fontSize: 12, textTransform: 'initial' }} />
                    </TabList>
                </Box>
                <TabPanel value="1"><Users {...props} smallScreen={width <= small_width} windowHeight={height}/></TabPanel>
                <TabPanel value="2"><Groups {...props} smallScreen={width <= small_width} windowHeight={height}/></TabPanel>
            </TabContext>
        </div>
  )
}
