import { LinearProgress } from '@mui/material';
import moment from 'moment';
import React, { useState, useMemo } from 'react';
import Measure from 'react-measure';
import { dtMinuteDelta, padSingleDigit, removeHypen } from '../../util/util';
import LogRangeBar from '../log_range_selector/LogRangeBar';
import './LogTimeline.css';

export default function LogTimeline2({logs, setTimeFilter, daysDisplayed, minutesDisplayed, startDate, loading}) {
    const [dimensions, setDimensions] = useState({ width: -1, height: 75 });
    const screen_is_small = !(dimensions.width > 800);
    const graph_resolution = screen_is_small ? 256 : 512;

    const renderLogs = useMemo(() => {
        if (logs && !loading) {
            const step_duration = (1440 * daysDisplayed) / graph_resolution;
            const step_width = dimensions.width / graph_resolution;
            const bins = Math.floor(graph_resolution * daysDisplayed);
            if (isNaN(bins)) return [];
            let openings = Array(bins).fill(0);

            for (let log_idx = 0; log_idx < logs.length; log_idx++) {
                const slice_idx = Math.floor(dtMinuteDelta(logs[log_idx].datetime, startDate) / step_duration);
                openings[slice_idx]++;
            }
            return(
                openings.map(
                    (value, idx) => {
                        if (value > 0) return <div style={{
                            width: step_width, left: step_width * idx, height: 25, position: 'absolute', backgroundColor: '#0867DE', opacity: Math.min(value * 0.2, 1.0), zIndex: 1000
                        }} key={removeHypen("log_slice_" + String(idx) + String(value))}/>
                        return null;
                    }
                )
            );
        }
        return [];
    }, [daysDisplayed, startDate, logs, dimensions.width, graph_resolution, loading]);

    const renderLabels = useMemo(() => {
        const time_resolution = 24;
        const time_offset = dimensions.width / (time_resolution * daysDisplayed);
        const day_width = dimensions.width / daysDisplayed;

        const dot_list = [];
        const time_list = [];
        const day_list = [];
        
        /* Alter amount of time labels based on screen size */
        if (!screen_is_small) {
            if (daysDisplayed <= 5) {
                for (let day_idx = 0; day_idx < daysDisplayed; day_idx++) {
                    for (let time_idx = 0; time_idx < time_resolution; time_idx += daysDisplayed) {
                        const left_offset = (time_idx * time_offset) + (day_width * day_idx) - 1;
                        const key_part = `${day_idx}_${time_idx}`;
                        if(time_idx){
                            time_list.push(<div className="time-middle" style={{
                                left: (time_idx * time_offset) + (day_width * day_idx) - 1,
                                opacity: 0.4
                            }} key={removeHypen("time_" + key_part)} >{padSingleDigit(time_idx)}:00</div>)
                        }else{
                            time_list.push(<div className="time-middle" style={{
                                left: (time_idx * time_offset) + (day_width * day_idx) - 1,
                                opacity: 0.9
                            }} key={removeHypen("date_" + key_part)} >{moment(startDate).add(day_idx, 'days').format("DD/MM")}</div>)
                        }
                        dot_list.push(<div key={removeHypen("dot_" + key_part)} className="time-dot" style={{ left: left_offset }} />);
                        if (!time_idx) {
                            day_list.push(<div key={removeHypen("day_" + key_part)} style={{ width: 2, left: left_offset, height: 25, position: 'absolute', backgroundColor: '#fff', opacity: 0.1 }} />);
                        }
                    }
                }
            } else if (daysDisplayed > 5){
                for (let day_idx = 0; day_idx < daysDisplayed; day_idx++) {
                    for (let time_idx = 0; time_idx < time_resolution; time_idx += time_resolution) {
                        const left_offset = (time_idx * time_offset) + (day_width * day_idx) - 1;
                        const key_part = `${day_idx}_${time_idx}`;
                        time_list.push(<div className="time-middle" style={{
                            left: left_offset,
                            opacity: time_idx ? 0.4 : 0.9
                        }} key={removeHypen("time_" + key_part)}
                        >{moment(startDate).add(day_idx, 'days').format("DD/MM")}</div>)
                        if (!time_idx) {
                            day_list.push(<div key={removeHypen("day_" + key_part)} style={{ width: 2, left: left_offset, height: 25, position: 'absolute', backgroundColor: '#fff', opacity: 0.1 }} />);
                            dot_list.push(<div key={removeHypen("dot_" + key_part)} className="time-dot" style={{ left: left_offset }} />);
                        }
                    }
                }
            }
        } else {
            if (daysDisplayed === 1) {
                for (let time_idx = 0; time_idx < time_resolution; time_idx += 2) {
                    const left_offset = time_idx * time_offset - 1;
                    const key_part = `0_${time_idx}`;
                    time_list.push(<div className="time-middle" style={{
                        left: left_offset, opacity: time_idx ? 0.4 : 0.9
                    }} key={removeHypen("time_") + key_part}
                    >{padSingleDigit(time_idx)}:00</div>)
                    dot_list.push(<div key={removeHypen("dot_" + key_part)} className="time-dot" style={{ left: left_offset }} />);
                    if (!time_idx) {
                        day_list.push(<div key={removeHypen("day_" + key_part)}
                        style={{
                            width: 2, left: left_offset, height: 25, position: 'absolute', backgroundColor: '#fff', opacity: 0.1
                        }} />);
                    }
                }
            } else {
                if (daysDisplayed <= 5) {
                    for (let day_idx = 0; day_idx < daysDisplayed; day_idx++) {
                        for (let time_idx = 0; time_idx < time_resolution; time_idx += time_resolution) {
                            const left_offset = (time_idx * time_offset) + (day_width * day_idx) - 1;
                            const key_part = `${day_idx}_${time_idx}`;
                            time_list.push(<div className="time-middle" style={{
                                left: left_offset, opacity: time_idx ? 0.4 : 0.9
                            }} key={removeHypen("time_") + key_part}
                            >{moment(startDate).add(day_idx, 'days').format("DD/MM")}</div>)
                            dot_list.push(<div key={removeHypen("dot_" + key_part)} className="time-dot" style={{ left: left_offset }} />);
                            day_list.push(<div key={removeHypen("day_" + key_part)} style={{ width: 2, left: left_offset, height: 25, position: 'absolute', backgroundColor: '#fff', opacity: 0.1 }} />);
                        }
                    }
                } else {
                    for (let day_idx = 0; day_idx < daysDisplayed; day_idx++) {
                        for (let time_idx = 0; time_idx < time_resolution; time_idx += time_resolution) {
                            const left_offset = (time_idx * time_offset) + (day_width * day_idx) - 1;
                            const key_part = `${day_idx}_${time_idx}`;
                            dot_list.push(<div key={removeHypen("dot_" + key_part)} className="time-dot" style={{ left: left_offset }} />);
                            day_list.push(<div key={removeHypen("day_" + key_part)} style={{ width: 2, left: left_offset, height: 25, position: 'absolute', backgroundColor: '#fff', opacity: 0.1 }} />);
                        }
                    }
                }
            }
        }

        day_list.push(<div key={"day_end"} style={{ width: 2, left: dimensions.width - 2, height: 25, position: 'absolute', backgroundColor: '#fff', opacity: 0.1 }} />);
        dot_list.push(<div key={"dot_end"} className="time-dot" style={{left: dimensions.width - 2 }}/>);

        return [dot_list, day_list, time_list];
    }, [daysDisplayed, dimensions, screen_is_small, startDate]);


    const [dotList, dayList, timeList] = renderLabels;

    return (
        <div className="bar-container">
            <Measure bounds onResize={contentRect => setDimensions(contentRect.bounds)}>
                {({ measureRef }) => (
                    <div className="bar" ref={measureRef}>
                        {dotList}
                        {loading ? <LinearProgress color={'info'} style={{ top: 11, opacity: 0.5 }} /> : renderLogs}
                        {dayList}
                    </div>)}
            </Measure>
            <LogRangeBar barWidth={dimensions.width} minutesDisplayed={minutesDisplayed} daysDisplayed={daysDisplayed} setTimeFilter={setTimeFilter} loading={loading}/>
            {timeList}
        </div>
    )
}
