import React from 'react';
import { Avatar, TableCell, TableRow } from '@mui/material';
import GroupIcon from '@mui/icons-material/Group';
import { withStyles } from '@mui/styles';

const StyledTableRow = withStyles({
    root: {
        cursor: 'pointer',
        backgroundColor: 'rgba(39, 40, 41, 0.9)',
        "&:hover": {
            backgroundColor: 'rgba(39, 40, 41, 0.7)',
        }
    }
})(TableRow);

const StyledTableCell = withStyles({
    root: {
        borderBottom: '1px solid #181818',
        fontSize: 13,
        padding: '6px'
    }
})(TableCell);

function ListEntryGroup({group, onSelect, selected}) {
    var is_selected = false;
    if(selected) is_selected = selected.id === group.id;
    
    return(
        <StyledTableRow style={is_selected ? {backgroundColor: 'rgba(39, 40, 41, 0.7)'} : {}} onClick={() => onSelect(group)}>
            <StyledTableCell>
                <Avatar sx={{
                    backgroundColor: '#181818',
                    color: '#fff',
                    fontSize: 13,
                    height: 34,
                    marginLeft: '6px',
                    width: 34,
                }}>
                    <GroupIcon sx={{height: 24, width: 24}}/>
                </Avatar>
            </StyledTableCell>
            <StyledTableCell style={{fontWeight: 'bold'}}>
                {group.name}
            </StyledTableCell>
            <StyledTableCell style={{ fontWeight: 'bold' }}>
                {group.members.length}
            </StyledTableCell>
        </StyledTableRow>
    );
}

export default ListEntryGroup;