import CloseIcon from '@mui/icons-material/Close';
import { IconButton } from '@mui/material';
import { useSnackbar } from 'notistack';
import * as React from 'react';

function SnackbarCloseButton({ snackbarKey }) {
    const { closeSnackbar } = useSnackbar();

    return (
        <IconButton onClick={() => closeSnackbar(snackbarKey)}>
            <CloseIcon />
        </IconButton>
    );
}

export default SnackbarCloseButton;