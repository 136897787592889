import React, { Component } from 'react';
import '../../index.css';
import './groups.css';
import { ServiceContext } from '../../context/ServiceContext';
import ListEntryGroup from '../../components/list_entry_group/ListEntryGroup';
import ListGroupDetails from '../../components/list_group_details/ListGroupDetails';
import VerifyGroupDelete from '../../components/verify_group_delete/VerifyGroupDelete';
import CreateGroup from '../../components/create_group/CreateGroup';
import AccessMatrixGroups from '../../components/access_matrix/AccessMatrixGroups';
import EditAccessRule from '../../components/access_matrix/edit_access_rule/EditAccessRule';
import MemberManager from '../../components/member_manager/MemberManager';
import { withTranslation } from 'react-i18next';
import { withSnackbar } from 'notistack';
import { Box, Drawer, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TableSortLabel } from '@mui/material';
import { withStyles } from '@mui/styles';
import { visuallyHidden } from '@mui/utils';
import { stableSort, getComparator, removeHypen } from '../../util/util';

const StyledTableCell = withStyles({
    root: {
        fontSize: 10,
        textTransform: 'uppercase',
        padding: '6px',
        paddingTop: 0,
    }
})(TableCell);

class Groups extends Component {
    

    static contextType = ServiceContext;

    constructor(props) {
        super(props)
        /* Initialize state */
        this.state = {
            order: 'asc',
            orderBy: 'name',
            selectedGroup: null,
            selectedRule: null,
            showVerify: false,
            showMatrix: false,
            showMembers: false,
            showCreateGroup: false,
            refreshMatrix: false,
            users: null,
            usergroups: null,
            locks: null,
            filter: '',
            refresh: false,
        }

        /* Handles for services */
        this.userService = null;
        this.lockService = null;
        this.apiService = null;
        this.orgService = null;
        
        /* Set up function binds */
        this.filterGroup = this.filterGroup.bind(this);
        this.addGroupClicked = this.addGroupClicked.bind(this);
        this.delGroupConfirmed = this.delGroupConfirmed.bind(this);
        this.delGroupClicked  = this.delGroupClicked.bind(this);
        this.onAccessClicked = this.onAccessClicked.bind(this);
        this.onExitMatrix    = this.onExitMatrix.bind(this);
        this.onSaveMatrix    = this.onSaveMatrix.bind(this);
        this.changeGroupSelection = this.changeGroupSelection.bind(this);
        this.refreshGroups   = this.refreshGroups.bind(this); 
        this.editRule        = this.editRule.bind(this);
        this.deleteRule      = this.deleteRule.bind(this);

    }

    componentDidMount() {
        /* Retrieve reference to lockService */
        if(!this.lockService){
            this.lockService = this.context.lockService;
        }

        /* Retrieve reference to userService */
        if(!this.userService){
            this.userService = this.context.userService;
        }

        /* Retrieve reference to apiService */
        if(!this.apiService){
            this.apiService = this.context.apiService;
        }

        /* Retrieve reference to organizationService */
        if(!this.orgService){
            this.orgService = this.context.orgService;
        }

        /* Retrieve usergroups when the component has been mounted. */
        this.orgService.retrieveUserGroups().then( usergroups => {
            this.setState({usergroups: usergroups})
        });

        /* Retrieve users when the component has been mounted. */
        this.userService.retrieveUsers().then( users => {
          this.setState({users: users})
        });

        /* Retrieve locks when the component has been mounted */
        this.lockService.retrieveLocks(true).then( locks => {
          this.setState({locks: locks})
        })

        /* Get filter from URL parameter */
        const params = new URLSearchParams(this.props.location.search);
        const name = params.get('name');
        if(name) this.setState({filter: name})

        /* Get global selected user and apply if not null */
        if(this.context.selectedGroup) this.setState({selectedGroup: JSON.parse(this.context.selectedGroup)});
    }

    async addGroupClicked(group_name) {
        /* Called when a group is being added to the organization. */
        const response = await this.orgService.addGroup(group_name);
        if(response.success){
            this.props.enqueueSnackbar(
                this.props.t('notifications.group_x_was_created_successfully', {group_name: group_name}),
                { variant: 'success' }
            );
            this.setState({showCreateGroup: false,  refresh: true});
            this.orgService.retrieveUserGroups().then( usergroups => {
                this.setState({usergroups: usergroups})
            });
            return {success: true};
        }else{
            this.props.enqueueSnackbar(
                this.props.t('notifications.could_not_create_group_x', {group_name: group_name}),
                { variant: 'error' }
            );
            this.setState({showCreateGroup: false, refresh: true});
            return {success: false};
        }
    }

    delGroupClicked(group) {
        /* Called when the delete button on a group entry was clicked */
        this.setState({
            selectedGroup: group,
            showVerify: true
        });
    }

    async delGroupConfirmed() {
        /* Called after the verification popup when a group is being removed 
           from the organization. */
        const success = await this.orgService.removeGroup(this.state.selectedGroup.id);
        /* Indicate to the user if the removal was succesful */
        if(success) {
            this.props.enqueueSnackbar(
                this.props.t('notifications.group_x_was_deleted_successfully', {group_name: this.state.selectedGroup.name}),
                { variant: 'success' }
            );
            this.context.setSelectedGroup(null);
            this.setState({showDetails: false, showVerify: false, selectedGroup: null, refresh: true});
        }else {
            this.props.enqueueSnackbar(
                this.props.t('notifications.could_not_delete_group_x', {group_name: this.state.selectedGroup.name}),
                { variant: 'error' }
            );
            this.toggleVerify(false);
        }
    }

    toggleCreateGroup(bool){
        /* Toggles visibilty of the CreateGroup Component */
        if(bool) {
            this.setState({
                showCreateGroup: bool,
            });
        } else {
            this.setState({
                showCreateGroup: bool,
            });
        }
    }

    toggleVerify(bool) {
        /* Toggles visibilty of the Verify Component */
        this.setState({
            showVerify: bool,
        })
    }

    changeGroupSelection(group) {
        /* Called when a user within the list is clicked */
        if(group === this.state.selectedGroup) {
            this.setState({selectedGroup: null});
            this.context.setSelectedGroup(null);
            return;
        }
        this.setState({selectedGroup: group});
        this.context.setSelectedGroup(JSON.stringify(group));
    }

    filterGroup(group) {
        /* 
           Filters a group.
           Looks for the applied filter in the name of the group.
        */
        return group.name.toLowerCase().includes(this.state.filter.toLowerCase());
    }

    onAccessClicked(group) {
        /* Shows / hiddes the matrix, changes scroll behaviour */
        document.body.style.overflowY = 'hidden';
        this.setState({selectedGroup: group, showMatrix: !this.state.showMatrix});
    }

    onExitMatrix() {
        document.body.style.overflowY = 'scroll';
        if(!this.state.showDetails) {
            this.setState({showMatrix: false, selectedUser: null});
            return
        }
        this.setState({showMatrix: false});
    }

    async onSaveMatrix() {
        /* Update locks */
        const locks = await this.lockService.retrieveLocks(true);
        if(!this.state.showDetails) {
            this.setState({locks: locks, showMatrix: false, selectedUser: null});
            return;
        }
        this.setState({locks: locks, showMatrix: false});
        return;
    }

    async refreshGroups(){
        const cur_group_id = this.state.selectedGroup ? this.state.selectedGroup.id : -1;
        const usergroups = await this.orgService.retrieveUserGroups();
        this.setState({usergroups: usergroups});
        if(cur_group_id !== -1){
            const selection = this.state.usergroups.find((group) => group.id === cur_group_id);
            if(selection === undefined){
                this.setState({selectedGroup: null});
            }else{
                this.context.setSelectedGroup(JSON.stringify(selection));
                this.setState({selectedGroup: selection});
            }
        }
    }

    editRule(data){
        this.setState({selectedRule: data});
    }

    async deleteRule(data){
        let success = false;
        if (this.apiService){
            if(this.state.selectedGroup && this.state.selectedGroup.id)
            {
                success = (await this.apiService.removeGroupAccessRule(this.state.selectedGroup.id, data.rule_id)).success;
            }
        }
        /* Indicate to the user if the removal was succesful */
        if(success) {
            this.setState({ refreshMatrix: true });
            this.props.enqueueSnackbar(
                this.props.t('notifications.the_access_rule_was_successfully_deleted'),
                { variant: 'success' }
            );
        } else {
            this.props.enqueueSnackbar(
                this.props.t('notifications.could_not_delete_access_rule'),
                { variant: 'error' }
            );
        }
    }

    render() {
        const headCells = [
            {
                id: 'name',
                label: this.props.t('labels.name'),
                sortable: true,
            },
            {
                id: 'members',
                label: this.props.t('labels.members'),
                sortable: true,
            },
        ];

        const handleRequestSort = (event, property) => {
            const isAsc = this.state.orderBy === property && this.state.order === 'asc';
            this.setState({ order: isAsc ? 'desc' : 'asc', orderBy: property});
        };

        const EnhancedTableHead = (props) => {
            const { order, orderBy, onRequestSort } = props;
            const createSortHandler = (property) => (event) => {
                onRequestSort(event, property);
            };

            return (
                <TableHead>
                    <TableRow>
                        <TableCell padding="checkbox">
                        </TableCell>
                        {headCells.map((headCell) => (
                        <StyledTableCell
                            key={headCell.id}
                            sortDirection={orderBy === headCell.id ? order : false}
                            style={headCell.id === "members" ? {width: 40} : {}}
                        >
                            {headCell.sortable ?
                                (<TableSortLabel
                                    style={{ fontSize: 10,}}
                                    active={orderBy === headCell.id}
                                    direction={orderBy === headCell.id ? order : 'asc'}
                                    onClick={createSortHandler(headCell.id)}
                                >
                                    {headCell.label}
                                    {orderBy === headCell.id ? (
                                        <Box component="span" sx={visuallyHidden}>
                                            {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                        </Box>
                                    ) : null}
                                </TableSortLabel>) : 
                                    headCell.label
                                }
                        </StyledTableCell>
                        ))}
                    </TableRow>
                </TableHead>
            );
        };

        var groups = this.state.usergroups;
        var groupList = null;

        const is_small_screen = this.props.smallScreen;
        const drawerOpen = this.state.selectedGroup !== null;
        const drawerWidth = drawerOpen ? 285 : 0;

        /* Apply filter if necessary */
        if(groups && this.state.filter) {
            groups = groups.filter(this.filterGroup);
        }

        /* Render groups if any */
        if(groups) {
            groupList = stableSort(groups, getComparator(this.state.order, this.state.orderBy))
                .map((group) =>
                    <ListEntryGroup key={removeHypen("group_entry" + group.id + group.name)} group={group} onSelect={this.changeGroupSelection} selected={this.state.selectedGroup}/>)
        }

        return (
            <div className="groups page" style={{width: drawerOpen && !is_small_screen ? 'calc(100% - 285px)' : '100%'}}>
                <div className="page-inner" style={{ padding: 0}}>
                    <div className="left-side" style={{width: '100%', flexShrink: 0, padding: 0}}>
                        <div className="actions">
                            <div className="search-bar">
                                <div className="icon"></div>
                                <input type="text" placeholder={this.props.t("search-placeholders.group")}
                                    value={this.state.filter} 
                                    onChange={(event) => this.setState({filter: event.target.value})}
                                />
                            </div>
                            <button className="button" onClick={() => this.toggleCreateGroup(true)}>
                                {`+ ${this.props.t('labels.group')}`}
                            </button>
                        </div>
                        <TableContainer style={{ maxHeight: this.props.windowHeight - 202}}>
                            <Table stickyHeader>
                                <EnhancedTableHead
                                    order={this.state.order}
                                    orderBy={this.state.orderBy}
                                    onRequestSort={handleRequestSort}
                                />
                                <TableBody dense="true">
                                    {groupList}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </div>
                    { this.state.showMatrix ?            
                        <AccessMatrixGroups 
                            locks={this.state.locks}
                            group={this.state.selectedGroup}
                            onExit={this.onExitMatrix}
                            onSave={this.onSaveMatrix}
                            apiService={this.apiService}
                            lockService={this.lockService}
                            editRule={this.editRule}
                            refresh={this.state.refreshMatrix}
                            onRefresh={() => this.setState({refreshMatrix: false})}/>
                        :
                        null
                }
                    { this.state.showMembers ?            
                        <MemberManager 
                            initialGroup={this.state.selectedGroup}
                            onExit={() => this.setState({showMembers: false})}
                            refreshCallback={this.refreshGroups}
                            users={this.state.users}
                        />
                        :
                        null
                    }
                    <Drawer anchor={is_small_screen ? 'bottom' : 'right'} open={drawerOpen} variant="permanent" sx={drawerOpen ? {
                        width: is_small_screen ? '100%' : drawerWidth,
                        height: is_small_screen ? 200 : '100%',
                        flexShrink: 0,
                        [`& .MuiDrawer-paper`]: {
                            width: is_small_screen ? '100%' : drawerWidth, boxSizing: 'border-box',
                            height: is_small_screen ? 200 : '100%', backgroundColor: 'rgb(24, 24, 24)'
                        },
                    } : {}}>
                        {is_small_screen ? null : <div style={{ height: 48 }} />}
                        <ListGroupDetails
                            group={this.state.selectedGroup}
                            onAccess={this.onAccessClicked}
                            onDelete={this.delGroupClicked}
                            onMembers={() => this.setState({ showMembers: true })}
                            refreshCallback={this.refreshGroups}
                            smallScreen={is_small_screen}
                        />
                    </Drawer>
                </div>
                { this.state.showCreateGroup ? 
                    <CreateGroup 
                        onAdd={this.addGroupClicked}
                        onCancel={() => this.toggleCreateGroup(false)}
                    /> 
                    : null  }
                { this.state.showVerify ? 
                    <VerifyGroupDelete
                        onDelete={this.delGroupConfirmed}
                        onCancel={() => this.toggleVerify(false)}
                        group={this.state.selectedGroup}/> 
                    : null }
                { this.state.selectedRule ?
                    <EditAccessRule group={this.state.selectedGroup} lock={this.state.selectedRule.lock} accessList={this.state.selectedRule.access} 
                                    selectedRule={this.state.selectedRule} onClose={() => this.setState({selectedRule: null})}
                                    onDelete={this.deleteRule} onSave={() => this.setState({refreshMatrix: true})}
                    />
                    :null
                }      
            </div> 
        )
    }
}

export default withTranslation()(withSnackbar(Groups));