class UserService {

    constructor(apiService) {
        this.apiService = apiService;
        this.users = []; 
    }

    async retrieveUsers() {
        if(this.apiService) {
            const response = await this.apiService.retrieveUsers();
            if(response.success)
            {
                this.users = response.data;
                return this.users;
            }
        }
        return this.users;
    }
    

    async retrieveUsersLazy(callback) {
        if(this.apiService) {
            await this.apiService.retrieveUsersLazy(callback);
        }
    }

    async retrieveSingleUser(user_id) {
        if(this.apiService) {
            const response = await this.apiService.retrieveSingleUser(user_id);
            if (response.success) {
                return response.data;
            }
        }
        return null;
    }
    
    getUser(email) {
        for(var i = 0; i < this.users.length; i++) {
            if ( this.users[i].email === email) {
                return (this.users[i]);
            }
        }
        return null;
    }
    
    getUserById(id) {
        for (var i = 0; i < this.users.length; i++) {
            if ( this.users[i].id === id) {
                return (this.users[i]);
            }
        }
        return null;
    }

    async userExists(email) {
        /* Checks if a user with the supplied email exists within the org */
        for(var i = 0; i < this.users.length; i++) {
            if (this.users[i].email.toLowerCase() === email.toLowerCase()) {
                return true;
            }
        }
        return false;
    }

    async addUser(email) {
        /* Sends a request to the API for adding a user */
        const response = await this.apiService.addUser(email);
        if(!response.success){
            return {success: response.success, reason: await response.data.text()};
        }else{
            return {success: response.success, reason: response.data};
        }
    }

    async inviteUser(email) {
        /* Sends a request to the API for inviting a user to register*/
        const response = await this.apiService.inviteUser(email);
        return response.success;
    }

    async removeUser(email) {
        /* Removes a user from the org */
        for(var i = 0; i < this.users.length; i++) {
            if (this.users[i].email.toLowerCase() === email.toLowerCase()) {
                const response = await this.apiService.removeUser(this.users[i].id);
                if(response.success){
                    this.users.splice(i, 1);
                    return true;
                }
            }
        }
        return false;
    }

    hasPicture(user) {
        /* Returns if the user has a picture */
        return user.avatar ? true : false;
    }

    getFullName(user) {
        /* Constructs the full name of the supplied user */
        return (`${user.first_name} ${user.last_name}`);
    }

    getInitials(user) {
        /* Constructs the user initials */
        var last_name = user.last_name.replace(/\s/g, "");
        /* Find first capital letter in last name */
        for(var i=0; i < last_name.length; i++) {
            if(last_name[i] === last_name[i].toUpperCase()) {
                return (`${user.first_name[0]}${last_name[i]}`);
            }
        }
        /* If there is no capital letter in the last name, just use the first letter */
        return (`${user.first_name[0]}${last_name[0].toUpperCase()}`);
    }

    getUserId(user) {
        /* Constructs an "ID" out of user properties*/
        return (user.first_name + user.middle_name + user.last_name + user.email);
    }
    
}
export default UserService;