import React, { Component } from 'react';
import '../../index.css';
import ListEntryLock from '../../components/list_entry_lock/ListEntryLock';
import { ServiceContext } from '../../context/ServiceContext';
import ListLockDetails from '../../components/list_lock_details/ListLockDetails';
import { withSnackbar } from 'notistack';
import { withTranslation } from 'react-i18next';
import { Drawer } from '@mui/material';
import { t } from 'i18next';
import { Box, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TableSortLabel } from '@mui/material';
import { withStyles } from '@mui/styles';
import { visuallyHidden } from '@mui/utils';
import { stableSort, getComparator } from '../../util/util';

const StyledTableCell = withStyles({
    root: {
        fontSize: 10,
        textTransform: 'uppercase',
        padding: '6px',
        paddingTop: 0,
    }
})(TableCell);

class Locks extends Component {

    static contextType = ServiceContext;

    constructor(props) {
        super(props)

        /* Initialize state */
        this.state = {
            order: 'asc',
            orderBy: 'name',
            selectedLock: null,
            filter: '',
            locks: null,
            sort_mode: 0, /* 0 = Don't sort, 1 = downwards, 2 = upwards */
            sort_on: 0,   /* 0 = Lock name, 1 = Building, 2 = Users, 3 = Firmware, 4 = Batterij */
        }

        /* Handles for services */
        this.lockService = null;
        this.apiService = null;

        /* Function binds */
        this.filterLock = this.filterLock.bind(this);
        this.onLockUpdate = this.onLockUpdate.bind(this);
        this.changeLockSelection = this.changeLockSelection.bind(this);
        this.cycleSortMode   = this.cycleSortMode.bind(this);
        this.sortOnClicked   = this.sortOnClicked.bind(this);
        this.renderArrow = this.renderArrow.bind(this);
    }

    componentDidMount() {
        /* Retrieve reference to lockService */
        if(!this.lockService){
            this.lockService = this.context.lockService;
        }
        /* Retrieve reference to apiService */
        if(!this.apiService){
            this.apiService = this.context.apiService;
        }

        /* Retrieve locks when the component has been mounted */
        this.lockService.retrieveLocks(true).then( locks => {
          this.setState({locks: locks})
        })

        /* Get global selected lock and apply if not null */
        if(this.context.selectedLock) this.setState({selectedLock: JSON.parse(this.context.selectedLock)});
    }

    changeLockSelection(lock) {
        /* Called when a lock within the list is clicked */
        if(lock === this.state.selectedLock) {
            this.setState({selectedLock: null});
            this.context.setSelectedLock(null);
            return;
        }
        this.setState({selectedLock: lock});
        this.context.setSelectedLock(JSON.stringify(lock));
    }

    filterLock(lock) {
        /* 
           Filters a lock.
           Looks for the applied filter in the lock name.
        */
        return lock.lock_name.toLowerCase().includes(this.state.filter.toLowerCase());
    }

    sortOnClicked(id){
        if(this.state.sort_on === id){
            this.cycleSortMode();
        }else{
            console.log(`[SORT] on ${this.state.sort_on} -> ${id}`);
            this.setState({sort_on: id, sort_mode: 1});
        }
    }

    cycleSortMode(){
        switch(this.state.sort_mode){
            case 0:
                this.setState({sort_mode: 1});
                break;
            case 1:
                this.setState({sort_mode: 2});
                break;
            case 2:
                this.setState({sort_mode: 0});
                break;
            default:
                this.setState({sort_mode: 0});
        }
    }

    renderArrow(id){
        if(id === this.state.sort_on){
            if(this.state.sort_mode === 1){
                return(<div className="sort-arrow down" onClick={() => this.sortOnClicked(id)}></div>);
            }
            if(this.state.sort_mode === 2){
                return(<div className="sort-arrow up" onClick={() => this.sortOnClicked(id)}></div>);
            }
        }
        return null;
    }

    async onLockUpdate(lock, updated_properties) {
        /* Called after a user edits / updates the properties of a lock */
        const response = await this.apiService.updateLock(lock.id, updated_properties);
        if(response.success) {
            this.lockService.retrieveLocks(true).then( locks => {
                this.setState({locks: locks})
            });
            this.props.enqueueSnackbar(
                t('notifications.changes_to_x_were_successfully_saved', { affected_item: updated_properties.name }),
                { variant: 'success' }
            );
            return true;
        } else {
            this.props.enqueueSnackbar(
                t('notifications.could_not_save_changes'),
                { variant: "error" }
            );
        }
        return false;
    }

    render() {

        const headCells = [
            {
                id: 'name',
                label: t('labels.lock'),
                sortable: true,
            },
            {
                id: 'building',
                label: t('labels.building'),
            },
            {
                id: 'user_count',
                label: t('labels.users'),
                sortable: true,
            },
            {
                id: 'battery',
                label: t('labels.battery')
            }
        ];

        const handleRequestSort = (event, property) => {
            const isAsc = this.state.orderBy === property && this.state.order === 'asc';
            this.setState({ order: isAsc ? 'desc' : 'asc', orderBy: property});
        };

        const EnhancedTableHead = (props) => {
            const { order, orderBy, onRequestSort } = props;
            const createSortHandler = (property) => (event) => {
                onRequestSort(event, property);
            };

            return (
                <TableHead>
                    <TableRow>
                        <TableCell padding="checkbox">
                        </TableCell>
                        {headCells.map((headCell) => (
                        <StyledTableCell
                            key={headCell.id}
                            sortDirection={orderBy === headCell.id ? order : false}
                            style={headCell.id === "locks" || headCell.id === "status" ? {width: 40} : {}}
                        >
                            {headCell.sortable ?
                                (<TableSortLabel
                                    style={{ fontSize: 10,}}
                                    active={orderBy === headCell.id}
                                    direction={orderBy === headCell.id ? order : 'asc'}
                                    onClick={createSortHandler(headCell.id)}
                                >
                                    {headCell.label}
                                    {orderBy === headCell.id ? (
                                        <Box component="span" sx={visuallyHidden}>
                                            {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                        </Box>
                                    ) : null}
                                </TableSortLabel>) : 
                                    headCell.label
                                }
                        </StyledTableCell>
                        ))}
                    </TableRow>
                </TableHead>
            );
        };


        var locks = this.state.locks;
        const is_small_screen = this.props.smallScreen;
        let drawerOpen = this.state.selectedLock !== null;
        let drawerWidth = drawerOpen ? 285 : 0;
        var lockList = null;

        /* Apply filter if necessary */
        if(locks && this.state.filter) {
            locks = locks.filter(this.filterLock);
        }

        /* Render locks if any */
        if(locks) {
            lockList = stableSort(locks, getComparator(this.state.order, this.state.orderBy)).map((lock) => <ListEntryLock lock={lock} selected={this.state.selectedLock} onSelect={this.changeLockSelection} key={String(lock.id)+lock.name}/>);
        }
        
        /* Render page */
        return (
            <div className="locks page"  style={{width: drawerOpen && !is_small_screen ? 'calc(100% - 285px)' : '100%'}}>
                <div className="page-inner" style={{padding: 0}}>
                    <div className="left-side" style={{width: '100%', padding: 0, flexShrink: 0}}>
                        <div className="actions">
                            <div className="search-bar">
                                <div className="icon"></div>
                                <input type="text" placeholder="Zoek slot" 
                                    value={this.state.filter}
                                    onChange={(event) => this.setState(
                                        {filter: event.target.value}
                                    )}/>
                            </div>
                        </div>
                        <TableContainer style={{ maxHeight: this.props.windowHeight - 202}}>
                            <Table stickyHeader>
                                <EnhancedTableHead
                                    order={this.state.order}
                                    orderBy={this.state.orderBy}
                                    onRequestSort={handleRequestSort}
                                />
                                <TableBody dense="true">
                                    {lockList}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </div>
                    <Drawer anchor={is_small_screen ? 'bottom' : 'right'} open={drawerOpen} variant="permanent" sx={drawerOpen ? {
                        width: is_small_screen ? '100%' : drawerWidth,
                        height: is_small_screen ? 200 : '100%',
                        flexShrink: 0,
                        [`& .MuiDrawer-paper`]: {
                            width: is_small_screen ? '100%' : drawerWidth, boxSizing: 'border-box',
                            height: is_small_screen ? 200 : '100%', backgroundColor: 'rgb(24, 24, 24)'
                        },
                        } : {}}
                    >
                        {is_small_screen ? null : <div style={{ height: 48 }} />}
                        <ListLockDetails lock={this.state.selectedLock} onUpdate={this.onLockUpdate} smallScreen={is_small_screen}/>
                    </Drawer>
                </div>
            </div>
        )
    }
}
export default withTranslation()(withSnackbar(Locks));