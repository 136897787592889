import React from 'react';
import { Switch, Route } from 'react-router-dom';
import Refresh from '../pages/refresh/Refresh.js';
import Logs from '../pages/categories/Logs.js';
import People from '../pages/categories/People.js';
import Hardware from '../pages/categories/Hardware.js';

export default function Routes() {
    return (
        <Switch>
            <Route path="/unlocks" component={Logs} />
            <Route path="/people" component={People} />
            <Route path="/hardware" component={Hardware} />
            <Route path="/refresh" component={Refresh} />
            <Route path="/" component={Logs} />
        </Switch>
    )
}
