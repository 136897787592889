import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import '../../index.css';
import './unlocks.css';
import { ServiceContext } from '../../context/ServiceContext';
import ListEntryLog from '../../components/list_entry_log/ListEntryLog';
import { dtToApiDate, getHTMLDate, removeHypen } from '../../util/util.js';
import { useTranslation } from 'react-i18next';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Tooltip, } from '@mui/material';
import { withStyles } from '@mui/styles';
import DateRangePicker from '@mui/lab/DateRangePicker';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import { LoadingButton } from '@mui/lab';
import { CSVLink } from "react-csv";
import LogTimeline2 from '../../components/log_timeline/LogTimeline2';
import moment from 'moment';
import useWindowDimensions from '../../hooks/UseWindowDimensions';
import ListEntryLogSkeleton from '../../components/list_entry_log/ListEntryLogSkeleton';
import Fade from '@mui/material/Fade';


const StyledTableCell = withStyles({
    root: {
        fontSize: 10,
        textTransform: 'uppercase',
        padding: '6px',
        paddingTop: 0,
    }
})(TableCell);

const poll_rate = 3000;
let poll_interval = null;

export default function Unlocks({windowHeight, location}) {
    const [filter, setFilter] = useState('');
    const [lockFilter, setLockFilter] = useState('');
    const [dateRange, setDateRange] = useState([moment().startOf('day'), null]);
    const [locks, setLocks] = useState(null);
    const [logs, setLogs] = useState([]);
    const [sortedLogs, setSortedLogs] = useState([]);
    const [loading, setLoading] = useState(true);
    const [timeView, setTimeView] = useState({from: 0, till: 1440, minDisplayed: 1440, dayDisplayed: 1});

    const context = useContext(ServiceContext);
    const { t } = useTranslation();
    const { width } = useWindowDimensions();
    const is_small_screen = width < 850;

    const pollLogs = () => {
        /* Polls for logs and appends them if they are new */
        context.logService.retrieveLatestLogs().then(response => {
            if (response.success) {
                /* Iterate through new logs */
                let newLogs = [];
                response.logs.forEach(new_log => {
                    var isNew = true;
                    /* Iterate through existing logs and check for matches */
                    logs.forEach(log => { if (log.id === new_log.id) isNew = false; });
                    if (isNew && (dtToApiDate(new Date(new_log.datetime)) === getHTMLDate())) {
                        /* If the log is new and it belongs to the current date we prepend it to the list */
                        newLogs.unshift(new_log);
                    }
                });
                if (newLogs.length) {
                    setLogs( curLogs => [...newLogs, ...curLogs]);
                }
            } else {
                if (response.reason === 403) {
                    clearInterval(poll_interval);
                }
            }
        }).catch(error => console.log(`Couldn't retrieve latest logs: ${error}`));
    };


    const updateDateRange = (newRange) => {
        if (
            (newRange[0] !== dateRange[0] ||
            newRange[1] !== dateRange[1]) &&
            (newRange[0] !== null && newRange[1] !== null)
        ){
            setDateRange(newRange);
            getLogs(newRange[0], newRange[1]);
        }
    };

    const getLogs = async(fromDate, toDate) => {
        /* Pulls logs */
        if (!fromDate) return;
        if (!loading) setLoading(() => true);
        const batch_size = 125;
        const response = await context.logService.retrieveLogsByDates(fromDate, toDate, batch_size);
        if (response && response.success) {
            const daysDisplayed = moment.utc(toDate).diff(moment.utc(fromDate), 'days') + 1;
            setLogs(response.data);
            setTimeView(oldView => (
                { ...oldView, dayDisplayed: daysDisplayed, minDisplayed: 1440 * daysDisplayed, from: 0, till: 1440 * daysDisplayed})
            );
        }
        setLoading(false);
    };

    const sortFilterLogs = useCallback((logList) => {
        const filterByUser = (log) => {
            /* Looks for the applied filter in the user who created the log. */
            const first_name = log.user.first_name.toLowerCase();
            const last_name = log.user.last_name.toLowerCase();
            return (
                first_name.includes(filter.toLowerCase()) ||
                last_name.toLowerCase().includes(filter.toLowerCase()) ||
                (first_name + " " + last_name).includes(filter.toLowerCase())
            );
        };

        const filterByLock = (log) => {
            /* Looks for lock that created the log. */
            return (parseInt(lockFilter) === log.lock);
        }

        if (filter) logList = logList.filter(filterByUser);
        if (lockFilter) logList = logList.filter(filterByLock);

        return logList.sort((a, b) => !(moment(a.datetime).unix() - moment(b.datetime).unix()))
    }, [lockFilter, filter]);

    const renderLogs = useMemo(() => {
        if (loading){
            let skeletons = [];
            for (let i=0; i < 20; i++) {
                skeletons.push(<ListEntryLogSkeleton key={removeHypen("log_skell" + String(i * 12))} is_small_screen={is_small_screen}/>);
            }
            return skeletons;
        }
        else if (sortedLogs) {
            return sortedLogs
            .map((log) => 
                <ListEntryLog 
                    log={log} lockService={context.lockService} time_from={timeView.from} time_till={timeView.till} is_small_screen={is_small_screen} 
                    startDate={dateRange[0]} key={removeHypen("log_entry_" + log.user.email + "_" + log.datetime.replace("-", "_"))}
                />
            );
       }
       return [];
    }, [sortedLogs, loading, timeView.from, timeView.till, context.lockService, dateRange, is_small_screen]);

    const generateLockFilters = useMemo(()=>{
        if (locks) {
            const activeLocks = locks.filter((lock) => lock.setup === true);
            return activeLocks.map((lock) => <option key={removeHypen("lock_filter" + lock.id + lock.name)} value={lock.id}>{lock.name}</option>);
        }
        return [];
    },[locks]);

    const generateCSV = useMemo(() => {

        const stateToStatus = (state) => {
            switch (state) {
                case 'Nack':
                    return t('labels.failed');
                case 'Unconfirmed':
                    return t('labels.unconfirmed');
                default:
                    return t('labels.confirmed');
            }
        }

        const unlockTypes = {"RFID_UNLOCK": t("labels.card"), "UNLOCK": "Keychain app"};

        if (logs && locks && !loading) {
            let csvList = [];
            logs.forEach(log => {
                const lock = locks.find(lock => lock.id === log.lock);
                const log_moment = moment(log.datetime);
                let log_item = {};
                log_item['id'] = log.id;
                log_item[t("labels.status")] = stateToStatus(log.state);
                log_item[t("labels.time")] = log_moment.format("HH:mm:ss");
                log_item[t("labels.date")] = log_moment.format("DD/MM/YYYY");
                log_item[t("labels.lock")] = lock.lock_name;
                log_item[t("labels.building")] = lock.building_name;
                log_item[t("labels.type")] = unlockTypes[log.action];
                log_item[t("labels.user")] = log.user.first_name + " " + log.user.last_name + " (" + log.user.email + ")";
    
                csvList.push(log_item);
            });
            return csvList;
        }
        return [];
    }, [locks, logs, loading, t]);

    useEffect(() => {
        /* Get filter from URL parameter */
        const params = new URLSearchParams(location.search);
        const user = params.get('user');
        if (user) setFilter(user);
        /* Fetch data */
        context.lockService.retrieveLocks().then(locks => {
            setLocks(locks);
        });
        context.userService.retrieveUsers().then();
        getLogs(dateRange[0], dateRange[0]).then();
        
    }, []);

    useEffect(() => {
        const now = moment();
        clearInterval(poll_interval);
        if (
            (!dateRange[1] && dateRange[0].isSame(now.format("YYYY-MM-DD"), "day")) ||
            (dateRange[1] && dateRange[1].isSame(now.format("YYYY-MM-DD"), "day"))
        ) {
            poll_interval = setInterval(pollLogs, poll_rate);
        }
        return () => {
            clearInterval(poll_interval);
        }
    }, [dateRange, pollLogs]);

    useEffect(() => {
        setSortedLogs(() => sortFilterLogs(logs));
    }, [filter, lockFilter, logs, sortFilterLogs]);

    let headCells = [
        {
            id: 'time',
            label: t('labels.time'),
        },
        {
            id: 'date',
            label: t('labels.date'),
        },
        {
            id: 'who',
            label: t('labels.who'),
        },
        {
            id: 'lock',
            label: t('labels.lock'),
        },
        {
            id: 'building',
            label: t('labels.building'),
        },
    ];
    if (is_small_screen) headCells.pop();


    const lockFilters = generateLockFilters;
    const logCSV = generateCSV;
    const csv_filename = `NextXS_${t('labels.unlocks')}_${dateRange[0].format("DD/MM/YYYY")}_${dateRange[1] ? dateRange[1].format("DD/MM/YYYY") : dateRange[0].format("DD/MM/YYYY")}.csv`.replace(/ /g,"_");

    /* Render page */
    return (
        <div>
            <div className="page-inner" style={{padding: 0}}>
                <div className="left-side" style={{ width: 'calc(100%)', padding: 0}}>
                    {is_small_screen ? 
                        (<div>
                        <div className="actions">
                            <div className="search-bar" style={{ width: 'calc(75%)' }}>
                                <div className="icon"></div>
                                <input type="text" placeholder={t("search-placeholders.user")}
                                    value={filter}
                                    onChange={(event) => setFilter(event.target.value)}
                                />
                            </div>
                            <div className="action-container lock-selector">
                                <select className="selector" name="" onChange={(event) => setLockFilter(event.target.value)}>
                                    <option value="">{t("labels.all-locks")}</option>
                                    {lockFilters}
                                </select>
                            </div>
                        </div>
                        <div className="actions">
                            <DateRangePicker
                                allowSameDateSelection={true}
                                calendars={1}
                                disabled={loading}
                                disableCloseOnSelect={false}
                                disableMaskedInput={true}
                                maxDate={moment()}
                                value={dateRange}
                                onChange={(newValue) => {
                                    if (
                                        (newValue[0] !== dateRange[0] ||
                                        newValue[1] !== dateRange[1]) &&
                                        (newValue[0] !== null && newValue[1] !== null)
                                    ){
                                        setDateRange(newValue);
                                        getLogs(newValue[0], newValue[1]);
                                    }
                                }}
                                renderInput={(startProps, endProps) => (
                                    <React.Fragment>
                                        <div className="date-range-container-small" style={{marginLeft: 0, marginTop: 0}}>
                                            <input type="date" id="start-date" className="date-range-field" ref={startProps.inputRef} {...startProps.inputProps}/>
                                            <div style={{minWidth: 6}}/>
                                            <input type="date" id="end-date" className="date-range-field" ref={endProps.inputRef} {...endProps.inputProps}/>
                                        </div>
                                    </React.Fragment>
                                )}
                            />
                            <Tooltip title={t('actions.export_csv')} TransitionComponent={Fade} TransitionProps={{ timeout: 300 }}>
                                <span>
                                    <LoadingButton color={'info'} size="small" variant="contained" sx={{height: 36}} style={{
                                        marginLeft: 6, marginTop: 0, maxWidth: '33px', maxHeight: '33px', minWidth: '33px', minHeight: '33px'
                                    }} disabled={!logCSV.length}>
                                        <CSVLink data={logCSV} filename={csv_filename} style={{display: 'flex', alignItems: 'center'}}>
                                            <FileDownloadIcon color={'listentry'} />
                                        </CSVLink>
                                    </LoadingButton>
                                </span>
                            </Tooltip>
                        </div></div>) 
                        :
                        (<div className="actions">
                            <div className="search-bar" style={{ width: 'calc(75%)' }}>
                                <div className="icon"></div>
                                <input type="text" placeholder={t("search-placeholders.user")}
                                    value={filter}
                                    onChange={(event) => setFilter(event.target.value)}
                                />
                            </div>
                            <div className="action-container lock-selector">
                                <select className="selector" name="" onChange={(event) => setLockFilter(event.target.value)}>
                                    <option value="">{t("labels.all-locks")}</option>
                                    {lockFilters}
                                </select>
                            </div>
                            <DateRangePicker
                                allowSameDateSelection={true}
                                calendars={2}
                                disabled={loading}
                                disableCloseOnSelect={false}
                                disableMaskedInput={true}
                                maxDate={moment()}
                                value={dateRange}
                                onChange={(newValue) => updateDateRange(newValue)}
                                renderInput={(startProps, endProps) => (
                                    <React.Fragment>
                                        <div className="date-range-container">
                                            <input type="date" id="start-date" className="date-range-field" ref={startProps.inputRef} {...startProps.inputProps}/>
                                            <div style={{minWidth: 6}}/>
                                            <input type="date" id="end-date" className="date-range-field" ref={endProps.inputRef} {...endProps.inputProps}/>
                                        </div>
                                    </React.Fragment>
                                )}
                            />
                            <Tooltip title={t('actions.export_csv')} TransitionComponent={Fade} TransitionProps={{ timeout: 300 }}>
                                <span>
                                    <LoadingButton color={'info'} size="small" variant="contained" sx={{height: 36}} style={{
                                        marginLeft: 6, marginTop: 12, maxWidth: '33px', maxHeight: '33px', minWidth: '33px', minHeight: '33px'
                                    }} disabled={!logCSV.length}>
                                        <CSVLink data={logCSV} filename={csv_filename} style={{display: 'flex', alignItems: 'center'}}>
                                            <FileDownloadIcon color={'listentry'} />
                                        </CSVLink>
                                    </LoadingButton>
                                </span>
                            </Tooltip>
                        </div>)
                    }
                    <LogTimeline2 
                        logs={sortedLogs} setTimeFilter={(newView) => setTimeView(curView => ({...curView, ...newView}))} daysDisplayed={timeView.dayDisplayed}
                        minutesDisplayed={timeView.minDisplayed} startDate={dateRange[0]} loading={loading} 
                    />
                    <TableContainer style={{ maxHeight: is_small_screen ? windowHeight - 297 - 34 : windowHeight - 297}}>
                        <Table stickyHeader>
                            <TableHead>
                                <TableRow>
                                    <TableCell padding="checkbox"/>
                                    {headCells.map((headCell) => (<StyledTableCell key={removeHypen("head_" + headCell.id)}>{headCell.label}</StyledTableCell>))}
                                </TableRow>
                            </TableHead>
                            <TableBody dense="true">
                                {renderLogs}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </div>
                
            </div>
        </div>)
}

