import React, { Component } from 'react';
import '../../index.css';
import './bridges.css';
import { ServiceContext } from '../../context/ServiceContext';
import ListEntryBridge from '../../components/list_entry_bridge/ListEntryBridge';
import ListBridgeDetails from '../../components/list_bridge_details/ListBridgeDetails';
import BridgeManager from '../../components/bridge_manager/BridgeManager';
import { withTranslation } from 'react-i18next';
import { Drawer } from '@mui/material';
import { Box, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TableSortLabel } from '@mui/material';
import { withStyles } from '@mui/styles';
import { visuallyHidden } from '@mui/utils';
import { stableSort, getComparator } from '../../util/util';
import { t } from 'i18next';

const StyledTableCell = withStyles({
    root: {
        fontSize: 10,
        textTransform: 'uppercase',
        padding: '6px',
        paddingTop: 0,
    }
})(TableCell);

class Bridges extends Component {

    static contextType = ServiceContext;

    constructor(props) {
        super(props)
        /* Initialize state */
        this.state = {
            order: 'asc',
            orderBy: 'name',
            selectedBridge: null,
            showLockManager: false,
            bridges: [],
            locks: null,
            filter: '',
        }

        /* Handles for services */
        this.lockService = null;
        this.apiService = null;
        this.orgService = null;
        this.bridgeService = null;

        /* Set up function binds */
        this.filterBridge = this.filterBridge.bind(this);
        this.changeBridgeSelection = this.changeBridgeSelection.bind(this);
        this.updateBridges = this.updateBridges.bind(this);
        this.lazyLoadBridges = this.lazyLoadBridges.bind(this);
    }

    componentDidMount() {

        /* Retrieve reference to bridgeService */
        if (!this.bridgeService) {
            this.bridgeService = this.context.bridgeService;
        }

        /* Retrieve reference to lockService */
        if (!this.lockService) {
            this.lockService = this.context.lockService;
        }

        /* Retrieve reference to userService */
        if (!this.userService) {
            this.userService = this.context.userService;
        }

        /* Retrieve reference to apiService */
        if (!this.apiService) {
            this.apiService = this.context.apiService;
        }

        /* Retrieve reference to organizationService */
        if (!this.orgService) {
            this.orgService = this.context.orgService;
        }

        /* Retrieve bridges when the component has been mounted. */
        this.bridgeService.retrieveBridges(this.lazyLoadBridges).then().catch(e => console.log(e));

        /* Retrieve locks when the component has been mounted */
        this.lockService.retrieveLocks(true).then(locks => {
            this.setState({ locks: locks })
        })

        /* Get filter from URL parameter */
        const params = new URLSearchParams(this.props.location.search);
        const name = params.get('name');
        if (name) this.setState({ filter: name })
    }

    lazyLoadBridges(new_bridges) {
        /* Adds new bridges to the page as they are being received from the API */
        this.setState({ bridges: [...this.state.bridges, ...new_bridges.data] })
    }

    changeBridgeSelection(bridge) {
        /* Called when a bridge within the list is clicked */
        if (bridge === this.state.selectedBridge) {
            this.setState({ selectedBridge: null });
            return;
        }
        this.setState({ selectedBridge: bridge });
    }

    filterBridge(bridge) {
        return (bridge.name.includes(this.state.filter.toLowerCase()));
    }


    async updateBridges(updated_bridges) {
        /* Called when a single bridge is updated, updates it within the page */
        const bridge_ids = [];
        updated_bridges.forEach(bridge => bridge_ids.push(bridge.pk));
        this.setState({
            bridges: [...this.state.bridges.filter(
                (bridge) => !bridge_ids.includes(bridge.pk)),
            ...updated_bridges]
        });

    }

    render() {
        const headCells = [
            {
                id: 'name',
                label: t('labels.name'),
                sortable: true,
            },
            {
                id: 'locks',
                label: t('labels.locks'),
            },
            {
                id: 'status',
                label: t('labels.status'),
            },
        ];

        const handleRequestSort = (event, property) => {
            const isAsc = this.state.orderBy === property && this.state.order === 'asc';
            this.setState({ order: isAsc ? 'desc' : 'asc', orderBy: property});
        };

        const EnhancedTableHead = (props) => {
            const { order, orderBy, onRequestSort } = props;
            const createSortHandler = (property) => (event) => {
                onRequestSort(event, property);
            };

            return (
                <TableHead>
                    <TableRow>
                        <TableCell padding="checkbox">
                        </TableCell>
                        {headCells.map((headCell) => (
                        <StyledTableCell
                            key={headCell.id}
                            sortDirection={orderBy === headCell.id ? order : false}
                            style={headCell.id === "locks" || headCell.id === "status" ? {width: 40} : {}}
                        >
                            {headCell.sortable ?
                                (<TableSortLabel
                                    style={{ fontSize: 10,}}
                                    active={orderBy === headCell.id}
                                    direction={orderBy === headCell.id ? order : 'asc'}
                                    onClick={createSortHandler(headCell.id)}
                                >
                                    {headCell.label}
                                    {orderBy === headCell.id ? (
                                        <Box component="span" sx={visuallyHidden}>
                                            {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                        </Box>
                                    ) : null}
                                </TableSortLabel>) : 
                                    headCell.label
                            }
                        </StyledTableCell>
                        ))}
                    </TableRow>
                </TableHead>
            );
        };

        var bridges = this.state.bridges;
        var bridgeList = null;

        const is_small_screen = this.props.smallScreen;
        const drawerOpen = this.state.selectedBridge !== null;
        const drawerWidth = drawerOpen ? 285 : 0;

        /* Apply filter if necessary */
        if (bridges && this.state.filter) {
            bridges = bridges.filter(this.filterBridge);
        }

        /* Render bridges if any */
        if (bridges) {
            bridgeList = stableSort(bridges, getComparator(this.state.order, this.state.orderBy))
                .map((bridge) => <ListEntryBridge bridge={bridge} onSelect={this.changeBridgeSelection} onEdit={(bridge) => this.setState({ showLockManager: true, selectedBridge: bridge })} selected={this.state.selectedBridge} key={"bridge_" + String(bridge.pk)} />);
        }

        return (
            <div className="bridges page" style={{width: drawerOpen && !is_small_screen ? 'calc(100% - 285px)' : '100%'}}>
                <div className="page-inner" style={{padding: 0}}>
                    <div className="left-side" style={{width: '100%', padding: 0 }}>
                        <div className="actions">
                            <div className="search-bar">
                                <div className="icon"></div>
                                <input type="text" placeholder={t('search-placeholders.bridge')}
                                    value={this.state.filter}
                                    onChange={(event) => this.setState({ filter: event.target.value })}
                                />
                            </div>
                        </div>
                        <TableContainer style={{ maxHeight: this.props.windowHeight - 202}}>
                            <Table stickyHeader>
                                <EnhancedTableHead
                                    order={this.state.order}
                                    orderBy={this.state.orderBy}
                                    onRequestSort={handleRequestSort}
                                />
                                <TableBody dense="true">
                                    {bridgeList}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </div>
                    {this.state.showLockManager ?
                        <BridgeManager
                            initialBridge={this.state.selectedBridge}
                            onExit={() => this.setState({ showLockManager: false })}
                            updateCallback={this.updateBridges}
                            locks={this.state.locks}
                        />
                        :
                        null
                    }
                </div>
                <Drawer anchor={is_small_screen ? 'bottom' : 'right'} open={drawerOpen} variant="permanent" sx={drawerOpen ? {
                    width: is_small_screen ? '100%' : drawerWidth,
                    height: is_small_screen ? 200 : '100%',
                    flexShrink: 0,
                    [`& .MuiDrawer-paper`]: {
                        width: is_small_screen ? '100%' : drawerWidth, boxSizing: 'border-box',
                        height: is_small_screen ? 200 : '100%', backgroundColor: 'rgb(24, 24, 24)'
                    },
                } : {}}
            >
                    {is_small_screen ? null : <div style={{ height: 48 }} />}
                <ListBridgeDetails smallScreen={is_small_screen} onEdit={(bridge) => this.setState({ showLockManager: true, selectedBridge: bridge })} bridge={this.state.selectedBridge} />
            </Drawer>
            </div>
        )
    }
}

export default withTranslation()(Bridges);