import React, { useContext } from 'react';
import { ServiceContext } from '../../context/ServiceContext';
import { Avatar, Chip, Stack, TableCell, TableRow, Tooltip } from '@mui/material';
import { withStyles } from '@mui/styles';
import { removeHypen } from '../../util/util';
import { styled } from '@mui/material/styles';
import Badge from '@mui/material/Badge';
import Fade from '@mui/material/Fade';
import { t } from 'i18next';

const StyledBadge = styled(Badge)(({ theme }) => ({
    '& .MuiBadge-badge': {
      backgroundColor: '#0867DE',
      boxShadow: `0 0 0 1px ${theme.palette.background.paper}`,
    },
  }));

const StyledChip = withStyles({
  root: {
    backgroundColor: '#0867DE',
    opacity: 0.95  
  },
  label: {
    fontSize: '10px',
  },
})(Chip);

const StyledTableRow = withStyles({
    root: {
        cursor: 'pointer',
        backgroundColor: 'rgba(39, 40, 41, 0.9)',
        "&:hover": {
            backgroundColor: 'rgba(39, 40, 41, 0.7)',
        }
    }
})(TableRow);

const StyledTableCell = withStyles({
    root: {
        borderBottom: '1px solid #181818',
        fontSize: 13,
        padding: '6px'
    }
})(TableCell);


function ListEntryUser({user, onSelect, onAccess, groups, selected}) {
    var is_selected = false;
    if(selected) is_selected = selected.email === user.email;
    
    const services = useContext(ServiceContext);
    const usergroups = [];

    if(groups){
        groups.forEach(group => {
            if(group.members.includes(user.id)){
                usergroups.push(group.name);
            }
        });
    }

    return(
        <StyledTableRow onClick={() => onSelect(user)} style={is_selected ? {backgroundColor: 'rgba(39, 40, 41, 0.7)'} : {}}>
           <StyledTableCell>
               {user.is_admin ?
                    (
                    <Tooltip title={t("labels.admin")} placement="right" TransitionComponent={Fade} TransitionProps={{ timeout: 600 }}>
                        <StyledBadge
                            overlap="circular"
                            anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                            variant="dot"
                        >
                            <Avatar src={user.avatar} sx={{
                                backgroundColor: '#181818',
                                color: '#fff',
                                fontSize: 13,
                                height: 34,
                                marginLeft: '6px',
                                width: 34,
                            }}>
                                {services.userService.getInitials(user)}
                            </Avatar>
                        </StyledBadge>
                    </Tooltip>)
                    :
                    (<Avatar src={user.avatar} sx={{
                        backgroundColor: '#181818',
                        color: '#fff',
                        fontSize: 13,
                        height: 34,
                        marginLeft: '6px',
                        width: 34,
                    }}>
                        {services.userService.getInitials(user)}
                    </Avatar>)
                }
            </StyledTableCell>
            <StyledTableCell style={{fontWeight: 'bold'}}>
                {user.first_name}
            </StyledTableCell>
            <StyledTableCell style={{fontWeight: 'bold'}}>
                {user.last_name}
            </StyledTableCell>
            <StyledTableCell style={{width: "max-content"}}>
                <Stack direction="row" spacing={1}>
                    {usergroups.map(group => <StyledChip key={removeHypen("chip_" + user.email + group )} size='small' label={group}/>)}
                </Stack>
            </StyledTableCell>
        </StyledTableRow>
    )
}

export default ListEntryUser;
