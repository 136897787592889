import React from 'react';
import { Avatar, } from '@mui/material';


function XSAvatar(props) {
    return (
        <Avatar
            sx={{
            backgroundColor: '#181818',
            color: '#fff',
            fontSize: 13,
            height: 30,
            width: 30,
            }}
        {...props}>
            {props.children}
    </Avatar>);
}

export default XSAvatar;
