class AuthService {

    constructor(apiService, setAuthenticated, onLogout) {

        this.user = {avatar: null, email: '', name: '', first_name: '', last_name: '', user_id: -1, organizations: []};
        this.authenticated = false;
        this.selected_org = 0;
        this.apiService = apiService;
        this.setAuthenticated = setAuthenticated;
        this.onLogout = onLogout;
    }

    getUserAvatar() {
        return this.user.avatar;
    }
    
    getUserEmail() {
        return this.user.email;
    }

    getUserName() {
        return this.user.first_name + ' ' + this.user.last_name;
    }

    getUserOrgs() {
        return this.user.organizations;
    }

    setSelOrg(org_id) {
        this.selected_org = org_id;
        this.apiService.setOrg(org_id);
    }

    async login(email, password) {
        const response = await this.apiService.getCookie(email, password);
        if(response.success) {
            this.user = response.data;
            this.user.email = response.data.email;
            this.user.organizations = response.data.organizations;
            this.authenticated = true;
            this.apiService.setOrg(this.user.organizations[0].id);
            this.setAuthenticated(true);
            return({success: true});
        }
        try{
            /* Sign in OK, but we will get redirected... */
            if(response.redirect){
                return({success: false, reason: "redirect"});
            }
            /* If the API call failed we try to extract the error message */
            const api_error = await response.data.json();
            return({success: false, error: api_error.errors.__all__});
        }catch{
            return({success: false, error: "Unknown error"});
        }
    }

    async logout() {
        const response = await this.apiService.logOut();
        if(response.success) {
            this.authenticated = false;
            this.setAuthenticated(false);
            this.onLogout();
        }
    }

    async isAuthenticated()
    {
        /* Checks if the user is still authenticated */
        const response = await this.apiService.isLoggedIn();
        if(response.success) {
            if(response.data) {
                this.user = response.data;
                this.authenticated = true;
                const org_id = this.apiService.getOrgId();
                var new_org_id = this.user.organizations[0].id;
                for(var i = 0; i < this.user.organizations.length; i++){
                    if(org_id === this.user.organizations[i].id){
                        new_org_id = org_id;
                    }
                }
                console.log(`selected org ID is : ${org_id}`);
                this.apiService.setOrg(new_org_id);
                this.setAuthenticated(true);
                return({success: true});
            }
        }
        return {success: false};
    }
    
}
export default AuthService;